import axiosService from "core/services/httpRequest/AxiosConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  infos: {},
  connected: {},
  loading: false,
};

export const getIndividual = createAsyncThunk(
  //action type string
  "/GET_INDIVIDUAL",
  // callback function
  async (_, thunkAPI) => {
    const infos = await axiosService.get("/individual");
    const connected = await axiosService.get("/customer/connected");

    return { infos: infos.data, connected: connected.data };
  },
  //   Add condition if infosPerso is already in the store
  {
    condition: (force = false, thunkAPI) => {
      const data = thunkAPI.getState();

      const fetchIndividual = data.individualState;
      if (
        force === false ||
        Object.keys(fetchIndividual.infos).length > 0 ||
        Object.keys(fetchIndividual.connected).length > 0
      ) {
        // Already fetched or in progress, don't need to re-fetch

        return false;
      }
    },
  }
);

export const infosPersoSlice = createSlice({
  name: "individualState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIndividual.pending, (state) => {
        state.loading = true;
        // state.infos = { givenName: "", familyName: "" };
      })
      .addCase(getIndividual.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.infos = payload.infos;
        state.connected = payload.connected;
      })
      .addCase(getIndividual.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default infosPersoSlice.reducer;
