import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Layout from "components/organisms/Layout/Layout";

import "./styles/404.scss";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import Button from "components/atoms/Button/Button";
import IconComponent from "components/atoms/Icons/IconComponent";

const Page404 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickAccueil = () => {
    history.push("/");
  };

  const handleClickEspaceClient = () => {
    history.push("/tableau-de-bord");
  };

  return (
    <Layout className="layout_error">
      <div className="container404">
        <div className="wrapper">
          <div className="relative">
            <IconComponent name="Error404" />

            <span className="ellipse-0" />
            <span className="ellipse-1" />
            <span className="ellipse-2" />
            <span className="ellipse-3" />
            <span className="ellipse-4" />
            <span className="ellipse-5" />
          </div>

          <TypographyComponent variant="h1">
            {t("page404.title")}
          </TypographyComponent>
          {/* <TypographyComponent variant="body2">
            {t("page404.sousTitle")}
          </TypographyComponent> */}
        </div>
        <div className="wrapper_btn">
          <Button className="green" onClick={handleClickAccueil}>
            {t("page404.labelBtn1")}
          </Button>
          <Button className="green" onClick={handleClickEspaceClient}>
            {t("page404.labelBtn2")}
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Page404;
