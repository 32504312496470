import IconComponent from "components/atoms/Icons/IconComponent";
import Link from "components/atoms/Link/Link";
import BlocContent from "components/molecules/BlocContent/BlocContent";
import LayoutPage from "components/organisms/Layout/LayoutPage";
import BlocMesFactures from "components/organisms/BlocMesFactures";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./mesDocuments.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import getFileContent from "core/services/API/Files/getFileContent";
// import Popin from "components/atoms/Popin/Popin";
// import Button from "components/atoms/Button/Button";
import { sendProofOfAddress } from "core/services/httpRequest/sendProofOfAddress";
// import handleRequestResponse from "utils/responsesHandler";

const MesDocuments = () => {
  const { t } = useTranslation();
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  // Selector
  const listFiles = useSelector((state) => state.allFiles);
  const productSerialNumber = useSelector(
    (state) =>
      state?.userContractsState?.contracts?.find((c) => c?.selected)
        ?.souscription?.productSerialNumber
  );

  // const [openJustif, setOpenJustif] = useState(false);
  const [files, setFiles] = useState([]);
  const [documents, setDocuments] = useState([
    {
      id: 1,
      title: "Mon justificatif de domicile",
      link: "/",
      fileName: "",
    },
    {
      id: 2,
      title: "Mes Conditions Particulières de Vente",
      link: "",
      fileName: "",
    },
    {
      id: 3,
      title: "Mes Conditions Générales de Vente",
      link: "/",
      fileName: "",
    },
    {
      id: 4,
      title: "Mon mandat de prélèvement SEPA",
      link: "",
      fileName: "",
    },
  ]);

  const detectSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
  }, [screenSize]);

  // Tableau
  const headCells = [
    {
      id: "types",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.type"),
    },
    {
      id: "montant",
      numeric: true,
      disablePadding: true,
      label: t("tableauFacture.montant"),
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.date"),
    },
    {
      id: "reference",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.reference"),
    },
    {
      id: "echeance",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.echeance"),
    },
    {
      id: "statut",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.statut"),
    },
  ];

  useEffect(() => {
    if (listFiles) {
      setFiles(tableDoc(listFiles));
    }
  }, [listFiles]);

  // Create list invoices
  const tableDoc = ({ files }) => {
    const typeFilesInvoices = files?.invoices;
    const typeFilesEcheancier = files?.echeancier;
    // const typeFilesContract = files?.contract;
    const list = [];

    typeFilesInvoices?.forEach((v, i) => {
      const statutF = v.status === "PAID";

      list?.push({
        typeData: "factures",
        types: v.characteristics.type,
        montant: (v.taxIncludedAmount / 100)
          ?.toFixed(2)
          ?.toString()
          ?.replace(".", ","),
        date: moment(v.billDate).format("DD/MM/yyyy"),
        reference: v.ref,
        echeance: moment(v.echeance).format("DD/MM/yyyy"),
        statut: statutF,
        telecharger: v.idFile,
        name: v.fileNamePDF,
      });
      list.sort((v1,v2) => moment(v2.date).diff(moment(v1.date)))
    });
    typeFilesEcheancier?.forEach((v, i) => {
      list?.push({
        typeData: "",
        types: "Échéancier",
        montant: "-",
        date: moment(v.dateTimeCreate).format("DD/MM/yyyy"),
        reference: "-",
        echeance: "-",
        statut: "-",
        telecharger: v.ouid,
        name: v.fileName,
      });
    });
    // typeFilesContract?.forEach((v, i) => {
    //   // const name = v.fileName.replace(".pdf", "");
    //   list?.push({
    //     types: v.characteristics.type,
    //     montant: "-",
    //     date: moment(v.dateTimeCreate).format("DD/MM/yyyy"),
    //     reference: "-",
    //     echeance: "-",
    //     statut: "-",
    //     telecharger: v.ouid,
    //     name: v.fileName,
    //   });
    // });
    return list;
  };

  useEffect(() => {
    if (Object.keys(listFiles.files).length > 0) {
      setDocuments([
        {
          id: 1,
          title: "Mon justificatif de domicile",
          link: "/",
          fileName: "",
        },
        {
          id: 2,
          title: "Mes Conditions Particulières de Vente",
          link: listFiles.files.contract[0].ouid,
          fileName: listFiles.files.contract[0].fileName,
        },
        {
          id: 3,
          title: "Mes Conditions Générales de Vente",
          link: "/",
          fileName: "",
        },
        {
          id: 4,
          title: "Mon mandat de prélèvement SEPA",
          link: listFiles.files.sepa[0].ouid,
          fileName: listFiles.files.sepa[0].fileName,
        },
      ]);
    }
  }, [listFiles]);

  const handleClick = (id, name) => {
    getFileContent(id, name);
  };

  function confirmSendJustif() {
    // setOpenJustif(false);
    //   // API send justif
    sendProofOfAddress(productSerialNumber);
  }
  const statutContrat = useSelector(
    (state) =>
      state?.userContractsState?.contracts?.find((c) => c?.selected)
        ?.souscription?.status
  );

  return (
    <LayoutPage>
      <div className="container-mesDocuments">
        <BlocContent
          title={t("pages.mesDocuments.title")}
          variant="h2"
          iconName="Document"
          iconColor={"white"}
          iconStyle={{ height: "20px", width: "20px" }}
        >
          {/* <Popin open={openJustif} onClose={() => setOpenJustif(false)}>
            <>
              <p>
                Votre attestation de contrat est en cours d'édition. Vous la
                recevrez par mail dans quelques instants. Cliquez pour valider
                l'envoi.
              </p>
              <Button
                style={{ margin: "20px auto 8px" }}
                className="green outline"
                onClick={() => confirmSendJustif()}
              >
                J'ai compris
              </Button>
            </>
          </Popin> */}
          <div className="wrapper_doc">
            {documents.map((v, i) => {
              if (v?.title === "Mon justificatif de domicile") {
                return (
                  <Link
                    className="cta black"
                    onClick={() => statutContrat === "PENDING_ACTIVE" ? "" : confirmSendJustif()}
                    target="_blank"
                    key={i}
                    disabled={statutContrat === "PENDING_ACTIVE" ? true : false}
                  >
                    <div className="picto">
                      <IconComponent name="Telechargement" />
                    </div>
                    {v.title}
                  </Link>
                );
              } else {
                return (
                  <Link
                    className="cta black"
                    onClick={() => handleClick(v.link, v.fileName)}
                    target="_blank"
                    key={i}
                  >
                    <div className="picto">
                      <IconComponent name="Telechargement" />
                    </div>
                    {v.title}
                  </Link>
                );
              }
            })}
          </div>
        </BlocContent>
        <BlocMesFactures
          headCells={headCells}
          table={files}
          screen={screenSize}
          loading={listFiles.loading}
        />
      </div>
    </LayoutPage>
  );
};

export default MesDocuments;
