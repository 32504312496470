import axios from "axios";

import { AuthVerify, logout } from "./authentification/authentificationService";

/**
 * Ce fichier contient la configuration d'axios, aucun appel aux services ne doit être présent dans ce fichier
 */
document.ENVIRONNEMENTS = !!document.ENVIRONNEMENTS
  ? document.ENVIRONNEMENTS
  : { API_BASE_PATH: "https://fail.config/" };

const url = `${window.location.origin}${document.ENVIRONNEMENTS.API_LOGIN_PATH}`;

export const axiosService = axios.create({
  baseURL: document.ENVIRONNEMENTS.API_BASE_PATH,
});

axiosService.interceptors.request.use((config) => {
  const accessToken = AuthVerify();
  //checking if accessToken exists
  if (accessToken) {
    config.headers["Authorization"] = "Bearer " + accessToken;
  }
  return config;
});


axiosService.interceptors.response.use(
  (response) => response,
  (error) => {
    //checking if error is Aunothorized error
    if (error.response.status === 401) {
      //clear local storage and log user out

      logout();
    }
    if (
      error.response.config.url !== `${url}/login/jwt` &&
      error.response.status === 403
    ) {
      logout();
      window.location.href = "/403";
    }
    if (error.response.status === 408) {
      //clear local storage and log user out
      console.log("408 Request Timed Out:", error.response.config.url);
    }
    if (error.response.status === 504) {
      //clear local storage and log user out
      console.log("504 Gateway Time Out:", error.response.config.url);
    }

    // console.log("axiosService.interceptors.response", error);
    // return status code as object (status code schema same as resolved for responsesHandler)
    return Promise.reject(error);
  }
);

export default axiosService;
