import React from "react";
import ContentBis from "components/molecules/Content/ContentBis";
import BlocContent from "components/molecules/BlocContent/BlocContent";
import { useTranslation } from "react-i18next";
import Link from "components/atoms/Link/Link";
import IconComponent from "components/atoms/Icons/IconComponent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import "./blocInformationUtiles.scss";

const BlocInformationsUtiles = ({
  heureCreuses = false,
  showLinkyTips = true,
  numero,
  departement,
}) => {
  const { t } = useTranslation();

  const numeroSlicer = numero?.slice(0, 2) + " " + numero?.slice(2, 5) + " " + numero?.slice(5, 8) + " " + departement;

  return (
    <BlocContent
      title={t("pages.informationUtile.titre")}
      variant="p"
      showIcon={false}
      className="informationUtile"
    >
      {!!heureCreuses && (
        <ContentBis
          iconName={"Horloge"}
          iconColor={"svgBlue"}
          variant="h3"
          title={t("pages.informationUtile.hc")}
          className="hc_container"
        >
          <TypographyComponent variant="body2">
            {heureCreuses}
          </TypographyComponent>
        </ContentBis>
      )}
      <ContentBis
        iconName={"Alert"}
        iconColor={"svgBlue"}
        variant="h3"
        title={t("pages.informationUtile.depannage")}
        className="depannage_container"
      >
        {t("pages.informationUtile.infoDepannage")}
      </ContentBis>
      <div className="panneText">
        <b className="title">{t("pages.informationUtile.panne")}</b>
        <Link
          className="black"
          href="https://www.enedis.fr/panne-et-interruption"
          target="_blank"
          rel="noreferrer"
        >
          {t("pages.informationUtile.link")}
          <IconComponent name="Link" />
        </Link>
      </div>
      <div className="urgent">
        <div className="title">
          <IconComponent name="Tel" />
          <b>{t("pages.informationUtile.appelUrgent")}</b>
        </div>
        {numero && departement && (

          <div className="urgent_content">
            <a className="num_urgent" href={`tel:+33${numero.slice(1, 8) + departement}`}>
              <b>{numeroSlicer}</b>
            </a>
            <p>{t("pages.informationUtile.infoTel")}</p>
          </div>
        )}
      </div>
      {showLinkyTips && (
        <ContentBis
          iconName={"Help"}
          iconColor={"svgBlue"}
          variant="h3"
          title={t("pages.informationUtile.question")}
          className="link_container"
        >
          {t("pages.informationUtile.reponse")}
          <Link
            className="black"
            href={
              "https://www.enedis.fr/le-compteur-linky-un-outil-pour-la-transition-ecologique"
            }
            target="_blank"
            rel="noreferrer"
          >
            En savoir plus <IconComponent name="Link" />
          </Link>
        </ContentBis>
      )}
    </BlocContent>
  );
};

export default BlocInformationsUtiles;
