import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./core/i18n/i18n";
import { Provider } from "react-redux";
import { stores } from "./core/stores/stores";
// import configAxios from "./core/services/httpRequest/AxiosConfig";
import ScrollToTop from "./components/atoms/ScrollToTop/ScrollTotop";
import { CookiesProvider } from "react-cookie";
import ScrollTopButton from "components/atoms/ScrollToTop/ScrollTopButton";

const container = document.getElementById("root");
const root = createRoot(container);
// configAxios();

root.render(
  <BrowserRouter>
    <Provider store={stores}>
      <CookiesProvider>
        <ScrollTopButton />
        <ScrollToTop />
        <App />
      </CookiesProvider>
    </Provider>
  </BrowserRouter>
);
