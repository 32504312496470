import React from "react";

import Logo from "../../../core/assets/picto/logoPrimeo.svg";
import Image from "components/atoms/Image/Image";

import { useTranslation } from "react-i18next";

import "./header.scss";

/**
 * @name Header
 * @param {node} rightContent Content on the right
 */

const Header = ({ rightContent }) => {
  const { t } = useTranslation();
  return (
    <header className="header">
      <div className="wrapper">
        <div className="container">
          <div className="left">
            <div className="bloc_title">
              <Image src={Logo} alt="Logo Primeo" className="logoPrimeo" />
              <p className="title">{t("all.titlePage")}</p>
            </div>
          </div>
          <div className="right">{rightContent}</div>
        </div>
      </div>
      <div className="container_bg" />
    </header>
  );
};

export default Header;
