import { Typography } from "@mui/material";
import { StylesProvider } from "@mui/styles";
import React from "react";

// Variante (h1,h2,h3,h4,h5,h6...,subtitle, subtitle2..., subtitle,body....)

const TypographyComponent = ({ children, variant, className, ...props }) => {
  return (
    <StylesProvider injectFirst>
      <Typography variant={variant} className={className} {...props}>
        {children}
      </Typography>
    </StylesProvider>
  );
};

export default TypographyComponent;
