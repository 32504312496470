import React, { useState } from "react";
import Container from "components/atoms/Container/Container";
import ContentTer from "components/molecules/Content/ContentTer";
import BarreButton from "components/molecules/BarreButton/BarreButton";
import Chart from "../../molecules/Chart";
import ChartSmall from "../../molecules/ChartSmall";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid } from "@mui/material";
import IconComponent from "components/atoms/Icons/IconComponent";

import "./blocconsograph.scss";
import moment from "moment";

const BlocConsoGraph = ({
  billedUsageData = [],
  rawUsageData = [],
  unit,
  timeFrame,
  onChangeUnit,
  view = "LARGE",
  children,
  loading,
  numberOfBarToDisplay
}) => {

  const [offsetCursor, setOffsetCursor] = useState(0)

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1024,
        xl: 1200,
      },
    },
  });

  function windowedData(data){
    return data.slice(-numberOfBarToDisplay -offsetCursor, offsetCursor !== 0 ? -offsetCursor : undefined )
  }

  function canGoNext(){
    return offsetCursor > 0
  }
  function canGoPrevious(){
    return offsetCursor < billedUsageData.length-1 && billedUsageData.length - offsetCursor > numberOfBarToDisplay
  }

  const slidePrevious = () => {
    if(canGoPrevious()){
      setOffsetCursor(offsetCursor + 1)
    }
  }
  const slideNext = () => {
    if(canGoNext()){
      setOffsetCursor(offsetCursor - 1)
    }
  }

  return (
    <Container className="blocConsoGraph">
      <ThemeProvider theme={theme}>
        {children}
        <div className="container-gray">
          {canGoPrevious() && <div className="chart-swap left"  onClick={slidePrevious}>
            <IconComponent name={"Before"} color="white" />
          </div>}
          {canGoNext() &&<div className="chart-swap right" onClick={slideNext}>
            <IconComponent name={"Next"} color="white" />
          </div>}
          <Grid container spacing={2} className="header-container">
            <Grid item xs={12} sm={7}>
              <ContentTer
                iconName={"MaConso"}
                iconColor={"white"}
                variant="h2"
                title={"Ma consommation"}
              >
                <TypographyComponent style={{ margin: "4px 0 0" }}>
                  Données mises à jour le {moment().format("DD/MM/YYYY")}
                </TypographyComponent>
              </ContentTer>
            </Grid>

            <Grid item xs={12} sm={5}>
              <div className="barreButton-container">
                <BarreButton
                  labelA={"€(TTC)"}
                  labelB={"kWh"}
                  value={unit}
                  onChange={onChangeUnit}
                />
              </div>
            </Grid>
          </Grid>
          <div className="chart-container">
              <Chart key={1} billedUsageData={windowedData(billedUsageData)} rawUsageData={windowedData(rawUsageData)} unit={unit} timeFrame={timeFrame} loading={loading} />
          </div>
        </div>
      </ThemeProvider>
    </Container>
  );
};

export default BlocConsoGraph;
