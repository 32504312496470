import React from "react";
import Container from "components/atoms/Container/Container";
import IconComponent from "components/atoms/Icons/IconComponent";
import Link from "components/atoms/Link/Link";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import { useTranslation } from "react-i18next";
import { getSsoLink } from "../../../core/services/API/SSOZendesk/getLinkSSO";

const BlocMoyenPaiementFEP = ({ auto = false, info = {} }) => {
  const { t } = useTranslation();

  return (
    <Container style={{ height: "100%" }} className="paymentMeansFEP">
      <div style={{ display: "flex" }}>
        <TypographyComponent variant="p" style={{ fontWeight: "bold" }}>
          {t("pages.facturesEtPaiements.title3")}
        </TypographyComponent>
        <IconComponent
          name="Help"
          style={{ marginLeft: "8px", paddingBottom: "2px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "12px",
        }}
      >
        {info ? (
          <>
            <TypographyComponent style={{ fontWeight: "bold" }}>
              {info?.holder}
            </TypographyComponent>

            <TypographyComponent>{info?.iban}</TypographyComponent>

            {/* <TypographyComponent>{info?.bankName}</TypographyComponent> */}

            <TypographyComponent style={{ fontWeight: "bold" }}>
              {t("infosperso.RUM1")} {info?.mandate}
            </TypographyComponent>
            <TypographyComponent
              variant="p"
              style={{ fontSize: "14px", marginTop: "14px" }}
            >
              {t("pages.facturesEtPaiements.click1")}
              <Link className="black" onClick={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=9452548116253&tf_subject=Je modifie mon IBAN pour les prochains prélèvements")}>
                {t("pages.facturesEtPaiements.click2")}
              </Link>
            </TypographyComponent>
          </>
        ) : (
          <>
            {" "}
            <TypographyComponent
              variant="p"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              {t("pages.facturesEtPaiements.sub1")}
            </TypographyComponent>
            <TypographyComponent
              variant="p"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              {t("pages.facturesEtPaiements.sub2")}
            </TypographyComponent>
            <TypographyComponent
              variant="p"
              style={{ fontSize: "14px", marginTop: "14px" }}
            >
              {t("pages.facturesEtPaiements.click1")}
              <Link className="black" onClick={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=9452548116253&tf_subject=Je modifie mon IBAN pour les prochains prélèvements")}>
                {t("pages.facturesEtPaiements.click2")}
              </Link>
            </TypographyComponent>
          </>
        )}
      </div>
    </Container>
  );
};

export default BlocMoyenPaiementFEP;
