/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import IconComponent from "components/atoms/Icons/IconComponent";
import MenuContrat from "components/organisms/MenuContrat/MenuContrat";

import "./menu.scss";

import { useHistory } from "react-router-dom";
import Button from "components/atoms/Button/Button";
// import Link from "components/atoms/Link/Link";
// import { useTranslation } from "react-i18next";
import { getSsoLink } from "core/services/API/SSOZendesk/getLinkSSO";

const Menu = ({
  menuList,
  collectOpen,
  listContrats,
  selected,
  onChange,
  isOpen,
}) => {
  const history = useHistory();
  // const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState();
  const [open, setOpen] = useState(isOpen === true ? true : false);
  const [showCTA, setShowCTA] = useState(true);
  const [opacityTransition, setOpacityTransition] = useState(false);

  const [isOpenContrat, setIsOpenContrat] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("stateMenu"))
      localStorage.setItem("stateMenu", true);
  }, []);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, open]);

  const handleClick = () => {
    setOpen(!open);
    localStorage.setItem("stateMenu", !open);
  };

  const handleMenuItemClick = (index, link) => {
    setOpen(isOpen);
    setSelectedIndex(index);
    history.push(link);
  };



  useEffect(() => {
    const currentIndex = menuList?.findIndex(
      (l) => l.href === history?.location?.pathname
    );
    setSelectedIndex(currentIndex);
  }, [menuList]);

  const handleClickContrat = () => {
    setOpen(true);
    localStorage.setItem("stateMenu", true);
    setIsOpenContrat(!isOpenContrat);
    onChange(!isOpenContrat);
  };

  useEffect(() => {
    if (open === true) {
      setTimeout(() => {
        setShowCTA(true);
      }, 500);
      setOpacityTransition(false);
    }
    if (open === false) {
      setTimeout(() => {
        setShowCTA(false);
      }, 750);
      setTimeout(() => {
        setOpacityTransition(true);
      }, 800);
    }
    collectOpen(open);
  }, [handleClick]);

  return (
    <div className={`menu_desk ${open ? "open" : "closed"}`}>
      <div className="wrapper_btn">
        <Button className="arrowMenu" onClick={handleClick}>
          <IconComponent name="ArrowMenu" />
        </Button>
      </div>
      <div className="nav_contrat" onClick={handleClickContrat}>
        <MenuContrat
          listContrat={listContrats}
          selected={selected}
          statut={open}
        />
      </div>
      <ul className="nav_desk">
        {menuList?.map((option, index) => (
          <li
            key={option.label}
            className={`${index === selectedIndex ? "selected " : ""}`}
            onClick={() => handleMenuItemClick(index, option.href)}
            title={option.label}
          >
            {option.icon && (
              <span className="icon">
                <IconComponent name={option.icon} />
              </span>
            )}
            <p className="custom_nav_label">{open && option.label}</p>
          </li>
        ))}
      </ul>
      <div className="under-menu">
        <h4>Besoin d'aide ?</h4>

        {open
          ? showCTA && (
            <>
              <Button className="blue" onClick={() => getSsoLink("https://assistance.primeo-energie.fr")}>
                <IconComponent name="FAQ" />
                <p>Consulter notre FAQ</p>
              </Button>
              <Button className="green medium outline btn_demenage" onClick={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=8129099812509")}>

                <IconComponent name="Demenage" />
                <p>Je déménage</p>

              </Button>
            </>
          )
          : !showCTA && (
            <>
              <Button
                className={
                  opacityTransition ? "blue closed opacity" : "blue closed"
                }
                onClick={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/sections/8021931007517-Questions-fréquentes")}
                title="Voir notre FAQ"
              >
                <IconComponent name="FAQ" />
              </Button>
              <Button
                className={
                  opacityTransition
                    ? "green closed opacity btn_demenage_picto"
                    : "green btn_demenage_picto closed"
                }
                title="Déménager"
                onClick={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=8129099812509")}
              >

                <IconComponent name="Demenage" />

              </Button>
            </>
          )}
      </div>
    </div>
  );
};

export default Menu;
