import React from "react";
import { replacePointByCommaInDecimals } from "utils";

const CustomizedLabel = (props) => {
  const { x, y, width, height, value = false, unit = "" } = props;
  const radius = 10;

  const resultValue = unit === "kWh" ? value : value < 0 ? 0 : replacePointByCommaInDecimals(value.toFixed(2));

  return (
    <g>
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#808080"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize: "12px", fontWeight: "bold" }}
      >
        {value ? `${resultValue}${unit}` : ""}
        <br />
      </text>
    </g>
  );
};

export default CustomizedLabel;
