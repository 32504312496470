import React from "react";
import "./contratItemList.scss";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import { statutContrat } from "utils";

const ContratItemList = ({ contrat, ...props }) => {
  return (
    <div className={`contrat_itemList--wrapper`} {...props}>
      <header>
        <div className="contrat_code">
          <TypographyComponent variant="body2">
            <b>{contrat.id}</b>
          </TypographyComponent>
        </div>
      </header>
      <div className="contrat_info--wrapper">
        <div className="contrat_address">
          <TypographyComponent variant="body2">
            {contrat.address}
          </TypographyComponent>
        </div>
        <div className="contrat_address">
          <TypographyComponent variant="body2">
            {contrat.addressVille}
          </TypographyComponent>
        </div>
        {/* <div className="contrat_name">
          <TypographyComponent variant="body2">
            {contrat.name}
          </TypographyComponent>
        </div> */}
        <div className="contrat_pdl">
          <TypographyComponent variant="body2">
            <b> Point de livraison : {contrat.pdl}</b>
          </TypographyComponent>
        </div>

        <div className="contrat_dateStart">
          <TypographyComponent variant="body2">
            <b>Activé à partir du : {contrat.dateStart}</b>
          </TypographyComponent>
        </div>
        {contrat.dateEnd && (
          <div className="contrat_dateEnd">
            <TypographyComponent variant="body2">
              <b>Date de fin le : {contrat.dateEnd}</b>
            </TypographyComponent>
          </div>
        )}

        {contrat.dateResiliation && (
          <div className="dateResiliation">
            <TypographyComponent variant="body2">
              Votre contrat sera retiré de votre espace client le{" "}
              {contrat.dateResiliation}
            </TypographyComponent>
          </div>
        )}
        {contrat.statut && (
          <div className="contrat_status">
            <TypographyComponent variant="body2">
              {statutContrat(contrat.statut)}
            </TypographyComponent>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContratItemList;
