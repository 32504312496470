import Button from "components/atoms/Button/Button";
import IconComponent from "components/atoms/Icons/IconComponent";
import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useTranslation } from "react-i18next";

import "./layout.scss";
import CookiesManager from "../Cookies/CookiesManager";
import { useCookies } from "react-cookie";
import { getSsoLink } from "core/services/API/SSOZendesk/getLinkSSO";

const Layout = ({ children, rightContent, className }) => {
  const { t } = useTranslation();

  const [cookies] = useCookies(["cookies"]);
  const [showCookies, setShowCookies] = useState(
    !cookies.primeoID_session ? true : false
  );

  const footerMenu1 = [
    { href: `${t("footer.href1")}`, label: `${t("footer.label1")}`, target: "_blank" },
    { href: `${t("footer.href2")}`, label: `${t("footer.label2")}`, target: "_blank" },
    { label: `${t("footer.label3")}`, onClick: "SSO" },
  ];

  const footerMenu2 = [
    { href: `${t("footer.href4")}`, label: `${t("footer.label4")}` },
    { href: `${t("footer.href5")}`, label: `${t("footer.label5")}` },
    {
      onClick: "cookie",
      label: `${t("footer.label6")}`,
    },
    { href: `${t("footer.href7")}`, label: `${t("footer.label7")}`, target: "_blank" },
  ];

  // const rightContents = (
  //   <Button
  //     className="green"
  //     iconBefore={true}
  //     icon={<IconComponent name="User" />}
  //   />
  // );
  // specifique action footer
  const handleClickFooter = (v) => {
    if (v === "cookie") {
      setShowCookies(true)
    }
    if (v === "SSO") {
      getSsoLink(t("footer.href3"))
    }

  }

  return (
    <div className={className}>
      <Header rightContent={rightContent} />
      <main className="main_container_layout">{children}</main>
      {showCookies && (
        <CookiesManager close={(param) => setShowCookies(param)} />
      )}

      <Button
        className="green btn_cookies"
        iconBefore={true}
        icon={
          showCookies ? (
            <IconComponent name="Cross" />
          ) : (
            <IconComponent name="Cookie" color="white" />
          )
        }
        onClick={() => setShowCookies(!showCookies)}
      />
      <Footer
        footerMenu1={footerMenu1}
        footerMenu2={footerMenu2}
        onClick={handleClickFooter}
      />
    </div>
  );
};

export default Layout;
