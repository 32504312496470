import React, { useEffect, useState } from "react";
import ContratItem from "../../molecules/ContratItem/ContratItem";
import IconComponent from "components/atoms/Icons/IconComponent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import "./menuContrat.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectContract } from "core/services/API/Contract/ContractSlice";
import { capitalizeFirstLetter } from "utils";
import Button from "components/atoms/Button/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";

const MenuContrat = ({ listContrat, statut }) => {
  const { t } = useTranslation();
  const selected = useSelector((state) =>
    state.userContractsState.contracts.find((contrat) => contrat.selected)
  );

  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!statut) setIsOpen(false);
  }, [statut]);

  return (
    <div className="menuContrat_wrapper">
      <header
        className={`menuContrat--header ${isOpen ? "isOpen" : ""}`}
        onClick={handleClick}
      >
        <IconComponent name="PDL" className="pdlSvg" />

        <div className="menuContrat--selected">
          <TypographyComponent variant="body2">
            <b>Vos contrats :</b>
          </TypographyComponent>
          {selected?.PDL?.characteristics["PRIMARY_RESOURCE"] !== undefined && (
            <>
              <TypographyComponent variant="body2" className="p_address">
                {!!!selected?.PDL?.characteristics["adresseInstallation.rue"] ?
                  t("common.waitData") :
                  `${selected?.PDL?.characteristics["adresseInstallation.numRue"] ?? ''
                  } ${selected?.PDL?.characteristics[
                  "adresseInstallation.rue"
                  ]} `
                }
              </TypographyComponent>
              <TypographyComponent variant="body2" className="p_address">
                {!!!selected?.PDL?.characteristics["adresseInstallation.rue"] ?
                  "" :
                  `${selected?.PDL?.characteristics[
                  "adresseInstallation.codePostal"
                  ]
                  } ${selected?.PDL?.characteristics[
                  "adresseInstallation.libelleCommune"
                  ]
                  }`
                }
              </TypographyComponent>
              <TypographyComponent variant="body2" className="numberPDL">
                N° : {selected?.PDL?.characteristics["PRIMARY_RESOURCE"]}
              </TypographyComponent>
            </>
          )}
        </div>
        <div className="svgArrow">
          {isOpen ? (
            <IconComponent name="ArrowTop" />
          ) : (
            <IconComponent name="ArrowDown" />
          )}
        </div>
      </header>

      <div className={`menuContrat--content ${!isOpen && "close"}`}>
        {listContrat
          ?.slice(0, 4)
          .filter((v) => v.statut !== "ABORTED" || v.statut !== "CANCELLED ")
          ?.map((item, i) => (
            <ContratItem
              contrat={item}
              key={i}
              onClick={(e) => {
                dispatch(selectContract({ ouid: item.ouid, index: i }));
                setIsOpen(false);
              }}
              isSelected={selected.ouid === item.ouid ? true : false}
            />
          ))}
        <Button
          className="green noTopRadius"
          style={{ width: "100%", fontSize: "12px" }}
          onClick={() => history.push("/liste-contrats")}
        >
          Retourner vers la liste des contrats
        </Button>
      </div>
    </div>
  );
};

export default MenuContrat;
