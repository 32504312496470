import Button from "components/atoms/Button/Button";
import IconComponent from "components/atoms/Icons/IconComponent";
import Link from "components/atoms/Link/Link";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import BarreButton from "components/molecules/BarreButton/BarreButton";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import "./cookiesManager.scss";

const CookiesManager = ({ close }) => {
  const { t } = useTranslation();

  const idSession = uuidv4();

  const [stepNext, setStepNext] = useState(false);

  const [optionGA, setOptionGA] = useState("Oui");
  const [optionEssentiel, setOptionEssentiel] = useState("Oui");

  // Manager cookies

  const [cookies, setCookies, removeCookie] = useCookies(["cookies"]);

  const handleCookies = (nameCookie, valueCookie) => {
    setCookies(nameCookie, valueCookie, { path: "/" });
  };

  // When cookies is selected or valid add cookies id session
  const handleCookiesValid = () => {
    if (!cookies.primeoID_session) {
      setCookies("primeoID_session", idSession, { path: "/" });
    }
  };

  // switch all cookies true
  const AllTrue = () => {
    const change = "Oui";
    setOptionGA(change);
    setOptionEssentiel(change)
  };

  const handleAccepte = () => {
    AllTrue();
    handleValid();

    close(false);
  };

  const handleValid = () => {
    if (optionGA === "Oui") {
      handleCookies("ga_session", idSession, { path: "/" });
    } else {
      removeCookie("ga_session", { path: "/" });
    }
    // Essentiels
    if (optionEssentiel === "Oui") {
      handleCookies("essentiels", idSession, { path: "/" });
    } else {
      removeCookie("essentiels", { path: "/" });
    }

    // cookie valid
    handleCookiesValid();

    close(false);
  };

  const handleRefus = () => {
    handleCookiesValid()
    removeCookie("ga_session", { path: "/" });
    removeCookie("essentiels", { path: "/" });
    setOptionGA(false);
    setOptionEssentiel(false)
    // removeCookie("primeoID_session", { path: "/" });
    close(false);
  };

  // init statut btn
  useEffect(() => {

    if (cookies.ga_session) {
      setOptionGA("Oui");
    } else {
      setOptionGA("Non");
    }
    // essentiel
    if (cookies.essentiels) {
      setOptionEssentiel("Oui");
    } else {
      setOptionEssentiel("Non");
    }
  }, []);

  return (
    <div className="container_cookies">
      <div className="wrapper_content">
        {!stepNext ? (
          <div className="text">
            <TypographyComponent variant="h2">
              {t("cookies.titre1")}
            </TypographyComponent>
            <TypographyComponent variant="body2">
              {t("cookies.text1")}
            </TypographyComponent>
            <Link href="/" className="black">
              {t("cookies.linkTxt")}
            </Link>
          </div>
        ) : (
          <div className="text">
            <TypographyComponent variant="h2">
              {t("cookies.titre2")}
            </TypographyComponent>
            <TypographyComponent variant="body2">
              {t("cookies.text2")}
            </TypographyComponent>
          </div>
        )}
        <div className="picto">
          <IconComponent name="Cookie" color="black" />
        </div>
      </div>
      {/* Options  */}
      {stepNext && (
        <div className="wrapper_options">
          {/* Add line cookies options */}
          {/* GA */}
          <div className="line_options">
            <div className="options_text">
              <div className="wrapper_title">
                <span>
                  <IconComponent name="Next" />
                </span>
                <div className="wrapper_text">
                  <TypographyComponent variant="h3">
                    Google Analytics
                  </TypographyComponent>
                  <TypographyComponent variant="body2">
                    Permet d'analyser les statistiques de la consultation de
                    notre site
                  </TypographyComponent>
                </div>
              </div>
            </div>

            <BarreButton
              name="phone"
              value={optionGA}
              labelA={t("common.yes")}
              labelB={t("common.no")}
              onChange={(e) => setOptionGA(e)}
              className="orgBarreButton"
            />
          </div>
          {/*  Cookies Essentiels */}
          <div className="line_options">
            <div className="options_text">
              <div className="wrapper_title">
                <span>
                  <IconComponent name="User" />
                </span>
                <div className="wrapper_text">
                  <TypographyComponent variant="h3">
                    Cookies Essentiels
                  </TypographyComponent>
                  {/* <TypographyComponent variant="body2">
                  
                  </TypographyComponent> */}
                </div>
              </div>
            </div>

            <BarreButton
              name="phone"
              value={optionEssentiel}
              labelA={t("common.yes")}
              labelB={t("common.no")}
              onChange={(e) => setOptionEssentiel(e)}
              className="orgBarreButton"
            />
          </div>
        </div>
      )}

      <div className="wrapper_actions">
        {!stepNext ? (
          <>
            <Button className="green outline" onClick={() => handleRefus()}>
              {t("cookies.refus")}
            </Button>
            <Button className="green outline" onClick={() => setStepNext(true)}>
              {t("cookies.choix")}
            </Button>
            <Button className="green" onClick={() => handleAccepte()}>
              {t("cookies.accepter")}
            </Button>
          </>
        ) : (
          <>
            <Button
              className="green outline"
              onClick={() => setStepNext(false)}
            >
              {t("cookies.retour")}
            </Button>
            <Button className="green outline" onClick={AllTrue}>
              {t("cookies.toutAccepter")}
            </Button>
            <Button className="green" onClick={() => handleValid()}>
              {t("cookies.valide")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CookiesManager;
