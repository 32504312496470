import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import CheckboxButton from "components/atoms/Checkbox/CheckboxButton";
import TextInput from "components/atoms/Inputs/TextInput/TextInput";
import PasswordInput from "components/atoms/Inputs/PasswordInput/PasswordInput";
import Button from "components/atoms/Button/Button";
import LayoutAccueil from "components/organisms/Layout/LayoutAccueil";
import Container from "components/atoms/Container/Container";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";

import "./styles/auth.scss";
import Link from "components/atoms/Link/Link";
import { formatErrorEmail } from "utils";
import { handleLogin } from "core/services/httpRequest/authentification/authentificationService";

const Auth = () => {
  const [logsError, setLogsError] = useState(false);
  const [disableValid, setDisableValid] = useState(false);
  // const [password, setPassword] = useState(null);

  const { t } = useTranslation();

  const history = useHistory();

  const {
    setValue,
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur", reValidateMode: "onChange" });

  function connexion(data) {
    handleLogin(data.email, data.password).then((resp) => {
      if (resp.status === 201) {
        history.push("/liste-contrats");
        if (data.rememberMe === true) {
          localStorage.setItem(
            "rememberMe:primeo-ESCL",
            JSON.stringify({
              rememberMe: true,
              email: data.email,
            })
          );
        } else {
          localStorage.removeItem("rememberMe:primeo-ESCL");
        }
      } else {
        const time = setTimeout(() => setLogsError(false), 6000);
        setLogsError(true);
        return () => {
          clearTimeout(time);
        };
      }
    });
  }

  useEffect(() => {
    if (localStorage.getItem("rememberMe:primeo-ESCL") !== null) {
      let LOCAL = JSON.parse(localStorage.getItem("rememberMe:primeo-ESCL"));
      setValue("email", LOCAL.email);
      setValue("rememberMe", LOCAL.rememberMe);
    }
  }, []);

  useEffect(() => {
    if (!(!!getValues("password") && !!getValues("email"))) {
      setDisableValid(true);
    } else {
      setDisableValid(false);
    }
  }, [watch("email"), watch("password")]);

  // const handlePassword = (password) => {
  //   setPassword(password);
  // };

  return (
    <LayoutAccueil className="page_content_auth">
      <TypographyComponent variant="h1" className="authTitle">
        {t("auth.title")}
      </TypographyComponent>
      <Container className="container_white">
        <TypographyComponent variant="h1" className="authContainerTitle">
          {t("auth.connexion")}
        </TypographyComponent>
        <TypographyComponent variant="p" className="sub_blue">
          {t("auth.subtitle")}
        </TypographyComponent>
        <form onSubmit={handleSubmit(connexion)} noValidate autoComplete="off">
          <Controller
            name="email"
            rules={{ required: true, validate: formatErrorEmail }}
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                label={t("auth.email")}
                type="email"
                error={errors.email !== undefined}
                helperText={errors.email?.message}
                required
              />
            )}
          />
          <Controller
            name="password"
            rules={{ required: true }}
            control={control}
            render={({ field: { password, onChange, onBlur } }) => (
              <PasswordInput
                value={password}
                onChange={(e) => {
                  onChange(e.target.value);
                  // handlePassword(e.target.value);
                }}
                onBlur={onBlur}
                label={t("auth.pass")}
                required
              />
            )}
          />
          <Controller
            name="rememberMe"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxButton
                checked={value ? true : false}
                onChange={onChange}
                text={t("auth.rememberMe")}
                className="rememberMe"
              />
            )}
          />

          <Button className="green" type="submit" disabled={disableValid}>
            {t("auth.connect")}
          </Button>
          <span className={`error-message ${logsError && "isError"}`}>
            {t("auth.incorrect")}
          </span>
        </form>

        <Link
          className="black"
          style={{ marginRight: "4px" }}
          onClick={() => history.push("/password/forgotten")}
        >
          {t("auth.forgotPass1")}
        </Link>

        <div className="greyline" />

        <TypographyComponent variant="p" style={{ fontWeight: "bold" }}>
          {t("auth.firstConnexion")}
        </TypographyComponent>
        <Button className="green outline" onClick={() => history.push({
          pathname: '/password/forgotten',
          state: { first: true },
        })}>{t("auth.subscribe")}</Button>
      </Container>
    </LayoutAccueil>
  );
};

export default Auth;
