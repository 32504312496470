import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "core/services/httpRequest/AxiosConfig";

const initialState = {
  billingsAccountInfo: [],
  loading: false,
};

export const getBillingsAccount = createAsyncThunk(
  //action type string
  "/GET_BILLINGS_ACCOUNT_INFO",
  // callback function
  async (_, thunkAPI) => {
    const res = await axiosService.get("/billingAccount");

    const billingsInfo = {
      billingsAccountOuid: res.data[0].ouid,
      balance: res.data[0].balance,
      frequencyBillings: res.data[0].billingCycleSpecification.frequency,
      nextBillingDate: res.data[0].billingCycleSpecification.nextBillingDate,
      startBillingDate: res.data[0].billingCycleSpecification.startBillingDate,
      frequencyNumber: res.data[0].billingCycleSpecification.frequencyNumber,
      frequency: res.data[0].billingCycleSpecification.frequency
    };

    return billingsInfo;
  },
  //   Add condition if is already in the store
  {
    condition: (_, thunkAPI) => {
      const data = thunkAPI.getState();
      const fetchBillingsAccount = data.billingsAccount.billingsAccountInfo;

      if (Object.keys(fetchBillingsAccount).length > 0) {
        // Already fetched or in progress, don't need to re-fetch

        return false;
      }
    },
  }
);

export const billingAccountSlice = createSlice({
  name: "billingsAccount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBillingsAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBillingsAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.billingsAccountInfo = payload;
      })
      .addCase(getBillingsAccount.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default billingAccountSlice.reducer;
