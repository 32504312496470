import React, { useState, useEffect } from "react";

import Footer from "../Footer/Footer";
import HeaderAccueil from "../Header/HeaderAccueil";
import Button from "components/atoms/Button/Button";

import { ReactComponent as LogoBigBgSvg } from "core/assets/picto/logoBig.svg";
import { useTranslation } from "react-i18next";

import "./styles/layoutAccueil.scss";
import { useCookies } from "react-cookie";
import CookiesManager from "../Cookies/CookiesManager";
import IconComponent from "components/atoms/Icons/IconComponent";
import Header from "../Header/Header";
import { getSsoLink } from "core/services/API/SSOZendesk/getLinkSSO";

const LayoutAccueil = ({ children, className }) => {
  const { t } = useTranslation();

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [style, setStyle] = useState({});
  const [buttonText, setButtonText] = useState(t("auth.youProSmall"));

  const [cookies] = useCookies(["cookies"]);
  const [showCookies, setShowCookies] = useState(
    !cookies.primeoID_session ? true : false
  );

  const footerMenu1 = [
    { href: `${t("footer.href1")}`, label: `${t("footer.label1")}`, target: "_blank" },
    { href: `${t("footer.href2")}`, label: `${t("footer.label2")}`, target: "_blank" }
  ];

  const footerMenu2 = [
    { href: `${t("footer.href4")}`, label: `${t("footer.label4")}` },
    { href: `${t("footer.href5")}`, label: `${t("footer.label5")}` },
    {
      onClick: "cookie",
      label: `${t("footer.label6")}`,
    },
    { href: `${t("footer.href7")}`, label: `${t("footer.label7")}`, target: "_blank" },
  ];

  const detectSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    if (screenSize <= 600) {
      setStyle({ padding: "0 8px" });
    }
    if (screenSize <= 800) {
      setStyle({ padding: "0 8px" });
      setButtonText(t("auth.youProSmall"));
    }
    if (screenSize > 800) {
      setButtonText(t("auth.youProBig"));
    }
    if (screenSize <= 1024 && screenSize > 600) {
      setStyle({ padding: "0 30px" });
    }
    if (screenSize > 1024) {
      setButtonText(t("auth.youProBig"));
    }

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenSize]);

  const handleClickFooter = (v) => {
    if (v === "cookie") {
      setShowCookies(true)
    }

  }

  return (
    <div className="layoutAccueil">
      <Header
        rightContent={
          <Button className="green medium outline button-accueil">
            <a
              href="https://pro.primeo-energie.fr"
              target="_blank"
              rel="noreferrer"
              className="youPro"
            >
              {buttonText}
            </a>
          </Button>
        }
      />
      <main className="main_container" style={style}>
        <div className={className}>{children}</div>
      </main>
      {showCookies && (
        <CookiesManager close={(param) => setShowCookies(param)} />
      )}

      <Button
        className="green btn_cookies"
        iconBefore={true}
        icon={
          showCookies ? (
            <IconComponent name="Cross" />
          ) : (
            <IconComponent name="Cookie" color="white" />
          )
        }
        onClick={() => setShowCookies(!showCookies)}
      />

      <Footer
        footerMenu1={footerMenu1}
        footerMenu2={footerMenu2}
        onClick={handleClickFooter}
      />

      <LogoBigBgSvg className="logoBig-bg" />
    </div>
  );
};

export default LayoutAccueil;
