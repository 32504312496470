export const calendrierFrnCode = [
  { code: "FC000126", before: "", name: "Base", cadran: 1, isHPHC: false },
  {
    code: "FC000127",
    before: "Heure",
    name: "Pleines/Creuses",
    cadran: 2,
    isHPHC: true,
  },
  { code: "FC000128", before: "", name: "Tempo", cadran: 6, isHPHC: false },
  {
    code: "FC000201",
    before: "",
    name: "HP/HC x Saison H/B",
    cadran: 4,
    isHPHC: true,
  },
];

export function getCalendrierFrnCodeName(code) {
  return (
    calendrierFrnCode.find((item) => item.code === code) || {
      name: "Divers",
      before: "",
      cadran: 2,
      isHPHC: false,
    }
  );
}
