/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import LayoutPage from "components/organisms/Layout/LayoutPage";
import BlocConsoGraph from "components/organisms/BlocConsoGraph";
import ContainerItem from "components/atoms/ContainerItem/ContainerItem";
import { Grid } from "@mui/material";
import _ from "lodash";

import "./styles/consommation.scss";
import { formatHPHC, getSumPricesHCHP } from "utils";
import { useSelector } from "react-redux";
// import { getIndividual } from "core/services/API/Individual/AgentIndividualSlice";
// import { getContractInformations } from "core/services/API/Contract/ContractSlice";
import BlocInformationsUtiles from "components/organisms/BlocInformationsUtiles";
import { getCalendrierFrnCodeName } from "utils/calendrierFrnCodes";
import { getBilledKwhUsage, getKwhUsage, getRawKwhUsage, getUsage, getUsageV1 } from "core/services/httpRequest/usageAggregate";
import moment from "moment";

const Consommation = () => {
  const [unit, setUnit] = useState("€(TTC)");
  const [timeFrame, setTimeFrame] = useState("ANNÉE");

  const [view, setView] = useState("LARGE");
  const [dataLoad, setdataLoad] = useState(false);

  const [billedUsageData, setBilledUsageData] = useState([]);
  const [rawUsageData, setRawUsageData] = useState([]);

  const [margeBefore, setMargeBefore] = useState(0);
  const [margeAfter, setMargeAfter] = useState(0);
  const [selector, setSelector] = useState([0, 0]);
  const [offsetCursor, setOffsetCursor] = useState(0)

  const [numberOfBarToDisplay, setNumberOfBarToDisplay] = useState(10)


  const contrat = useSelector((state) =>
    state.userContractsState.contracts.find((contrat) => contrat.selected)
  );


  const adjustSize = () => {
    console.log("debug")
    setNumberOfBarToDisplay(10);
    let screenSize = window.innerWidth
    if (screenSize <= 1300 && !!screenSize) {
      setNumberOfBarToDisplay(8);
    }

    if (screenSize <= 1100 && !!screenSize) {
      setNumberOfBarToDisplay(6);
    }

    if (screenSize <= 600 && !!screenSize) {
      setNumberOfBarToDisplay(4);
    }

    if (screenSize <= 420 && !!screenSize) {
      setNumberOfBarToDisplay(2);
    }
  }

  useEffect(() => {
    const handleResize = () => adjustSize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    if (contrat?.souscription?.ouid) {
      getBilledKwhUsage(contrat?.souscription?.ouid).then(
        res => {
          setBilledUsageData(res)
          console.log(res)
        }
      );

    }
  }, [contrat])

  useEffect(() => {
    if (contrat?.souscription?.ouid) {
      getRawKwhUsage(contrat?.souscription?.ouid).then(res => {
        setRawUsageData(res)
      });
    }
  }, [contrat])
  
  return (
    <LayoutPage>
      <ContainerItem container spacing={3.5} className="">
        <Grid item xs={12} md={8}>
          <BlocConsoGraph
            billedUsageData={billedUsageData}
            rawUsageData={rawUsageData}
            unit={unit}
            timeFrame={timeFrame}
            onChangeUnit={setUnit}
            onChangeTimeFrame={setTimeFrame}
            view={view}
            loading={dataLoad}
            numberOfBarToDisplay={numberOfBarToDisplay}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BlocInformationsUtiles
            heureCreuses={
              getCalendrierFrnCodeName(
                contrat?.PDL?.characteristics["calendrierFournisseur"]
              )?.isHPHC
                ? formatHPHC(
                  contrat?.PDL?.characteristics["libellePlageHeuresCreuses"]
                )
                : false
            }
            numero={contrat?.PDL?.characteristics["numDepannage"]}
            departement={contrat?.PDL?.characteristics["departement"]}
          // showLinkyTips={false}
          />{" "}
        </Grid>
      </ContainerItem>
    </LayoutPage>
  );
};

export default Consommation;
