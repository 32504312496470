import { Grid } from "@mui/material";
import Image from "components/atoms/Image/Image";
import LogoFooter from "../../../core/assets/picto/LogoPrimeoFooter.svg";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import ListLink from "components/molecules/ListLink/ListLink";
import React from "react";
import { useTranslation } from "react-i18next";

import "./footer.scss";
import IconComponent from "components/atoms/Icons/IconComponent";
import Link from "components/atoms/Link/Link";

const Footer = ({ footerMenu1, footerMenu2, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        className="footer--container"
      >
        {/* <Grid item xs={2}></Grid> */}
        <Grid item xs={10.5} sm={1.5} md={2}>
          <TypographyComponent variant="h4">
            <b>{t("footer.nom")}</b>
          </TypographyComponent>
          <TypographyComponent variant="body2">
            {t("footer.adresse")}
          </TypographyComponent>
        </Grid>
        <Grid item xs={10.5} sm={1.5} md={2}>
          <ListLink list={footerMenu1} className="nav--footer" onClick={(c) => onClick(c)} />
        </Grid>
        <Grid item xs={10.5} sm={1.5} md={2}>
          <ListLink
            list={footerMenu2}
            className="nav--footer"
            onClick={(c) => onClick(c)}
          />
        </Grid>
        <Grid item xs={10.5} sm={1} md={2} className="rs--container">
          <TypographyComponent variant="h4">
            <b>{t("footer.follow")}</b>
          </TypographyComponent>
          <div className="rs">
            <Link
              href={t("footer.facebook")}
              target="_blank"
              rel="noreferrer"
              className="white"
            >
              <IconComponent name="Facebook" />
            </Link>
            <Link
              href={t("footer.twitter")}
              target="_blank"
              rel="noreferrer"
              className="white"
            >
              <IconComponent name="Twitter" />
            </Link>

            <Link
              href={t("footer.linkedin")}
              target="_blank"
              rel="noreferrer"
              className="white"
            >
              <IconComponent name="Linkedin" />
            </Link>

            <Link
              href={t("footer.instagram")}
              target="_blank"
              rel="noreferrer"
              className="white"
            >
              <IconComponent name="Insta" />
            </Link>
          </div>
        </Grid>
        {/* <Grid item xs={2}></Grid> */}
      </Grid>
      <Grid
        container
        className="footer--end"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item className="image--footer">
          <Image src={LogoFooter} alt="Primeo" className="Primeo" />
        </Grid>
        <Grid item>
          <TypographyComponent variant="body">
            {t("footer.accroche")}
          </TypographyComponent>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
