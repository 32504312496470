import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import { Box, Table, TableBody, TableRow } from "@mui/material";
import CustomTableHead from "components/organisms/Tables/CustomTableHead/CustomTableHead";
import PaginationManager from "components/organisms/Pagination/PaginationManager";
import TableCellItem from "components/atoms/TableCellItem/TableCellItem";
import "./styles/customtable.scss";
import { useTranslation } from "react-i18next";
import Loader from "components/atoms/Loader/Loader";

const TableComponent = ({
  header,
  datas,
  pagination = false,
  loading = false,
  numberRowLimit,
}) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [nbPages, setNbPages] = useState(
    Math.ceil(datas?.length / rowsPerPage)
  );

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);

    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });

    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = datas?.length === 0;

  useEffect(() => {
    if (!pagination) {
      setRowsPerPage(datas?.length);
    }
    setNbPages(Math.ceil(datas?.length / rowsPerPage));
  }, [datas]);

  const handleOnChange = (pageSeleted, rowPerPageSelected) => {
    setRowsPerPage(rowPerPageSelected);
    if (pageSeleted !== nbPages) {
      setPage(pageSeleted);
    }

    setNbPages(Math.ceil(datas.length / rowPerPageSelected));
  };

  const options = numberRowLimit
    ? stableSort(datas, getComparator(order, orderBy))?.slice(0, numberRowLimit)
    : stableSort(datas, getComparator(order, orderBy));

  return (
    <Box className="tableau_container">
      <TableContainer>
        <Table
          padding={"none"}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader={true}
        >
          <CustomTableHead
            headCells={header}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {options
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, i) => {
                return (
                  <TableRow key={i} tabIndex={1} className="custom-table-row ">
                    <TableCellItem data={data} header={header} />
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {emptyRows && (
        <div
          style={{
            height: "auto",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? <Loader /> : t("common.tableauEmpty")}
        </div>
      )}

      {pagination && (
        <div className="pagination_wrapper">
          <PaginationManager
            rows={datas}
            pagination={pagination}
            numbersPage={nbPages}
            currentPage={page}
            size={rowsPerPage}
            onChange={handleOnChange}
          />
        </div>
      )}
    </Box>
  );
};

export default TableComponent;
