import React, { useState } from "react";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import BlocContentSmall from "../../molecules/BlocContentSmall/BlocContentSmall";
import BarreButtonBool from "components/molecules/BarreButton/BarreButtonBool";
import { useTranslation } from "react-i18next";
import "./blocPreferenceCommunication.scss";

const BlocPreferenceCommunication = ({
  prefCoEmail,
  prefCoPhone,
  prefCoSMS,
  onChangeEmail,
  onChangePhone,
  onChangeSMS,
  loading
}) => {
  const { t } = useTranslation();

  return (
    <BlocContentSmall title={t("infosperso.prefComTitle")}>
      <TypographyComponent style={{ fontSize: "14px", marginBottom: "24px" }}>
        {t("infosperso.prefComText")}
      </TypographyComponent>
      <div className="barrebutton-wrapper">
        <div style={{ marginBottom: "24px", marginRight: "24px" }}>
          <TypographyComponent>{t("infosperso.sms")}</TypographyComponent>
          <BarreButtonBool
            name="SMS"
            value={prefCoSMS}
            labelA={t("common.yes")}
            labelB={t("common.no")}
            onChange={onChangeSMS}
            className="orgBarreButton"
            loading={loading}
          />
        </div>

        <div style={{ marginBottom: "24px", marginRight: "24px" }}>
          <TypographyComponent>{t("infosperso.email")}</TypographyComponent>
          <BarreButtonBool
            name="email"
            value={prefCoEmail}
            labelA={t("common.yes")}
            labelB={t("common.no")}
            onChange={onChangeEmail}
            className="orgBarreButton"
            loading={loading}
          />
        </div>

        <div>
          <TypographyComponent>{t("infosperso.phone")}</TypographyComponent>
          <BarreButtonBool
            name="phone"
            value={prefCoPhone}
            labelA={t("common.yes")}
            labelB={t("common.no")}
            onChange={onChangePhone}
            className="orgBarreButton"
            loading={loading}
          />
        </div>
      </div>
    </BlocContentSmall>
  );
};

export default BlocPreferenceCommunication;
