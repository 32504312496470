const fr = {
  lang: "français",
  actions: {
    save: "enregistrer",
  },
  all: {
    titlePage: "Espace Client Particuliers",
    resiBtn: "Je résilie mon contrat",
  },
  common: {
    yes: "Oui",
    no: "Non",
    tableauEmpty: "Aucune donnée",
    SSO: "https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=8129099812509",
    waitData: "Certaines données sont temporairement indisponibles",
  },
  error: {
    email: "Le format de l'adresse email est incorrect",
    required: "Ce champs est  requis.",
    phoneLength: "Le numéro doit comporter 10 chiffres",
    mustBeMobilePhone: "Le numéro doit commencer par 06 ou 07",
    mustBeMobilePhone2:
      "Le numéro doit commencer par 01, 02, 03, 04, 05, 06, 07 ou 09",
  },
  page403: {
    title: "Accès non autorisé",
    sousTitle:
      "Cliquez sur un des liens ci-dessous pour retrouver votre chemin",
    labelBtn1: "Accueil",
    labelBtn2: "Mon espace client",
  },
  page404: {
    title: "Cette page n'existe pas",
    sousTitle: "Cliquez sur un des liens ci-dessous pour rallumer la lumière",
    labelBtn1: "Accueil",
    labelBtn2: "Mon espace client",
  },
  pageFooter: {
    mentionsTitle: "Mentions Légales",
    protection: "POLITIQUE DE CONFIDENTIALITE PRIMEO BTC",
  },
  footer: {
    nom: "Primeo energie france",
    adresse: "8 Place Boulnois, 75017 Paris",
    follow: "Suivez-nous",
    accroche: "L'énergie est notre avenir, économisons la.",
    facebook: "https://www.facebook.com/primeo.energie.france/",
    twitter: "https://twitter.com/primeo_energie",
    linkedin: "https://www.linkedin.com/company/primeoenergiefrance",
    instagram: "https://www.instagram.com/primeo.energie.france/",
    label1: "Mon gestionnaire de réseaux",
    href1: "https://www.enedis.fr",
    label2: "J'ai une panne",
    href2: "https://www.enedis.fr/panne-et-interruption",
    label3: "J'ai une question",
    href3: "https://assistance.primeo-energie.fr/hc/fr",
    label4: "Mentions légales",
    href4: "/mentions-legales",
    label5: "Politique de protection des données",
    href5: "/protection-des-donnees",
    label6: "Gestion des cookies",
    href6: "/",
    label7: "CGV",
    href7: "https://www.primeo-energie.fr/conditions-generales-de-vente/",
  },
  pageListeContrat: {
    message: "Bonjour ",
    titre: "Choisissez votre contrat",
  },
  auth: {
    title: "Bienvenue sur votre espace personnel",
    connexion: "Connexion",
    subtitle: "S'identifier en saisissant les informations ci-dessous",
    email: "Email",
    pass: "Mot de passe",
    confirmPass: "Confirmation du mot de passe",
    rememberMe: "Se souvenir de moi",
    connect: "Se connecter",
    forgotPass1: "Mot de passe oublié ?",
    forgotPass2: "ou changement de mot de passe ?",
    firstConnexion: "C'est votre première connexion ?",
    subscribe: "Je crée mon mot de passe",
    youProBig: "Vous êtes un professionnel ?",
    youProSmall: "pro ?",
    btnLabelClientDesk: "Espace client",
    btnLabelClientResp: "Client",
    incorrect: "Login/mot de passe incorrect.",
    initPsw: "Création de mot de passe",
    initPswSub: "Recevoir un e-mail de création de mot de passe",
  },
  password: {
    back: "RETOUR",
    send: "ENVOYER",
    connect: "SE CONNECTER",
    continue: "CONTINUER",
    change: "CHANGER LE MOT DE PASSE",
    new: "Saisir votre nouveau mot de passe",
    repete: "Confirmer votre nouveau mot de passe",
    maj: "Une majuscule",
    min: "Une minuscule",
    number: "Un chiffre",
    spec: "Un caractère spécial",
    minlength: "8 caractères",
    step1: "Changement ou réinitialisation du mot de passe",
    step1sub: "Envoyer un e-mail de réinitialisation du mot de passe",
    step1info1:
      "Si vous ne recevez pas d'e-mail, assurez-vous d'avoir utilisé l'adresse avec laquelle vous vous êtes inscrit et vérifiez votre dossier spam.",
    step1info2:
      "Si vous réinitialisez votre mot de passe plusieurs fois, seul le lien de réinitialisation de mot de passe le plus récent sera valide.",
    step2:
      "L’e-mail de changement ou de réinitialisation du mot de passe a été envoyé !",
    step2sub:
      "Un e-mail vous a été envoyé avec les informations nécessaires pour modifier votre mot de passe.",
    step3: "Nouveau mot de passe",
    step3sub: "Choisir votre nouveau mot de passe",
    nomatch: "Les mots de passe ne correspondent pas",
    formaterror: "Mauvais format",
    step4: "Votre nouveau mot de passe a été créé avec succès !",
    step4sub: "Se connecter dès maintenant",
    current: "Renseigner mon ancien mot de passe",
    newpass: "Renseigner mon nouveau mot de passe",
    confirmnewpass: "Confirmer mon nouveau mot de passe",
    deco: "Déconnexion",
  },
  infosperso: {
    prefComTitle: "Préférences de communication",
    prefComText:
      "Pour les communications de Primeo autres que celles utiles à la bonne exécution de mon contrat, j'accepte d'être contacté par :",
    email: "Email",
    phone: "Téléphone",
    sms: "SMS",
    blocIdentifiantConnexionTitle: "Mes identifiants de connexion",
    emailConnexion: "Email de connexion",
    modifierEmail: "Modifier mon adresse email",
    pass: "Mot de passe",
    modifierPass: "Modifier mon mot de passe",
    blocCoordonneeTitle: "Mes coordonnées",
    titulaire: "Titulaire du contrat",
    cotitulaire: "Co-titulaire(s)",
    adresseFacturation: "Adresse de facturation",
    securityTip:
      "Pour des raisons de sécurité, la modification des éléments ci-dessus nécessitent l’intervention d’un conseiller.",
    securityTipAsk: "Demander la modification au Service Client.",
    telephone: "N° de téléphone",
    telephones: "N° de téléphones",
    modifierTelephone: "Modifier mes numéros",
    none: "Aucun",
    blocMoyenPaiementTitle: "Mon moyen de paiement",
    prelevementAuto: "Prélèvement automatique",
    RUM1: "Référence Unique de Mandat (RUM) :",
    RUM2: "PRIMEOfvcfu 4395 9C65 7642 3784 6286 1331",
    modifierIBAN: "Modifier mon IBAN",
    MALE: {
      gender: "Monsieur",
      born: "né le",
    },
    FEMALE: {
      gender: "Madame",
      born: "née le",
    },
  },
  tableauFacture: {
    title: "Factures",
    type: "Type",
    montant: "Montant (€ TTC)",
    date: "Date",
    reference: "Référence",
    echeance: "Échéance",
    statut: "Statut",
    titleFilter: "Vous pouvez filtrer par : ",
  },
  tableauPaiement: {
    title: "Paiements",
    type: "Type d'opération",
    montant: "Montant (€ TTC)",
    date: "Date",
    statut: "Statut",
    titleFilter: "Vous pouvez filtrer par : ",
  },
  tableauCTA: "Télécharger",
  cookies: {
    titre1: "Vous prendrez bien quelques Cookies ?",
    titre2: "À quoi servent nos Cookies ?",
    text1:
      "Afin d'améliorer nos services en continu et de vous proposer une expérience qualitative, nous utilisons des technologies telles que les cookies pour stocker et/ou accéder aux informations de l'appareil. C'est OK pour vous ?",
    text2:
      "Ils sont chargés de compter le nombre de visites sur ce site. Leur travail aide énormément les personnes qui travaillent ici, car ils leur permettrons de détecter de nombreux points d'amélioration.",
    retour: "Retour",
    refus: "Non merci",
    choix: "Choisir mes cookies",
    accepter: "J'accepte",
    toutAccepter: "J'accepte tout",
    valide: "Je valide mes choix",
    linkTxt: "Lire la politique de confidentialité",
  },
  pages: {
    contrat: {
      blocContrat: {
        titre: "Mon contrat",
        numero: "N° de contrat",
        debutFourniture: "Début de fourniture",
        titulaire: "Titulaire principal",
        coTitulaire: "Co-titulaires(s)",
        adresseFactu: "Adresse de facturation",
        modeFactu: "Mode de facturation",
        modePaiement: "Mode de paiement",
        souscription: "Souscription",
        coordonnee: "Voir mes coordonnées bancaires",
        tooltip:
          "Vous payez chaque mois le même montant correspondant à une estimation de votre consommation annuelle et recevez une facture de régularisation le dernier mois.",
        mensualise: "Mensualisation (échéancier)",
        mensualiseR: "Mensuelle au réel",
      },
      blocInstallation: {
        titre: "Mon installation",
        pdl: "Point de livraison (PDL)",
        adresse: "Adresse du PDL",
        numero: "N° de série du compteur",
        type: "Type de compteur",
        CCB: "Compteur Linky",
        CEB: "Compteur Bleu électronique",
        CFB: "Compteur Électromécanique",
        PSC: "Point sans comptage",
      },
      blocOffre: {
        titre: "Mon offre : ",
        puissance: "Puissance",
        optionTarif: "Option tarifaire",
        serviceActive: "Services primeo energie activés",
        serviceDispo: "Services primeo energie disponibles",
        unit: "kVA",
        heure: "Heures",
        offresActiveVide: "Aucun service n'est actif sur ce contrat.",
        offresServiceVide: "Aucun service n'est disponible sur ce contrat.",
      },
      showContent: "Voir le detail de l'offre",
      hideContent: "Masquer le detail de l'offre",
    },
    mesDocuments: {
      title: "Mes documents contractuels à télécharger",
      titleTable: "Mes factures, échéanciers et bilans de consommation",
      titleFilter: "Vous pouvez filtrer par : ",
    },
    informationUtile: {
      titre: "Mes informations utiles",
      hc: "Mes heures creuses",
      depannage: "Dépannage réseau",
      infoDepannage:
        "Le gestionnaire de mon réseau de distribution est Enedis.",
      panne: "Une panne, une coupure d'électricité ?",
      link: "Je fais un diagnostic sur Enedis.fr",
      question: "Comment bien utiliser mon Linky ?",
      reponse:
        "Tout savoir sur Linky, le nouveau compteur adapté à la vie d’aujourd’hui",
      appelUrgent: "Appel d'urgence réseau Enedis",
      telUrgent: "09 726 750 75",
      infoTel: "N° non surtaxé, 24h/24 et 7j/7",
      enSavoirPlus: "En savoir plus",
    },
    parrain: {
      title: "Mon code de parrainage",
      howToUse: "Comment utiliser mon code ?",
      condition: "Voir les conditions générales",
      tutorial: "Voir le tutoriel",
    },
    docTelechargement: {
      title: "Mes documents à télécharger",
    },
    facturesEtPaiements: {
      title1: "Ce que je dois",
      title2: "Mon mode de facturation",
      title3: "Mon moyen de paiement",
      pay: "Payer en ligne",
      mensualise: "Je suis mensualisé",
      prochainFact: "Prochaine facture estimée au ",
      mensualiseR: "Je suis facturé au réel tous {{frequency}} les mois",
      prochaine: "Prochaine facture de régularisation le ",
      consulter: "Consulter mes échéanciers",
      sub1: "Vous ne bénéficiez pas du prélèvement automatique.",
      sub2: "Vous pouvez demander le prélèvement automatique, payer par carte bancaire, ou par chèque.",
      click1: "Pour modifier votre moyen de paiement, ",
      click2: "cliquez ici.",
      fep: "Mes factures et paiements",
      type: "Type",
      date: "Date",
      statut: "Statut",
      filtrer: "Filtrer",
      paid: "Payée",
      effective: "Effectué",
      waiting: "En attente de paiement",
      mensualite: "Mensualité",
      facture: "Facture",
      echeance: "Echéance d'apurement",
      remboursement: "Remboursement",
      ref: "Ref échéancier",
      empty: "-",
    },
    accueil: {
      title:
        "Bienvenue dans l'espace réservé aux  clients particuliers de Priméo",
      description:
        "Proin at nulla eget in odio eget elementum habitant adipiscing. Commodo risus nibh ut lorem turpis accumsan. Tristique ultricies quisque orci vulputate id et massa molestie lorem. Eu parturient posuere duis massa eu amet diam proin.",
      labelBtn: "Je souscris",
      card1Title: "Flexibilité",
      card1Description:
        "Proin at nulla eget in odio eget elementum habitant adipiscing. Commodo risus nibh ut lorem turpis accumsan. Tristique ultricies quisque orci vulputate id",
      card2Title: "Réactivité",
      card2Description:
        "Proin at nulla eget in odio eget elementum habitant adipiscing. Commodo risus nibh ut lorem turpis accumsan. Tristique ultricies quisque orci vulputate id",
      card3Title: "Proximité",
      card3Description:
        "Proin at nulla eget in odio eget elementum habitant adipiscing. Commodo risus nibh ut lorem turpis accumsan. Tristique ultricies quisque orci vulputate id",
      titleNosOffres: "Découvrez nos services / nos offres",
    },
    dashboard: {
      linkJeDois: "Voir toutes mes factures et paiements",
    },
  },
};

export default fr;
