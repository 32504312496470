import { axiosService } from "../AxiosConfig";

const url = `${window.location.origin}${document.ENVIRONNEMENTS.API_LOGIN_PATH}`;

export const logout = () => {
  //handle logout
  localStorage.removeItem("accessToken");
  // Delete persist root
  localStorage.removeItem("persist:root");
  window.location.reload();
};

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const AuthVerify = () => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    const decodedJwt = parseJwt(token);
    if (
      (decodedJwt && decodedJwt.exp * 1000 < Date.now()) ||
      token === undefined
    ) {
      logout();
    } else {
      return token;
    }
  }
};

export const handleLogin = (email, password) => {
  return axiosService
    .post(`${url}/login/jwt`, {
      email: email,
      password: password,
    })
    .then((response) => {
      const token = response.data.token;
      localStorage.setItem("accessToken", token);
      return response;
    })
    .catch((e) => {
      return e;
    });
};
