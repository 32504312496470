import { Grid } from "@mui/material";
import IconComponent from "components/atoms/Icons/IconComponent";
import BlocContent from "components/molecules/BlocContent/BlocContent";
import Content from "components/molecules/Content/Content";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import NavLink from "components/atoms/NavLink/NavLink";
import "./blocInformationContrat.scss";

const BlocInformationContrat = ({
  contratNumber,
  dateStartSouscription,
  dateStartFourniture,
  titulaire,
  cotitulaires,
  adresse,
  modeFacturation,
  modePaiement,
}) => {
  const { t } = useTranslation();
  const [showToolTip, setshowToolTip] = useState(false);

  return (
    <BlocContent
      title={t("pages.contrat.blocContrat.titre")}
      variant="h2"
      iconName="Contrat"
      iconColor={"white"}
      iconStyle={{ height: "20px", width: "20px" }}
    >
      <Grid
        container
        spacing={3.5}
        style={{ padding: "0 24px", marginTop: "0" }}
      >
        {/* N° contrat */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"NumeroContrat"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocContrat.numero")}
          >
            <span>{contratNumber}</span>
          </Content>
        </Grid>

        {/* date fourniture */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"Date"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocContrat.debutFourniture")}
          >
            <span>{moment(dateStartFourniture)?.format("DD/MM/yyyy")}</span>
          </Content>
        </Grid>
        {/* titulaire  */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"Titulaire"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocContrat.titulaire")}
          >
            {t(`infosperso.${titulaire?.gender}.gender`)} {titulaire?.givenName}{" "}
            {titulaire?.familyName?.toUpperCase()}
          </Content>
        </Grid>
        {/* Souscription  */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"Souscription"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocContrat.souscription")}
          >
            <span>{moment(dateStartSouscription).format("DD/MM/yyyy")}</span>
          </Content>
        </Grid>
        {/* CoTitulaire  */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"CoTitulaire"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocContrat.coTitulaire")}
          >
            {cotitulaires?.length > 0
              ? cotitulaires?.map((c, index) => {
                return (
                  <div key={index} style={{ marginBottom: "4px" }}>
                    <span>
                      {" "}
                      {c?.gender} {c?.firstname} {c?.lastname?.toUpperCase()}
                    </span>
                  </div>
                );
              })
              : t("infosperso.none")}
          </Content>
        </Grid>
        {/* Adresse factu  */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"AdresseFacturation"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocContrat.adresseFactu")}
          >
            <span style={{ textTransform: 'uppercase' }}>
              {adresse?.street1}
              <br />
              {adresse?.street2 && (
                <>
                  {adresse?.street2}
                  <br />
                </>
              )}              
              {adresse?.supplement1 && (
                <>
                  {adresse?.supplement1}
                  <br />
                </>
              )}
              {adresse?.supplement2 && (
                <>
                  {adresse?.supplement2}
                  <br />
                </>
              )}
              {adresse?.postCode} {adresse?.city}
            </span>
          </Content>
        </Grid>
        {/* Mode facturation  */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"ModeFacturation"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocContrat.modeFactu")}
          >
            {modeFacturation && (
              <div className="content_with_tooltip">
                {modeFacturation === "MONTH" && (
                  <span>{t("pages.contrat.blocContrat.mensualiseR")}</span>
                )}
                {modeFacturation === "YEAR" && (
                  <span>{t("pages.contrat.blocContrat.mensualise")}</span>
                )}
                {modeFacturation === "YEAR" && (
                  <div
                    className="wrapper_tooltip"
                    onMouseEnter={() => setshowToolTip(true)}
                    onMouseLeave={() => setshowToolTip(false)}
                  >
                    <IconComponent
                      name="InfoTooltip"
                      color={`${showToolTip ? "bluePetrol" : "text"}`}
                    />
                    {showToolTip && (
                      <div
                        className="wrapped_tooltip"
                        onMouseLeave={() => setshowToolTip(false)}
                      >
                        <p>{t("pages.contrat.blocContrat.tooltip")}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </Content>
        </Grid>
        {/* Mode paiement  */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"ModeFacturation"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocContrat.modePaiement")}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{modePaiement}</span>
              <NavLink className="black" to={"/mes-infos-personnelles"} disabled={true}>
                {t("pages.contrat.blocContrat.coordonnee")}
              </NavLink>
            </div>
          </Content>
        </Grid>
      </Grid>
    </BlocContent>
  );
};

export default BlocInformationContrat;
