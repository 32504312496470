/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";

// Pages
// import Accueil from "pages/Accueil";
import Dashboard from "../pages/Dashboard";
import Auth from "pages/Login/Auth";
import ListContrats from "pages/ListContrats";
import FacturesEtPaiements from "pages/FacturesEtPaiements";
import MonContrat from "pages/MonContrat/MonContrat";
import InfosPerso from "pages/InfosPerso";
import MesDocuments from "pages/MesDocuments";
import Consommation from "pages/Consommation";
import Step1 from "pages/Login/PasswordResetSteps/Step1";
import Step2 from "pages/Login/PasswordResetSteps/Step2";
import Step3 from "pages/Login/PasswordResetSteps/Step3";
import Step4 from "pages/Login/PasswordResetSteps/Step4";

// Pages errors
import Page403 from "pages/Errors/403/Page403";
import Page404 from "pages/Errors/404/Page404";

// Pages footer
import Mentions from "pages/Mentions";
import ProtectionDonnees from "pages/ProtectionDonnees";

//Tracking
import { ID_trackingGA } from "core/services/Cookies/config";
import { useCookies } from "react-cookie";
import { AuthVerify } from "core/services/httpRequest/authentification/authentificationService";

const Routes = () => {
  const [cookies] = useCookies(["cookies"]);

  if (ID_trackingGA && cookies.primeoID_session) {
    ReactGA.initialize(ID_trackingGA);
  }

  useEffect(() => {
    if (ID_trackingGA && cookies.primeoID_session)
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // check token valid if refresh
  AuthVerify();

  const isAuthenticated = () => localStorage.getItem("accessToken");

  const PublicRoute = (props) =>
    !isAuthenticated() ? (
      <Route {...props} />
    ) : (
      <Redirect to={"/liste-contrats"} />
    );
  const PrivateRoute = (props) =>
    isAuthenticated() ? (
      <Route {...props} />
    ) : (
      <Redirect to={"/authentification"} />
    );

  return (
    <Switch>
      {/* Public */}
      <PublicRoute path="/" exact>
        <Auth />
      </PublicRoute>

      <PublicRoute path="/authentification" exact>
        <Auth />
      </PublicRoute>

      <Route path="/password/forgotten" >
        <Step1 />
      </Route>

      <Route path="/password/mail-sent" exact>
        <Step2 />
      </Route>

      <Route path="/password/new-password" exact>
        <Step3 />
      </Route>

      <Route path="/password/new-password-accepted" exact>
        <Step4 />
      </Route>

      {/* Private */}
      <PrivateRoute path="/ma-consommation" exact>
        <Consommation />
      </PrivateRoute>

      <PrivateRoute path="/tableau-de-bord" exact>
        <Dashboard />
      </PrivateRoute>

      <PrivateRoute path="/liste-contrats" exact>
        <ListContrats />
      </PrivateRoute>

      <PrivateRoute path="/contrat" exact>
        <MonContrat />
      </PrivateRoute>

      <PrivateRoute path="/factures-et-paiements" exact>
        <FacturesEtPaiements />
      </PrivateRoute>

      <PrivateRoute path="/mes-infos-personnelles" exact>
        <InfosPerso />
      </PrivateRoute>

      <PrivateRoute path="/documents" exact>
        <MesDocuments />
      </PrivateRoute>

      {/* Footer */}
      <Route path="/mentions-legales" exact>
        <Mentions />
      </Route>
      <Route path="/protection-des-donnees" exact>
        <ProtectionDonnees />
      </Route>

      {/* Errors */}
      <Route path="/403" exact>
        <Page403 />
      </Route>

      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  );
};

export default Routes;
