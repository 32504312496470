import React from "react";
import IconComponent from "components/atoms/Icons/IconComponent";
import "./link.scss";

const Link = ({
  href,
  target,
  icon,
  iconContent = <IconComponent name={"ArrowDown"} />,
  // iconPlacement = "right",
  onClick,
  className,
  children,
  disabled,
  ...props
}) => {
  return (
    <a
      href={href}
      onClick={onClick}
      className={`link ${disabled ? "disabled" : ""} ${className}`}
      target={target}
      rel="noreferrer"
      {...props}
    >
      {children} {icon && iconContent}
    </a>
  );
};

export default Link;
