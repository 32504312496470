import React from "react";
import "./styles/pagination.scss";

import usePagination from "@mui/material/usePagination";
import { List } from "@mui/material";
import IconComponent from "components/atoms/Icons/IconComponent";

const PaginationTab = ({
  count,
  onChange,
  currentPage,
  boundaryCount = 1,
  siblingCount = 1,
}) => {
  const { items } = usePagination({
    count: count,
    page: currentPage + 1,
    boundaryCount: boundaryCount,
    siblingCount: siblingCount,
  });

  return (
    <nav className="pagination_nav">
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = <div className="ellipsis">...</div>;
          } else if (type === "previous") {
            children = (
              <button type="button" className="prevBtn" {...item}>
                <IconComponent name="Before" />
                <span>Préc.</span>
              </button>
            );
          } else if (type === "next") {
            children = (
              <button type="button" className="nextBtn" {...item}>
                <span>Suiv.</span>
                <IconComponent name="Next" />
              </button>
            );
          } else if (type === "page") {
            children = (
              <button
                type="button"
                className={`${selected && "selected"}`}
                {...item}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button type="button" {...item}>
                {type}
              </button>
            );
          }

          return (
            <li onClick={(e) => onChange(e, page - 1)} key={index}>
              {children}
            </li>
          );
        })}
      </List>
    </nav>
  );
};

export default PaginationTab;
