import { configureStore } from "@reduxjs/toolkit";

import notificationStore from "./notificationSlice";
import infosPersoReducer from "core/services/API/Individual/AgentIndividualSlice";
import contractInformationsReducer from "core/services/API/Contract/ContractSlice";
import billingsAccountReducer from "core/services/API/BillingsCharges/BillingAccountSlice";
import paymentMeansReducer from "core/services/API/PaymentMeans/paymentMeansSlice";
import filesReducer from "core/services/API/Files/FilesSlices";
import userReducer from "core/services/httpRequest/authentification/authentificationSlice";
import paymentRequestReducer from "core/services/API/PaymentRequest/paymentRequestSlice";
import prefCoMeanReducer from "core/services/API/PreferedCommunicationMean/PreferedCommunicationMeanSlice";

const stores = configureStore({
  reducer: {
    notifications: notificationStore,
    individualState: infosPersoReducer,
    userContractsState: contractInformationsReducer,
    billingsAccount: billingsAccountReducer,
    paymentMeansData: paymentMeansReducer,
    allFiles: filesReducer,
    userInfo: userReducer,
    payments: paymentRequestReducer,
    prefCoMean: prefCoMeanReducer,
  },
});

export { stores };
