import React from "react";
import { TableCell } from "@mui/material";
import Link from "../Link/Link";
import IconComponent from "components/atoms/Icons/IconComponent";
import getFileContent from "core/services/API/Files/getFileContent";
import { statutPaymentIcon } from "utils";

const TableCellItem = ({ data, header }) => {
  const headerId = header.map((u) => u.id);

  const handleClick = (id, name) => {
    getFileContent(id, name);
  };

  return (
    <>
      {headerId.map((item, i) => (
        <React.Fragment key={i}>
          {item === "statut" ? (
            <TableCell align="left">
              {data["typeData"] === "factures" ? (
                data["statut"] === true ? (
                  <div className="wrapper_statut">
                    <div className="picto">
                      <IconComponent name="Active" color="primary" />
                    </div>
                    Payée
                  </div>
                ) : (
                  <div className="wrapper_statut">
                    <div className="picto">
                      <IconComponent name="Wait" />
                    </div>
                    En attente
                  </div>
                )
              ) : data["typeData"] === "paiements" ? (
                <div className="wrapper_statut">
                  <div className="picto">
                    <IconComponent
                      name={`${statutPaymentIcon(data[item])}`}
                      color={`${
                        statutPaymentIcon(data[item]) === "Error" ||
                        statutPaymentIcon(data[item]) === "Wait"
                          ? ""
                          : "primary"
                      }`}
                    />
                  </div>
                  {data[item]}
                </div>
              ) : (
                "-"
              )}
            </TableCell>
          ) : (
            <TableCell align={item === "montant" ? "right" : "left"}>
              <p>
                {data[item]}
                {item === "montant" ? "€" : ""}
              </p>
            </TableCell>
          )}
          {i === 5 && (
            <TableCell align="left">
              <Link
                className="cta black btn_dl_table"
                onClick={() => handleClick(data["telecharger"], data["name"])}
                target="_blank"
              >
                <div className="picto">
                  <IconComponent name="Telechargement" />
                </div>
                Télécharger
              </Link>
            </TableCell>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default TableCellItem;
