import React, { useEffect, useState } from "react";
import IconComponent from "components/atoms/Icons/IconComponent";
import { Box, Zoom } from "@mui/material";
import Button from "../Button/Button";
import "./scrollTopButton.scss";

const ScrollTopButton = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 650) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <Zoom in={showTopBtn}>
      <Box className="container_btnBackTop" role="backTop">
        <Button
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="green btn_backTop"
          aria-label="scroll to top"
        >
          <IconComponent name="Arrow" color="white" />
        </Button>
      </Box>
    </Zoom>
  );
};

export default ScrollTopButton;
