import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import React, { useState } from "react";

const AccordionCustom = ({
  panelName,
  icon,
  children,
  title,
  titleType = "h2",
  subtitle,
}) => {
  const [open, setOpen] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setOpen(isExpanded ? panel : false);
  };
  return (
    <Accordion expanded={open === "panel1"} onChange={handleChange("panel1")}>
      <AccordionSummary
        expandIcon={icon}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <TypographyComponent variant={titleType}>{title}</TypographyComponent>
        <TypographyComponent variant="subtitle">{subtitle}</TypographyComponent>
      </AccordionSummary>
      <AccordionDetails>
        <TypographyComponent variant="body">{children}</TypographyComponent>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionCustom;
