import React from "react";
import IconComponent from "components/atoms/Icons/IconComponent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";

const ContentBis = ({
  iconName,
  iconColor = "primary",
  title,
  variant = "h2",
  children,
  className,
}) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className={className}
    >
      <div
        style={{ alignItems: "center", display: "flex", flexDirection: "row" }}
        className="svg_wrappe"
      >
        <IconComponent
          name={iconName}
          color={iconColor}
          style={{ marginRight: "8px", height: "20px", width: "20px" }}
        />
        <TypographyComponent variant={variant}>{title}</TypographyComponent>
      </div>
      <div className="children_wrappe">{children}</div>
    </div>
  );
};

export default ContentBis;
