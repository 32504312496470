/**
 * @name handleRequestResponse
 * @description Execute function passed as args depending on request response status code
 * @param {Object} res API response
 * @param {function} onSuccess Function executed on success
 * @param {function} onFail Function executed on fail
 */
export default function handleRequestResponse(
   res,
   onSuccess = undefined,
   onFail = undefined
) {
   switch (res?.status) {
      case 200:
         return onSuccess();
      case 201:
         return onSuccess();
      case 400:
         return onFail();

      case 403:
         return onFail();

      case 404:
         return onFail();

      case 500:
         return onFail();

      default:
         break;
   }
}
