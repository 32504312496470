import React from "react";
import Button from "components/atoms/Button/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PasswordReset from "components/organisms/PasswordReset/PasswordReset";


const Step2 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PasswordReset>
      <div className="container_step">
        <h1>{t("password.step2")}</h1>
        <p className="blue">{t("password.step2sub")}</p>
        <div className="container_btn">
          <Button
            className="green medium outline"
            style={{ margin: "0 auto" }}
            onClick={() => history.push("/authentification")}
          >
            {t("password.back")}
          </Button>
        </div>
      </div>
    </PasswordReset>
  );
};

export default Step2;
