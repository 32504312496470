import { TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";

import { ReactComponent as ArrowDownSvg } from "core/assets/picto/arrowDown.svg";
import PaginationTab from "./PaginationTab";
import "./styles/pagination.scss";

const PaginationManager = ({
  numbersPage,
  currentPage,
  rows,
  pagination = true,
  onChange,
  size,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(size);
  const [page, setPage] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const boundaryCount = 1;
  const [siblingCount, setSiblingCount] = useState(0);

  const detectSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    if (screenSize <= 1024) {
      setSiblingCount(0);
    }
    if (screenSize > 1024) {
      setSiblingCount(1);
    }

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenSize]);

  const count = -1;

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onChange(0, parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    onChange(newPage, rowsPerPage);
  };

  return (
    pagination && (
      <div className="pagination_container">
        {numbersPage > 1 && (
          <PaginationTab
            count={numbersPage}
            onChange={handleChangePage}
            currentPage={page}
            boundaryCount={boundaryCount}
            siblingCount={siblingCount}
          />
        )}
        {rows.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "Tout", value: 1000 }]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={count === -1 ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="pagination_row"
            labelRowsPerPage={"Résultats par page :"}
            SelectProps={{
              IconComponent: ArrowDownSvg,
            }}
          />
        )}
      </div>
    )
  );
};

export default PaginationManager;
