import React, { useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { logout } from "core/services/httpRequest/authentification/authentificationService";

import { useHistory } from "react-router-dom";

import "./menu.scss";
import IconComponent from "components/atoms/Icons/IconComponent";
import Button from "components/atoms/Button/Button";
import { useTranslation } from "react-i18next";

const MenuRight = () => {
  // const history = useHistory();
  const [open, setOpen] = useState(null);
  const isOpen = Boolean(open);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setOpen(event.currentTarget);
  };

  // const handleMenuItemClick = (event, link) => {
  //   if (!!link) {
  //     history.push(link);
  //     setOpen(null);
  //   }
  // };
  const handleClose = () => {
    setOpen(null);
  };

  return (
    <div className="menu_right">
      <Button
        className={`green btn_userRight ${isOpen ? "open" : "closed"}`}
        onClick={handleClick}
      >
        <IconComponent name="User" />
      </Button>
      <Menu
        id="menuRight"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={open}
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem onClick={logout}>
          <span className="icon">
            <IconComponent name="Deco" />
          </span>
          <p>{t("password.deco")}</p>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MenuRight;
