import React from "react";
import IconComponent from "components/atoms/Icons/IconComponent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";

const Content = ({
  iconName,
  iconColor = "primary",
  title,
  variant = "h2",
  children,
  className,
}) => {
  return (
    <div style={{ display: "flex" }} className={className}>
      <div style={{ width: "24px" }} className="svg_wrappe">
        <IconComponent
          name={iconName}
          color={iconColor}
          style={{ marginRight: "4px", height: "20px", width: "20px" }}
        />
      </div>
      <div className="children_wrappe" style={{ overflowWrap: "anywhere" }}>
        <TypographyComponent variant={variant} style={{ marginBottom: "4px" }}>
          {title}
        </TypographyComponent>
        {children}
      </div>
    </div>
  );
};

export default Content;
