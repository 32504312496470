import React, { useState, useEffect } from "react";
import ContainerItem from "components/atoms/ContainerItem/ContainerItem";
import LayoutPage from "components/organisms/Layout/LayoutPage";
import BlocJeDois from "components/organisms/BlocJeDois";
import BlocTableauFEP from "components/organisms/BlocTableauFEP/BlocTableauFEP";
import BlocModeFacturationFEP from "components/organisms/BlocModeFacturation";
import BlocMoyenPaiementFEP from "components/organisms/BlocMoyenPaiement/BlocMoyenPaiementFEP";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./styles/facturesEtPaiements.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentMeans } from "core/services/API/PaymentMeans/paymentMeansSlice";
import moment from "moment";
import { statutPayment, typePayment } from "utils";

const FacturesEtPaiements = () => {
  const dispatch = useDispatch();

  // Selector
  const priceCharges = useSelector(
    (state) => state.billingsAccount.billingsAccountInfo
  );
  const paymentMeansData = useSelector(
    (state) => state.paymentMeansData.paymentMeans
  );
  const listFiles = useSelector((state) => state.allFiles);
  const listPayments = useSelector((state) => state.payments.paymentRequest);

  const [totalBilling, setTotalBilling] = useState(0);
  const [infoPaymentMeans, setInfoPaymentMeans] = useState();
  const [files, setFiles] = useState([]);
  const [filesPayment, setFilespayment] = useState([]);

  const [view, setView] = useState("LARGE");
  const [screenSize, setScreenSize] = useState(0);

  const { t } = useTranslation();

  const headCellsInvoice = [
    {
      id: "types",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.type"),
    },
    {
      id: "montant",
      numeric: true,
      disablePadding: true,
      label: t("tableauFacture.montant"),
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.date"),
    },
    {
      id: "reference",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.reference"),
    },
    {
      id: "echeance",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.echeance"),
    },
    {
      id: "statut",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.statut"),
    },
  ];

  const headCellsPayment = [
    {
      id: "types",
      numeric: false,
      disablePadding: true,
      label: t("tableauPaiement.type"),
    },
    {
      id: "montant",
      numeric: true,
      disablePadding: true,
      label: t("tableauPaiement.montant"),
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("tableauPaiement.date"),
    },
    {
      id: "statut",
      numeric: false,
      disablePadding: true,
      label: t("tableauPaiement.statut"),
    },
  ];

  const detectSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    if (screenSize <= 1024 && !!screenSize) {
      setView("SMALL");
    } else {
      setView("LARGE");
    }

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenSize]);

  useEffect(() => {
    dispatch(getPaymentMeans());
  }, []);

  useEffect(() => {
    if (priceCharges?.balance && priceCharges?.balance < 0) {
      setTotalBilling(priceCharges?.balance);
    } else setTotalBilling(0);
  }, [priceCharges]);

  useEffect(() => {
    if (paymentMeansData) {
      setInfoPaymentMeans(paymentMeansData);
    }
  }, [paymentMeansData]);

  useEffect(() => {
    if (listFiles) {
      setFiles(tableInvoices(listFiles));
    }
    if (listPayments) {
      setFilespayment(tablePayment(listPayments));
    }
  }, [listFiles, listPayments]);

  // Create list invoices
  const tableInvoices = ({ files }) => {
    const typeFiles = files?.invoices;
    let list = [];

    typeFiles?.forEach((v, i) => {
      const statutF = v.status === "PAID"
      list?.push({
        typeData: "factures",
        types: v.characteristics.type,
        montant: (v.taxIncludedAmount / 100)
          ?.toFixed(2)
          ?.toString()
          ?.replace(".", ","),
        date: moment(v.billDate).format("DD/MM/yyyy"),
        reference: v.ref,
        echeance: moment(v.echeance).format("DD/MM/yyyy"),
        statut: statutF,
        telecharger: v.idFile,
        name: v.fileNamePDF,
      });
    });
    list = list.sort((v1,v2) => {
      return moment(v2.date,"DD/MM/yyyy").diff(moment(v1.date,"DD/MM/yyyy"))
    })
    return list;
  };

  // Create list payment

  const tablePayment = (payments) => {
    const list = [];

    payments?.forEach((v, i) => {
      const statutP = statutPayment(v.status);
      list?.push({
        typeData: "paiements",
        types: typePayment(v.type),
        montant: (v.amount / 100)?.toFixed(2)?.toString()?.replace(".", ","),
        date: moment(v.date).format("DD/MM/yyyy"),
        statut: statutP,
      });
    });

    return list;
  };

  return (
    <LayoutPage>
      <ContainerItem container spacing={3.5} className="container-fep">
        <Grid item xs={12} sm={6} lg={4}>
          <BlocJeDois price={totalBilling} />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <BlocModeFacturationFEP
            mensualise={priceCharges.frequencyBillings}
            freqency={priceCharges.frequencyNumber}
            nextDateBill={priceCharges.nextBillingDate}
            date={priceCharges.nextBillingDate !== 0 ? moment(priceCharges.nextBillingDate).format("DD/MM/yyyy") : undefined}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <BlocMoyenPaiementFEP info={infoPaymentMeans} />
        </Grid>

        <Grid item xs={12}>
          <BlocTableauFEP
            view={view}
            datasInvoice={files}
            datasPayment={filesPayment}
            headCellsInvoice={headCellsInvoice}
            headCellsPayment={headCellsPayment}
            loading={listFiles.loading}
          />
        </Grid>
      </ContainerItem>
    </LayoutPage>
  );
};

export default FacturesEtPaiements;
