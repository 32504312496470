import BlocContent from "components/molecules/BlocContent/BlocContent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import TypographyComponent from "../../components/atoms/TypographyComponent/TypographyComponent";
import "./protection.scss";
import Layout from "components/organisms/Layout/Layout";

const ProtectionDonnees = () => {
    const { t } = useTranslation();
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const detectSize = () => {
        setScreenSize(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", detectSize);
    }, [screenSize]);

    return (
        <Layout>
            <div className="container-simplePage">
                <BlocContent
                    title={t("pageFooter.protection")}
                    variant="h2"
                    showIcon={false}
                >
                    <Grid
                        container
                        spacing={2.5}
                        style={{ padding: "0 24px", marginTop: "0" }}
                    >
                        <Grid item xs={12} sm={12}>
                            <TypographyComponent variant={"h3"} style={{ marginBottom: "4px" }}>
                                Préambule
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                Pour Primeo Energie France (ci-après dénommée « Primeo Energie France », « Primeo Energie » ou « le Fournisseur »), la protection des données personnelles de ses clients ainsi que de tout utilisateur de son site internet revêt une haute importance.
                                <br />Tous les traitements de données sont réalisés dans le respect des réglementations en vigueur en France, en particulier le <a href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees" target='_blank' rel="noreferrer">règlement n° 2016/679</a> relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données.
                                Le client particulier, utilisateur du site Internet Primeo Energie reconnaît avoir pris connaissance et accepté les termes de la présente Politique et ceux des conditions générales de prestation disponible sur <a href="https://www.primeo-energie.fr" target='_blank' rel="noreferrer">https://www.primeo-energie.fr</a>. Il est libre de ne pas utiliser les prestations et site internet du Fournisseur, et de n’y fournir aucune donnée personnelle, dans le cas où il serait en désaccord avec la présente Politique.
                                <br />La présente Politique de protection des données personnelles précise les types de données utilisées par le Fournisseur et sur quelles bases légales elles sont utilisées.
                            </TypographyComponent>

                            <TypographyComponent variant={"h3"} style={{ marginBottom: "4px" }}>
                                1. Lexique
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                <ul style={{ marginTop: '4px' }}>
                                    <li>Client: tout particulier ou personne physique capable au sens de la définition de l'article préliminaire du Code de la consommation qui visite le Site objet de la présente politique de protection des données. </li>
                                    <li>Prestations et Services : Primeo Energie France, via le site espace-client.primeo-energie.fr met à disposition des Clients <ul><li>Contenu</li><li>Informations clients</li></ul></li>
                                    <li>Utilisateur : Internaute se connectant, utilisant le site susnommé.</li>
                                    <li>Donnée à caractère personnel : Les informations qui permettent, sous quelque forme que ce soit, directement ou non, l’identification des personnes physiques auxquelles elles s’appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978)</li>
                                </ul>
                            </TypographyComponent>

                            <TypographyComponent variant={"h3"} style={{ marginBottom: "4px" }}>
                                2. Responsable du traitement des données personnelles
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                Dans le cadre des offres et services proposés par Primeo Energie France, Société par actions simplifiée  au capital social de 2 857 143 € – RCS PARIS 808 283 345 – 8 place Boulnois 75017 Paris, propriétaire du site internet susnommé, sont désignés :
                                <ul>
                                    <li>Délégué à la protection des données personnelles (DPO) : M. Vincent DI PIETRO - <a href="mailto:dpo@primeo-energie.fr" target="_blank" rel="noreferrer">dpo@primeo-energie.fr</a></li>
                                    <li>Responsable publication : M. Benoit DOIN – <a href="mailto:contact@primeo-energie.fr" target="_blank" rel="noreferrer">contact@primeo-energie.fr</a>. Le responsable publication est une personne physique ou une personne morale </li>
                                    <li>Webmaster: M. Octave LEBORGNE – <a href="mailto:contact@primeo-energie.fr" target="_blank" rel="noreferrer">contact@primeo-energie.fr</a></li>
                                </ul>
                            </TypographyComponent>

                            <TypographyComponent variant={"h3"} style={{ marginBottom: "4px" }}>
                                3. Traitement de données à caractère personnel
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                a.	Quelles données sont collectées<br />
                                Dans le cadre des offres et services proposés par Primeo Energie et l’exploitation de son site internet, peuvent être collectées des données suivantes vous concernant :
                                <ul style={{ marginTop: '4px' }}>
                                    <li>Données d’état civil : Noms et Prénoms</li>
                                    <li>Coordonnées : Numéro de téléphone fixe et/ou portable, adresses de messagerie et postale </li>
                                    <li>Informations du point de livraison (PDL) : numéro de PDL, Courbe de Charge </li>
                                    <li>Paiement : Relevé d’Identité Bancaire (RIB) et/ou autres modes de paiement de vos factures </li>

                                </ul>
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                b.  A quels moments la collecte des données personnelles survient<br />
                                Nous collectons vos données pour répondre notamment à une ou plusieurs des finalités lors :
                                <ul style={{ marginTop: '4px' }}>
                                    <li>De la navigation sur notre site internet <a href="espace-client.primeo-energie.fr" target='_blank' rel="noreferrer">espace-client.primeo-energie.fr</a>. Pour en savoir plus, veuillez lire l’article 7 des <a href="https://www.primeo-energie.fr/conditions-generales-de-vente/" target='_blank' rel="noreferrer">conditions générales d’utilisation</a> </li>
                                    <li>De la conclusion d’un contrat de fourniture d’électricité et/ou de services complémentaires </li>
                                    <li>De la création de votre espace client sur notre site internet</li>
                                    <li>D’une réclamation adressée au DPO concernant le traitement de vos données personnelles </li>
                                    <li>De la prise de contact avec notre service client via nos formulaires de contact </li>
                                    <li>Du consentement à des actions marketing du fournisseur et ses filiales </li>

                                </ul>
                                Ainsi, la fourniture de vos données devient volontaire et légale lorsqu’elle intervient dans les cas de figure suscités.
                            </TypographyComponent>

                            <TypographyComponent variant={"h3"} style={{ marginBottom: "4px" }}>
                                4.	Traitement des données
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                a.	Durée de conservation des données à caractère personnel <br />
                                Primeo Energie conservera les données collectées dans un environnement sécurisé dans la mesure raisonnablement nécessaire ou requise pour satisfaire aux obligations légales ou réglementaires, régler des litiges, empêcher les fraudes et abus ou appliquer nos modalités et conditions, nous pouvons également conserver certaines de vos informations si nécessaire, que nous n’ayons plus besoin de vous fournir nos services. <br />
                                La Plateforme conservera vos données pendant la durée requise pour fournir ses services ou son assistance, dans la limite des 10 ans fixée par la législation française (<a href="https://www.cnil.fr/fr/les-durees-de-conservation-des-donnees" target='_blank' rel='noreferrer'>https://www.cnil.fr/fr/les-durees-de-conservation-des-donnees</a>).
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                b.	Communication et non communication des données à caractère personnel  <br />
                                <ul style={{ marginTop: '4px' }}>
                                    <li>Communication des données à caractère personnel<br />
                                        Primeo Energie pourra, dans le cadre des services proposés, confier des activités à des partenaires ou filiales ; cela pourra impliquer un traitement de vos données par des sous-traitants. Il n’est ainsi fait recours à de tels sous-traitants que sur la base garantie suffisantes quant à la mise en place des mesures techniques et organisationnelles. Par ailleurs, cette sous-traitance répond aux exigences de fiabilité et de sécurité requises par la réglementation applicable et garantissant la protection des droits des personnes.
                                    </li>
                                    <li>
                                        Non communication des données à caractère personnel <br />
                                        Primeo Energie France s’interdit de traiter, héberger ou transférer les informations collectées sur ses Clients vers un pays situé en dehors de l’Union européenne ou reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement le client.  <br />
                                        Primeo Energie France s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident impactant l’intégrité ou la confidentialité des Informations du Client est portée à la connaissance de Primeo Energie France, celle-ci devra dans les meilleurs délais informer le Client et lui communiquer les mesures de corrections prises. Par ailleurs <a href="espace-client.primeo-energie.fr" target='_blank' rel="noreferrer">espace-client.primeo-energie.fr</a> ne collecte aucune « données sensibles » au sens de la CNIL.
                                        Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des Utilisateurs <a href="espace-client.primeo-energie.fr" target='_blank' rel="noreferrer">espace-client.primeo-energie.fr</a> sont principalement les agents de notre service client

                                    </li>
                                </ul>
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                c.	Notification d’incident dans le traitement des données à caractère personnel <br />
                                Quels que soient les efforts fournis, aucune méthode de transmission sur internet et aucune méthode de stockage électronique n’est complètement sûre. Nous ne pouvons en conséquence pas garantir une sécurité absolue. Si nous prenions connaissance d’une brèche de la sécurité, nous avertirions les utilisateurs concernés afin qu’ils puissent prendre les mesures appropriées. Nos procédures de notification d’incident tiennent compte de nos obligations légales aux niveaux national et européen. Nous nous engageons à informer pleinement nos clients de toutes les questions relevant de la sécurité de leur compte et à leur fournir toutes les informations nécessaires pour les aider à respecter leurs propres obligations réglementaires en matière de reporting. <br />
                                Aucune information personnelle de l’utilisateur du site <a href="espace-client.primeo-energie.fr" target='_blank' rel="noreferrer">espace-client.primeo-energie.fr</a> n’est publiée à l’insu de l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l’hypothèse du rachat de Primeo Energie France et de ses droits permettrait la transmission des dites informations à l’éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l’utilisateur du site <a href="espace-client.primeo-energie.fr" target='_blank' rel="noreferrer">espace-client.primeo-energie.fr</a>.
                            </TypographyComponent>

                            <TypographyComponent variant={"h3"} style={{ marginBottom: "4px" }}>
                                5. Mesures de sécurité
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                Pour être sûr d’être sur notre site web, veuillez vérifier que les mot « Primeo Energie » se trouvent dans l’adresse du navigateur. Cette adresse doit commencer obligatoirement par « https » ; la lettre « s » permet de vous assurer que le contenu est offert par un serveur sécurisé. <br />
                                Pour assurer la sécurité et la confidentialité des Données Personnelles, <a href="espace-client.primeo-energie.fr" target='_blank' rel="noreferrer">espace-client.primeo-energie.fr</a> utilise des réseaux protégés par des dispositifs standards tels que par pare-feu, pseudonymisation, chiffrement et mot de passe. Lors du traitement des Données Personnelles, Primeo Energie France prend toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction.
                            </TypographyComponent>

                            <TypographyComponent variant={"h3"} style={{ marginBottom: "4px" }}>
                                6. Mesures de sécurité
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                Conformément à la réglementation européenne en vigueur, les Utilisateurs de <a href="espace-client.primeo-energie.fr" target='_blank' rel="noreferrer">espace-client.primeo-energie.fr</a> disposent des droits suivants : <br />
                                <br />a.	Droit d’accès  <br />
                                Vous bénéficiez du droit de recevoir une copie de vos données dans un format lisible.
                                <br /><br />b.	Droit d’information, de complétude, de rectification et de suppression <br />
                                Si l’Utilisateur souhaite savoir comment espace-client.primeo-energie.fr utilise ses Données Personnelles, demander leur rectification ou s’opposer à leur traitement, l’Utilisateur peut contacter Primeo Energie France par écrit à l’adresse suivante :
                                <br />Primeo Energie France – DPO, Vincent Di Pietro – 8 place Boulnois – 75017 – Paris.
                                <br />Dans ce cas, l’Utilisateur doit indiquer les Données Personnelles qu’il souhaiterait que Primeo Energie France corrige, mette à jour ou supprime, en s’identifiant précisément avec une copie d’une pièce d’identité (carte d’identité ou passeport).
                                <br /><br />c.	Droit à la portabilité <br />
                                Vous pouvez bénéficier de ce droit lorsque vos données font l’objet de traitements automatisés fondés sur le consentement à ces traitements ou sur un contrat comme le décrit l’article 20 RGPD. Vous pouvez recevoir ainsi les données que vous avez fournies dans un format structuré et lisible, afin de le transmettre à un autre responsable de traitement.
                                <br /> <br />d.	Droit d’opposition droit d’opposition au traitement des données des Utilisateurs (article 21 RGPD)
                                <br /><br />e.	Droit à la suppression<br />
                                Vous avez le droit de supprimer à tout moment et sans délai votre consentement à l’utilisation de vos données à caractère personnel. Vous pouvez aussi supprimer vos données lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l’utilisation, la communication ou la conservation est interdite.
                                <br /><br />f.	Droit à la réparation du dommage matériel et moral<br />
                                Lorsque vous estimez avoir subi un dommage matériel ou moral du fait de la violation du règlement européen, vous pouvez obtenir auprès de notre responsable de traitement la réparation du préjudice.
                                <br /><br />g.	Droit à l’action de groupe <br />
                                Vous pouvez mandater un organisme spécialiste dans le domaine de la protection des données, afin de mener à bien un recours et obtenir réparation en cas de violation de vos données personnelles.
                                <br /><br />h.	Droit de définition du sort de vos données en termes d’utilisateurs après votre mort <br />
                                Vous avez le droit de choisir à qui <a href="espace-client.primeo-energie.fr" target='_blank' rel="noreferrer">espace-client.primeo-energie.fr</a> devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné. Dès que Primeo Energie France a connaissance du décès d’un Utilisateur et à défaut d’instructions de sa part, Primeo Energie France s’engage à détruire ses données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.
                            </TypographyComponent>

                            <TypographyComponent variant={"h3"} style={{ marginBottom: "4px" }}>
                                7.	Politique de Cookies
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                Le site peut collecter automatiquement des informations standards. Toutes les informations collectées indirectement ne seront utilisées que pour suivre le volume, le type et la configuration du trafic utilisant ce site, pour en développer la conception et l’agencement et à d’autres fins administratives et de planification et plus généralement pour améliorer le service que nous vous offrons<br />
                                Le cas échéant, des « cookies » émanant de l’éditeur du site et/ou des sociétés tiers pourront être déposés sur votre terminal, avec votre accord. Dans ce cas, lors de la première navigation sur ce site, une bannière explicative sur l’utilisation des « cookies » apparaîtra. Avant de poursuivre la navigation, le client et/ou le prospect devra accepter ou refuser l’utilisation desdits « cookies ». Le consentement donné sera valable pour une période de treize (13) mois. L’utilisateur a la possibilité de désactiver les cookies à tout moment.
                            </TypographyComponent>

                            <TypographyComponent variant={"h3"} style={{ marginBottom: "4px" }}>
                                8.	Loi applicable
                            </TypographyComponent>
                            <TypographyComponent variant={"body2"} style={{ marginBottom: "14px" }}>
                                Tout litige en relation avec l’utilisation du site Web <a href="espace-client.primeo-energie.fr" target='_blank' rel="noreferrer">espace-client.primeo-energie.fr</a> est soumis au droit français de la protection des données personnelles et encadrée par la loi du 6 janvier 1978 dite « Informatique et libertés »
                            </TypographyComponent>

                        </Grid>
                    </Grid>
                </BlocContent>
            </div>
        </Layout>
    );
};

export default ProtectionDonnees;
