import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "core/services/httpRequest/AxiosConfig";

const initialState = {
  user: [],
  loading: false,
};

export const getLoginInformation = createAsyncThunk(
  //action type string
  "/GET_LOGIN",
  // callback function
  async (_, thunkAPI) => {
    // async () => {
    const res = await axiosService.get("/login");
    return res?.data
  },
  // ,
  // //   Add condition is already in the store
  {
    condition: (_, thunkAPI) => {
      const data = thunkAPI.getState();
      const fetchInfo = data.userInfo;

      if (Object.keys(fetchInfo.user).length > 0) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoginInformation.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoginInformation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload;
      })
      .addCase(getLoginInformation.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default userSlice.reducer;
