import IconComponent from "components/atoms/Icons/IconComponent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import React from "react";
import "./servicesPrimeoDispoContent.scss";

const ServicesPrimeoActifContent = ({
  title,
  statut,
  icon,
  description,
  action,
}) => {
  return (
    <div className="actif_content">
      <div className="zone_picto">
        <IconComponent name={icon} color="white" />
      </div>
      <div className="zone_content">
        <TypographyComponent variant="h2">{title}</TypographyComponent>
        <div>
          <div className="wrapper_statut">
            <IconComponent name="Active" color="primary" />
            <p>{statut}</p>
          </div>
          <div className="txt_Action">
            <TypographyComponent variant="body2">
              {description}
            </TypographyComponent>
            {action}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesPrimeoActifContent;
