import React from "react";
import { Grid } from "@mui/material";

const ContainerItem = ({ children, ...props }) => {
  return (
    <Grid container {...props}>
      {children}
    </Grid>
  );
};

export default ContainerItem;
