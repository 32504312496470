import axiosService from "core/services/httpRequest/AxiosConfig";

async function getFileContent(IDfile, fileName) {
  return await axiosService
    .get(`/file/${IDfile}/content`, {
      responseType: "blob",
    })
    .then((res) => {
      // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //   // IE variant
      //   window.navigator.msSaveOrOpenBlob(
      //     new Blob([res.data], {
      //       type: "application/pdf",
      //       encoding: "UTF-8",
      //     }),
      //     fileName
      //   );
      // } else {
      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: "application/pdf",
          encoding: "UTF-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);

      link.click();
      link.remove();
      // }
    });
}


export default getFileContent;
