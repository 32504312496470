import Container from "components/atoms/Container/Container";
import IconComponent from "components/atoms/Icons/IconComponent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import React from "react";

import "./blocContent.scss";

const BlocContent = ({
  iconName,
  iconColor = " ",
  iconStyle,
  title,
  variant = "h1",
  children,
  showIcon = true,
  className,
}) => {
  return (
    <Container className={`container_content ${className}`}>
      <header>
        {showIcon && (
          <div
            style={{
              background: "#80DCF7",
              height: "32px",
              width: "32px",
              padding: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50px",
              marginRight: "10px",
            }}
          >
            <IconComponent
              name={iconName}
              color={iconColor}
              style={iconStyle}
            />
          </div>
        )}
        <TypographyComponent variant={variant} style={{ fontWeight: "bold" }}>
          {title}
        </TypographyComponent>
      </header>
      <div>{children}</div>
    </Container>
  );
};

export default BlocContent;
