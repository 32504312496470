import React from "react";
import Content from "components/molecules/Content/Content";
import BlocContent from "components/molecules/BlocContent/BlocContent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import Link from "components/atoms/Link/Link";

import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

const BlocMoyenPaiement = ({ infosPaiment, onClickModifyIBAN }) => {
  const { t } = useTranslation();

  return (
    <BlocContent
      title={t("infosperso.blocMoyenPaiementTitle")}
      variant="h2"
      iconName="Paper"
      iconColor="white"
      iconStyle={{ height: "20px", width: "20px" }}
      className="paymentMeans"
    >
      <Grid
        container
        spacing={2.5}
        style={{ padding: "0 24px", marginTop: "0" }}
      >
        <Grid item>
          <Content
            iconName={"Billet"}
            iconColor={"secondary"}
            variant="h3"
            title={t("infosperso.prelevementAuto")}
          >
            <TypographyComponent style={{ fontWeight: "bold" }}>
              {infosPaiment?.holder}
            </TypographyComponent>

            <TypographyComponent>{infosPaiment?.iban}</TypographyComponent>

            {/* <TypographyComponent>{infosPaiment?.bankName}</TypographyComponent> */}

            <TypographyComponent style={{ fontWeight: "bold" }}>
              {t("infosperso.RUM1")} {infosPaiment?.mandate}
            </TypographyComponent>

            {/* <TypographyComponent style={{ fontWeight: "bold" }}>
              {infosPaiment?.mandate}
            </TypographyComponent> */}

            <Link className="black" onClick={onClickModifyIBAN}>{t("infosperso.modifierIBAN")}</Link>
          </Content>
        </Grid>
      </Grid>
    </BlocContent>
  );
};

export default BlocMoyenPaiement;
