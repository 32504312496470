import React, { useState, useEffect } from "react";

import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import IconComponent from "components/atoms/Icons/IconComponent";
import "./passwordInput.scss";

const PasswordInput = ({
  value,
  label,
  placeholder,
  error,
  required = false,
  disabled = false,
  margin = "normal",
  variant = "outlined",
  fullwidth = true,
  style,
  InputLabelProps,
  id,
  name,
  onChange,
  onBlur,
  autoComplete,

  className,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [icon, seticon] = useState(<></>);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function handleEndAdornment() {
    return showPassword
      ? seticon(
          <IconButton
            style={{ marginRight: "-8px" }}
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            //   onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            <IconComponent name="Eye" />
          </IconButton>
        )
      : seticon(
          <IconButton
            style={{ marginRight: "-8px" }}
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            //   onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            <IconComponent name="Eye" />
          </IconButton>
        );
  }

  useEffect(() => {
    handleEndAdornment();
  }, [showPassword]);

  return (
    <div className={"input-text"}>
      <TextField
        id={id}
        label={label}
        placeholder={placeholder ?? label}
        InputLabelProps={{ ...InputLabelProps }}
        margin={margin}
        required={required}
        variant={variant}
        error={!!error}
        helperText={error?.message ?? ""}
        disabled={disabled}
        fullWidth={fullwidth}
        style={{ ...style }}
        type={showPassword ? "text" : "password"}
        name={"noAutoFill"}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{
          endAdornment: icon,
        }}
        {...props}
      />
    </div>
  );
};

export default PasswordInput;
