import React from "react";

import "./textInput.scss";
import TextField from "@mui/material/TextField";
import IconComponent from "components/atoms/Icons/IconComponent";

const TextInput = ({
  value,
  label,
  placeholder,
  error,
  required = false,
  disabled = false,
  margin = "normal",
  variant = "outlined",
  style,
  InputLabelProps,
  id,
  name,
  onChange,
  autoComplete,
  maxLength,
  className,
  ...props
}) => {
  const displayEndAdornment = () => {
    if (error === true && props.reset === false) {
      return <IconComponent name={"Error"} />;
    }
    if (error === true && props.reset === true) {
      return (
        <IconComponent
          name={"Error"}
          style={{ cursor: "pointer" }}
          onClick={props.resetFunction}
        />
      );
    }
  };

  return (
    <div className={"input-text"}>
      <TextField
        className={className}
        id={id}
        InputLabelProps={{ ...InputLabelProps }}
        onChange={onChange}
        label={label}
        placeholder={placeholder ?? label}
        margin={margin}
        required={required}
        variant={variant}
        error={!!error}
        helperText={error?.message ?? ""}
        disabled={disabled}
        fullWidth={true}
        style={{ ...style }}
        type={"text"}
        name={"noAutoFill"}
        autoComplete={autoComplete}
        value={value || ""}
        InputProps={{
          endAdornment: displayEndAdornment(),
          inputProps: {
            maxLength: maxLength,
          },
        }}
        {...props}
      />
    </div>
  );
};

export default TextInput;
