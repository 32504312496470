/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ContainerItem from "components/atoms/ContainerItem/ContainerItem";
import LayoutPage from "components/organisms/Layout/LayoutPage";
import BlocConsoGraph from "components/organisms/BlocConsoGraph";
import BlocTableauFEP from "components/organisms/BlocTableauFEP/BlocTableauFEP";
import BlocJeDois from "components/organisms/BlocJeDois";
import BlocAlertDash from "components/organisms/BlocAlertDash";
import BlocInformationsUtiles from "components/organisms/BlocInformationsUtiles";
import BlocDocTelechargement from "components/organisms/BlocDocTelechargement";
import Link from "components/atoms/Link/Link";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
// import BlocActuDash from "components/organisms/BlocActuDash";
// import image from "core/assets/eole.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatHPHC, getSumPricesHCHP, statutPayment, typePayment } from "utils";
import moment from "moment";
import { getCalendrierFrnCodeName } from "utils/calendrierFrnCodes";
import "./dash.scss";
import { getBilledKwhUsage, getRawKwhUsage, getUsage, getUsageV1 } from "core/services/httpRequest/usageAggregate";
import _ from "lodash";
import BlocCodeParrain from "components/organisms/BlocCodeParrain";

const Dashboard = () => {
  const [unit, setUnit] = useState("€(TTC)");
  const [timeFrame, setTimeFrame] = useState("ANNÉE");
  const [dates, setDates] = useState({
    begin: new Date().getTime() - 34187400000,
    end: new Date().getTime(),
  });
  const [data, setdata] = useState([]);
  const [dataV1, setdataV1] = useState([]);
  const [dataLoad, setdataLoad] = useState(false);
  const [view, setView] = useState("LARGE");
  const [viewFacure, setViewFacture] = useState("LARGE");
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [totalBilling, setTotalBilling] = useState(0);
  const [files, setFiles] = useState([]);
  const [paymentRequest, setPaymentRequest] = useState([]);
  const [DOC, setDOC] = useState([
    { title: "Mon justificatif de domicile", link: "/" },
  ]);

  const [dataDisplay, setDataDisplay] = useState([]);
  const [margeBefore, setMargeBefore] = useState(0);
  const [margeAfter, setMargeAfter] = useState(0);
  const [selector, setSelector] = useState([0, 0]);
  const [showNumberBar, setShowNumberBar] = useState(10)

  const priceCharges = useSelector(
    (state) => state.billingsAccount.billingsAccountInfo
  );

  const infos = useSelector((state) => state.individualState.infos);
  const contrat = useSelector(
    (state) => state.userContractsState.contracts.filter((c) => c?.selected)[0]
  );
  const codeParrain = useSelector((state) => state.individualState.connected.name);

  const listFiles = useSelector((state) => state.allFiles);
  const listPayments = useSelector((state) => state.payments.paymentRequest);

  const { t } = useTranslation();

  const [billedUsageData, setBilledUsageData] = useState([]);
  const [rawUsageData, setRawUsageData] = useState([]);

  const INFO = {
    text: "Pour télécharger votre contrat et vos CGV, rendez-vous dans",
    link: "/documents",
    txtLink: "Mes Documents",
  };

  const headCellsInvoice = [
    {
      id: "types",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.type"),
    },
    {
      id: "montant",
      numeric: true,
      disablePadding: true,
      label: t("tableauFacture.montant"),
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.date"),
    },
    {
      id: "reference",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.reference"),
    },
    {
      id: "echeance",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.echeance"),
    },
    {
      id: "statut",
      numeric: false,
      disablePadding: true,
      label: t("tableauFacture.statut"),
    },
  ];

  const headCellsPayment = [
    {
      id: "types",
      numeric: false,
      disablePadding: true,
      label: t("tableauPaiement.type"),
    },
    {
      id: "montant",
      numeric: true,
      disablePadding: true,
      label: t("tableauPaiement.montant"),
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("tableauPaiement.date"),
    },
    {
      id: "statut",
      numeric: false,
      disablePadding: true,
      label: t("tableauPaiement.statut"),
    },
  ];

  function formatJutificatifAndEcheancier(files) {
    // Prend les files du contrat et ne viens prendre que
    // l'échéancier et le justif de domicile pour les formater pour le composant

    let echeancier = {
      title: "Mon dernier échéancier",
      link: files?.echeancier?.[0]?.ouid,
      fileName: files?.echeancier?.[0]?.fileName,
    };
    let res = [{ title: "Mon justificatif de domicile", link: "/" }];
    if (priceCharges.frequency === "YEAR") {
      res.push(echeancier);
    }
    return setDOC(res);
  }

  const detectSize = () => {
    setScreenSize(window.innerWidth);
  };

  const tableInvoices = ({ files }) => {
    const typeFiles = files?.invoices;
    let list = [];

    typeFiles?.forEach((v, i) => {
      const statutF = v.status !== "DUE" ? true : false;
      list?.push({
        typeData: "factures",
        types: v.characteristics.type,
        montant: (v.taxIncludedAmount / 100)
          ?.toFixed(2)
          ?.toString()
          ?.replace(".", ","),
        date: moment(v.billDate).format("DD/MM/yyyy"),
        reference: v.ref,
        echeance: moment(v.echeance).format("DD/MM/yyyy"),
        statut: statutF,
        telecharger: v.idFile,
        name: v.fileNamePDF,
      });

    });
   
    list = list.sort((v1,v2) => {
      return moment(v2.date,"DD/MM/yyyy").diff(moment(v1.date,"DD/MM/yyyy"))
    })
    return list;
  };

  const tablePayment = (payments) => {
    const list = [];

    payments?.forEach((v, i) => {
      const statutP = statutPayment(v.status);
      list?.push({
        typeData: "paiements",
        types: typePayment(v.type),
        montant: (v.amount / 100)?.toFixed(2)?.toString()?.replace(".", ","),
        date: moment(v.date).format("DD/MM/yyyy"),
        statut: statutP,
      });
    });

    return list;
  };

  useEffect(() => {

    const newData = [...dataV1, ...data?.slice(1, data?.length)];

    const maxLength = newData.length;

    const marge = maxLength > showNumberBar ? maxLength - showNumberBar : 0;

    if (maxLength > showNumberBar) {
      setMargeBefore(marge)
      setSelector([maxLength - showNumberBar, maxLength])
      const dataGraph = newData?.slice(selector[0], selector[1]);

      setDataDisplay(dataGraph)
    } else {
      setMargeBefore(marge)
      setSelector([0, maxLength])
      setDataDisplay(newData)
    }

  }, [showNumberBar, screenSize, data])

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    setShowNumberBar(10);

    if (screenSize <= 1300 && !!screenSize) {
      setShowNumberBar(8);
    }

    if (screenSize <= 1100 && !!screenSize) {
      setShowNumberBar(6);
    }

    if (screenSize <= 600 && !!screenSize) {
      setShowNumberBar(4);
      setView("SMALL");
    } else {
      setView("LARGE");
    }
    if (screenSize <= 1024 && !!screenSize) {
      setViewFacture("SMALL");
    } else {
      setViewFacture("LARGE");
    }

    if (screenSize <= 420 && !!screenSize) {
      setShowNumberBar(2);
    }

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenSize]);




  useEffect(() => {
    if (priceCharges?.balance && priceCharges?.balance < 0) {
      setTotalBilling(priceCharges?.balance);
    } else setTotalBilling(0);
  }, [priceCharges]);

  useEffect(() => {
    if (listFiles) {
      setFiles(tableInvoices(listFiles));
    }
    if (listPayments) {
      setPaymentRequest(tablePayment(listPayments));
    }
    if (listFiles) {
      formatJutificatifAndEcheancier(listFiles?.files);
    }
  }, [listFiles, listPayments, priceCharges]);

  useEffect(() => {
    if (contrat?.souscription?.ouid) {
      getBilledKwhUsage(contrat?.souscription?.ouid).then(
        res =>
          setBilledUsageData(res)
      );

    }
  }, [contrat])

  useEffect(() => {
    if (contrat?.souscription?.ouid) {
      getRawKwhUsage(contrat?.souscription?.ouid).then(res => setRawUsageData(res));
    }
  }, [contrat])

  return (
    <LayoutPage>
      <ContainerItem container spacing={3.5} className="container-infosperso">
        <Grid item xs={12} md={8}>
          <BlocConsoGraph
            data={dataDisplay}
            unit={unit}
            timeFrame={timeFrame}
            onChangeUnit={setUnit}
            onChangeTimeFrame={setTimeFrame}
            view={view}
            loading={dataLoad}
            billedUsageData={billedUsageData}
            rawUsageData={rawUsageData}
          >
            <div className="dash-bienvenue">
              <TypographyComponent
                variant="h2"
                style={{ marginBottom: "20px" }}
              >
                Bonjour {infos?.givenName} {infos?.familyName?.toUpperCase()}
              </TypographyComponent>
              <TypographyComponent
                variant="p"
                style={{ marginBottom: "20px", color: "#57B9CF" }}
              >
                Bienvenue dans votre espace client
              </TypographyComponent>
              <BlocAlertDash status={contrat?.souscription?.status} />
            </div>
          </BlocConsoGraph>
          <BlocTableauFEP
            view={viewFacure}
            datasInvoice={files}
            headCellsInvoice={headCellsInvoice}
            datasPayment={paymentRequest}
            headCellsPayment={headCellsPayment}
            showFilters={false}
            pagination={false}
            link="/factures-et-paiements"
            loading={listFiles.loading}
            numberRowLimit={3}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BlocJeDois fullHeight={false} price={totalBilling}>
            <Link
              className="black"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              {t("pages.dashboard.linkJeDois")}
            </Link>
          </BlocJeDois>
          <BlocDocTelechargement listeDocuments={DOC} info={INFO} />
          <BlocInformationsUtiles
            heureCreuses={
              getCalendrierFrnCodeName(
                contrat?.PDL?.characteristics["calendrierFournisseur"]
              )?.isHPHC
                ? formatHPHC(
                  contrat?.PLD?.characteristics["libellePlageHeuresCreuses"]
                )
                : false
            }
            numero={contrat?.PDL?.characteristics["numDepannage"]}
            departement={contrat?.PDL?.characteristics["departement"]}
            showLinkyTips={false}
          />
          <BlocCodeParrain code={codeParrain}></BlocCodeParrain>

          {/* <BlocActuDash actualite={actus} /> */}
        </Grid>
      </ContainerItem>
    </LayoutPage>
  );
};

export default Dashboard;
