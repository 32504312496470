import React, { useState, useEffect } from "react";
import Logo from "../../../core/assets/picto/logoPrimeo.svg";

import "./headerPage.scss";
import Image from "components/atoms/Image/Image";
import Menu from "components/organisms/Menu/Menu";
import MenuResp from "../Menu/MenuResp";
import { useTranslation } from "react-i18next";

/**
 * @name Header
 * @param {node} rightContent Content on the right
 * @param {number} screenSize Size screen for resp
 * @param {array} menuPrimary First menu  and burger menu
 * @param {array} listContrats List contrats user
 */

const HeaderPage = ({
  selected,
  screenSize,
  menuPrimary,
  rightContent,
  collectOpen,
  listContrats,
  onChangeContratMenu,
  isOpen,
}) => {
  const { t } = useTranslation();

  // TODO  =>  ajouter une fonction uqi permet de switcher de contrat mais aussi de garde le bon contrat en mémoire pour le display

  return (
    <header className="headerPage">
      <div className="wrapper">
        <div className="container">
          <div className="left">
            {screenSize <= 1024 && (
              <MenuResp
                menuList={menuPrimary}
                listContrats={listContrats}
                selected={selected}
              />
            )}
            <div className="bloc_title">
              <Image src={Logo} alt="Logo Primeo" className="logoPrimeo" />
              <p className="title">{t("all.titlePage")}</p>
            </div>
            {screenSize > 1024 && (
              <Menu
                menuList={menuPrimary}
                collectOpen={collectOpen}
                listContrats={listContrats}
                selected={selected}
                onChange={onChangeContratMenu}
                isOpen={isOpen}
              />
            )}
          </div>
          <div className="right">{rightContent}</div>
        </div>
      </div>
      <div className="container_bg" />
    </header>
  );
};

export default HeaderPage;
