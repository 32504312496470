import axiosService from "core/services/httpRequest/AxiosConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { regroupContracts } from "utils";
import { getPDL } from "core/services/httpRequest/networkEntity";

const initialState = {
  contracts: [],
  catalog: [],
  loading: false,
};

export const getContractsInformations = createAsyncThunk(
  //action type string
  "/GET_CONTRACTS_INFORMATIONS",
  // callback function
  async (_, thunkAPI) => {
    const products = await axiosService.get(encodeURI("/product?filters={\"status\":\"ABORTED,ACTIVE,CANCELLED,CREATED,PENDING_ABORTED,PENDING_ACTIVE,PENDING_TERMINATED,PENDING_SUSPENDED,SUSPENDED,PENDING_REACTIVATED,TERMINATED\"}"));
    const catalog = await axiosService.get("/productOffering");
    const CONTRATCS = regroupContracts(products.data, catalog.data);

    return {
      contracts: CONTRATCS,
      catalog: catalog.data,
    };
  },
    // Add condition if infosPerso is already in the store
  {
    condition: (_, thunkAPI) => {
      const data = thunkAPI.getState();

      const fetchContractInformations = data.userContractsState;

      if (
        fetchContractInformations.contracts.length > 0 ||
        fetchContractInformations.catalog.length > 0
      ) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const getPDLList = createAsyncThunk(
  //action type string
  "/GET_PDL_LIST",
  // callback function
  async (_, thunkAPI) => {
    const data = thunkAPI.getState();
    const contracts = data.userContractsState.contracts;

    let fulfilledContracts = await Promise.all(
      contracts.map(async (contrat) => {
        return await getPDL(contrat.ouid).then((res) => {
          //
          // if (res.status !== 408) {
          return {
            ...contrat,
            PDL: res.data?.networkItem?.find((i) => i.name === "DEFAULT"),
          };
          // } else {
          //   getPDL(contrat.ouid).then((res) => {
          //     return {
          //       ...contrat,
          //       PDL: res.data?.networkItem?.find((i) => i.name === "DEFAULT"),
          //     };
          //   });
          // }
        });
      })
    );

    return { contracts: fulfilledContracts };
  },
  //   Add condition if infosPerso is already in the store
  {
    condition: (_, thunkAPI) => {
      const data = thunkAPI.getState();

      const fetchContractInformations = data.userContractsState.contracts;

      if (fetchContractInformations.find((c) => !!c?.PDL)) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const contractInformationsSlice = createSlice({
  name: "userContractsState",
  initialState,
  reducers: {
    selectContract: (state, { payload }) => {
      state.contracts = state.contracts.map((contract) => {
        if (contract.ouid === payload.ouid) {
          return { ...contract, selected: true };
        } else {
          return { ...contract, selected: false };
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContractsInformations.pending, (state) => {
        state.loading = false;
      })
      .addCase(getContractsInformations.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.contracts = payload.contracts;
        state.catalog = payload.catalog;
      })
      .addCase(getContractsInformations.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPDLList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPDLList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.contracts = payload.contracts;
      })
      .addCase(getPDLList.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { selectContract } = contractInformationsSlice.actions;
export default contractInformationsSlice.reducer;
