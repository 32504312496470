import React from "react";
import { Grid } from "@mui/material";
import Content from "components/molecules/Content/Content";
import BlocContent from "components/molecules/BlocContent/BlocContent";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils";

const BlocInstallation = ({
  pdl,
  adressePDL,
  numeroCompteur,
  typeCompteur,
}) => {
  const { t } = useTranslation();
  return (
    <BlocContent
      title={t("pages.contrat.blocInstallation.titre")}
      variant="h2"
      iconName="MonInstallation"
      iconColor={"white"}
      iconStyle={{ height: "24px", width: "24px" }}
    >
      <Grid container spacing={3} style={{ padding: "0 24px", marginTop: "0" }}>
        {/* pdl */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"MonInstallation"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocInstallation.pdl")}
          >
            <span> {pdl}</span>
          </Content>
        </Grid>

        {/* adresse pdl */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"PDL"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocInstallation.adresse")}
          >

            {!!!adressePDL.numero || !!!adressePDL.rue ?
              (
                <span>{t("common.waitData")}</span>
              ) : (
                <span style={{ textTransform: 'uppercase' }}>
                  {adressePDL.numero} {adressePDL.rue?.toLowerCase()} <br />
                  {adressePDL.zipCode} {capitalizeFirstLetter(adressePDL.commune)}
                </span>)

            }


          </Content>
        </Grid>

        {/* numero serie */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"NumeroContrat"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocInstallation.numero")}
          >
            {!!!numeroCompteur ?
              (
                <span>{t("common.waitData")}</span>
              ) : (
                <span>{numeroCompteur}</span>
              )
            }

          </Content>
        </Grid>

        {/* adresse pdl */}
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"TypeCompteur"}
            iconColor={"svgBlue"}
            variant="h3"
            title={t("pages.contrat.blocInstallation.type")}
          >
            {!!!typeCompteur ?
              (
                <span>{t("common.waitData")}</span>
              ) : (
                <span>
                  {t(`pages.contrat.blocInstallation.${typeCompteur}`)}
                </span>
              )
            }

          </Content>
        </Grid>
      </Grid>
    </BlocContent>
  );
};

export default BlocInstallation;
