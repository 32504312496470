import axiosService from "core/services/httpRequest/AxiosConfig";

const url = `${window.location.origin}${document.ENVIRONNEMENTS.API_LOGIN_PATH}`;

export async function getSsoLink(return_to) {
    // if (return_to) {

    return await axiosService.get(`${url}/zendesk/login/noredirect`).then((res) => {
        const urlSSO = res?.data?.redirectUrl;
        const encoded = encodeURIComponent(return_to);
        const link = document.createElement("a");
        link.target = '_blank';
        link.href = urlSSO + '&return_to=' + encoded;
        document.body.appendChild(link);

        link.click();
        link.remove();

    })

}



