import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import TextInput from "components/atoms/Inputs/TextInput/TextInput";
import { formatErrorEmail } from "utils";
import Button from "components/atoms/Button/Button";
import PasswordReset from "components/organisms/PasswordReset/PasswordReset";
import handleRequestResponse from "utils/responsesHandler";
import { askResetPassword } from "core/services/API/ResetPassword/resetPassword";
const Step1 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [first, setFirst] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur", reValidateMode: "onChange" });

  function submit(data) {
    askResetPassword(data.email).then((res) => {
      handleRequestResponse(
        res,
        () => {
          history.push("/password/mail-sent");
        },
        () => {
          return;
        }
      );
    });

    history.push("/password/mail-sent");
  }

  const location = useLocation();


  useEffect(() => {
    setFirst(location?.state?.first)

  }, [location])

  return (
    <PasswordReset>
      <>

        {first ? (
          <>
            <h1>{t("auth.initPsw")}</h1>
            <p className="blue">{t("auth.initPswSub")}</p>
          </>
        ) : (
          <>
            <h1>{t("password.step1")}</h1>
            <p className="blue">{t("password.step1sub")}</p>
          </>
        )}

        <form
          name="resetForm"
          onSubmit={handleSubmit(submit)}
          noValidate
          autoComplete="off"
        >
          <Controller
            name="email"
            control={control}
            rules={{
              required: t("error.required"),
              validate: formatErrorEmail,
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                label={t("auth.email")}
                placeholder={t("auth.email")}
                variant="outlined"
                type="email"
                error={errors.email !== undefined}
                helperText={errors.email?.message}
                required
              />
            )}
          />

          <div className="container_btn">
            <Button
              className="green medium outline "
              onClick={() => history.push("/authentification")}
            >
              {t("password.back")}
            </Button>
            <Button type="submit" className="green medium">
              {t("password.send")}
            </Button>
          </div>
        </form>
      </>
    </PasswordReset>
  );
};

export default Step1;
