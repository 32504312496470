import React, { useState } from "react";
import { Grid } from "@mui/material";
import BlocContent from "components/molecules/BlocContent/BlocContent";
import { useTranslation } from "react-i18next";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";

import "./BlocMonOffre.scss";
import IconComponent from "components/atoms/Icons/IconComponent";
import ServicesPrimeoActifContent from "components/molecules/ServicesPrimeoActifContent/ServicesPrimeoActifContent";
import ServicesPrimeoDispoContent from "components/molecules/ServicesPrimeoDispoContent/ServicesPrimeoDispoContent";
import Button from "components/atoms/Button/Button";
import Link from "components/atoms/Link/Link";
import { getSsoLink } from "../../../core/services/API/SSOZendesk/getLinkSSO";

const BlocMonOffre = ({
  offre,
  description,
  puissance,
  optionTarif,
  optionActive,
  optionDispo,
}) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(true);

  const action1 = <Button className="green" onClick={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=9477646074653&tf_subject=Je modifie mon numéro de téléphone")}>Modifier</Button>;

  const action2 = <Button className="green" onClick={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=9477646074653&tf_subject=Je souscris un service Primeo Energie en complément de mon contrat d\'électricité")}>Souscrire</Button>;

  // const action3 = <Button className="green">Souscrire</Button>;

  return (
    <BlocContent
      title={t("pages.contrat.blocOffre.titre") + " " + offre}
      variant="h2"
      iconName="MonOffre"
      iconColor={"white"}
      iconStyle={{ height: "24px", width: "24px" }}
      className="offre"
    >
      <TypographyComponent variant="body2" className="offre--description">
        {description}
      </TypographyComponent>
      <div className={`all_infos ${show ? "isShow" : "isHide"}`}>
        <Grid container>
          <Grid item xs={12} sm={6} className="offre-puissance">
            <TypographyComponent variant="h3" className="offre-title">
              {t("pages.contrat.blocOffre.puissance")}
            </TypographyComponent>
            <div className="wrapper_info">
              <b>{puissance}</b>

              <TypographyComponent variant="h3">
                {t("pages.contrat.blocOffre.unit")}
              </TypographyComponent>

              <IconComponent name="NumeroContrat" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className="offre-tarif">
            <TypographyComponent variant="h3" className="offre-title">
              {t("pages.contrat.blocOffre.optionTarif")}
            </TypographyComponent>
            <div className="wrapper_info">
              <TypographyComponent variant="h3">
                {optionTarif?.before}
              </TypographyComponent>

              <b>{optionTarif?.name}</b>
              <IconComponent name="Horloge" />
            </div>
          </Grid>
        </Grid>

        {optionActive?.length >= 0 && (
          <TypographyComponent variant="h3" className="title-service">
            {t("pages.contrat.blocOffre.serviceActive")}
          </TypographyComponent>
        )}
        {optionActive?.length === 0 && (
          <TypographyComponent variant="body2" className="zero-serviceActive">
            {t("pages.contrat.blocOffre.offresActiveVide")}
          </TypographyComponent>
        )}
        {optionActive?.map((o) => {
          return (
            <ServicesPrimeoActifContent
              key={o.ouid}
              title={o?.productSpecification?.name}
              icon={o?.name}
              description={o?.productSpecification?.description}
              statut={o.lifeCycleStatus === "ACTIVE" ? "Activé" : "Désactivé"}
              action={action1}
            />
          );
        })}

        <TypographyComponent variant="h3" className="title-service">
          {t("pages.contrat.blocOffre.serviceDispo")}
        </TypographyComponent>

        {optionDispo?.length === 0 && (
          <TypographyComponent variant="body2" className="zero-serviceActive">
            {t("pages.contrat.blocOffre.offresServiceVide")}
          </TypographyComponent>
        )}

        {optionDispo?.map((o) => {
          return (
            <ServicesPrimeoDispoContent
              key={o.ouid}
              title={o?.productSpecification?.name}
              icon={o?.name}
              description={o?.productSpecification?.description}
              price={
                o?.productsOfferingPrices[0]?.price?.taxIncludedAmount /
                100 /
                12?.toString()?.replace(".", ",") +
                "€"
              }
              action={action2}
            />
          );
        })}
      </div>
      <div className="wrapper_link">
        <Link onClick={() => setShow(!show)} className="black">
          {show
            ? t("pages.contrat.hideContent")
            : t("pages.contrat.showContent")}
        </Link>
      </div>
    </BlocContent>
  );
};

export default BlocMonOffre;
