import React from "react";
import Container from "components/atoms/Container/Container";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import Button from "components/atoms/Button/Button";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BlocModeFacturationFEP = ({
  mensualise,
  frequencyNumber,
  nextDateBill = "",
  date = "",
  onClickView = null,
}) => {
  const { t } = useTranslation();

  const history = useHistory()

  const newDate = nextDateBill !== 0 ? moment(nextDateBill).format("DD/MM/yyyy") : undefined;


  return (
    <Container style={{ height: "100%" }}>
      <TypographyComponent variant="p" style={{ fontWeight: "bold" }}>
        {t("pages.facturesEtPaiements.title2")}
      </TypographyComponent>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        {mensualise && (
          <>
            {mensualise === "MONTH" && (
              <>
                <TypographyComponent
                  variant="p"
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  {t("pages.facturesEtPaiements.mensualiseR", {
                    frequency: frequencyNumber < 2 ? "" : frequencyNumber,
                  })}
                </TypographyComponent>
                <TypographyComponent
                  variant="p"
                  style={{
                    fontSize: "12px",
                    margin: "8px 0",
                    textAlign: "center",
                  }}
                >
                  {newDate && (
                    <>
                      {t("pages.facturesEtPaiements.prochainFact")}

                      {newDate}
                    </>
                  )}

                </TypographyComponent>
              </>
            )}

            {mensualise === "YEAR" && (
              <>
                <TypographyComponent
                  variant="p"
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  {t("pages.facturesEtPaiements.mensualise")}
                </TypographyComponent>
                <TypographyComponent
                  variant="p"
                  style={{
                    fontSize: "12px",
                    margin: "8px 0",
                    textAlign: "center",
                  }}
                >
                  {date && (
                    <>
                      {t("pages.facturesEtPaiements.prochaine")}
                      {date}
                    </>
                  )}
                </TypographyComponent>
                <Button className="green" onClick={() => history.push("/documents")}>
                  {t("pages.facturesEtPaiements.consulter")}
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default BlocModeFacturationFEP;
