import React from "react";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import IconComponent from "components/atoms/Icons/IconComponent";
import NavLink from "components/atoms/NavLink/NavLink";
import { statutContrat, statutContratWarningBloc } from "utils";

const BlocAlertDash = ({ status }) => {
  //
  const defaultBlocAlert = (
    <div
      className="urgent"
      style={{
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div className="title">
        <div
          style={{
            backgroundColor: "#CE25251A",
            height: "48px",
            width: "48px",
            padding: "14px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
            borderRadius: "10px",
          }}
        >
          <IconComponent name="Attention" style={{ margin: "0" }} />
        </div>

        <div>
          {status === "TERMINATED" ? (
            <>
              <TypographyComponent variant="h3">
                {statutContratWarningBloc(status)}
              </TypographyComponent>
              <TypographyComponent variant="body2">
                Pensez à récupérer vos documents (factures, contrat...), dans la
                rubrique{" "}
                <NavLink className="black" to="/documents">
                  "Mes documents"
                </NavLink>
                .
              </TypographyComponent>
            </>
          ) : (
            <TypographyComponent variant="h3">
              {statutContratWarningBloc(status)}
            </TypographyComponent>
          )}
        </div>
      </div>
    </div>
  );

  function displayBloc(status) {
    switch (status) {
      case "ABORTED":
        return defaultBlocAlert;

      case "ACTIVE":
        return <></>;

      case "CANCELLED":
        return defaultBlocAlert;

      case "CREATED":
        return <></>;

      case "PENDING_ABORTED":
        return defaultBlocAlert;

      case "PENDING_ACTIVE":
        return defaultBlocAlert;

      case "PENDING_TERMINATED":
        return defaultBlocAlert;

      case "PENDING_SUSPENDED":
        return defaultBlocAlert;

      case "SUSPENDED":
        return defaultBlocAlert;

      case "PENDING_REACTIVATED":
        return defaultBlocAlert;

      case "TERMINATED":
        return defaultBlocAlert;

      default:
        return <></>;
    }
  }

  return <div>{displayBloc(status)}</div>;
};

export default BlocAlertDash;
