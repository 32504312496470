import React from "react";
import Container from "components/atoms/Container/Container";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import Button from "components/atoms/Button/Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./blocCodeParrain.scss";
import IconComponent from "components/atoms/Icons/IconComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BlocCodeParrain = ({
  price = "0",
  fullHeight = false,
  code = ""
}) => {
const { t } = useTranslation();
const history = useHistory();

const LINK_TUTORIAL = "https://particuliers.primeo-energie.fr/parrainage/"
const LINK_CGU = "https://particuliers.primeo-energie.fr/cgv-cgu-2/"

  return (
    <Container style={fullHeight ? { height: "100%" } : {}} className="blocCodeParrain">
      <TypographyComponent variant="p" style={{ fontWeight: "bold" }}>
        {t("pages.parrain.title")}
      </TypographyComponent>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "22px",
        }}
      >
        <div style={{display : "flex", alignContent : "baseline"}}>
        <div className="gift black" >
            <div className=" picto">
              <IconComponent name="Gift" />
            </div>
          </div>
        <TypographyComponent
          variant="h2"
          style={{ fontWeight: "bold",marginTop : "auto", marginBottom: "auto"}}
        >
          {code}
        </TypographyComponent>
        </div>
        <div className="info">
          <div className="title">
            <b>{t("pages.parrain.howToUse")}</b>
          </div>
          {(
            <div className="info_content" style={{ display: "flex", justifyContent: "space-around" }}>
              <Link className="link black" onClick={() => { window.open(LINK_TUTORIAL, "_blank"); }}>
                {t("pages.parrain.tutorial")}
                <div className="picto">
                  <IconComponent name="Link" />
                </div>

              </Link>
              <Link className="link black" onClick={() => { window.open(LINK_CGU, "_blank"); }}>
                {t("pages.parrain.condition")}
                <div className="picto">
                  <IconComponent name="Telechargement" />
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default BlocCodeParrain;
