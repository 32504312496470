import * as React from "react";
import { useHistory, Router as BrowserRouter } from "react-router-dom";
import "./core/styles/main.scss";

import Routes from "./Routes/Routes";

function App() {
  const history = useHistory();
  return (
    <div className="App">
      <BrowserRouter history={history} basename="/">
        <Routes />
      </BrowserRouter>
    </div>
  );
}

export default App;
