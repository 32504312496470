import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconComponent from "components/atoms/Icons/IconComponent";
import TypographyComponent from "../TypographyComponent/TypographyComponent";
import "./styles/notification.scss";
import { removeNotification } from "core/stores/notificationSlice";

const Notification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.notifs);

  const [alert, setAlert] = useState({ type: "", message: "", title: "" });
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (notifications.length > 0) {
      setAlert(notifications[notifications.length - 1]);
      setShow(true);
      setTimeout(() => {
        setShow(false);
        dispatch(removeNotification());
      }, 6000);
    }
  }, [notifications]);

  const onClose = () => {
    setShow(false);
    dispatch(removeNotification());
  };

  return (
    <div className={`toast  ${show ? "show" : ""}`}>
      <div className="crossNotif" onClick={onClose}>
        <IconComponent name="Cross" />
      </div>
      <div className="content">
        <TypographyComponent variant="h2">{alert.title}</TypographyComponent>
        <TypographyComponent variant="body2">
          {alert.message || ""}
        </TypographyComponent>
      </div>

      <div
        className="round-time-bar"
        style={{ "--duration": "6" }}
        data-style="smooth"
      >
        <div></div>
      </div>
    </div>
  );
};

export default Notification;
