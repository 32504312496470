import React from "react";
import { useTranslation } from "react-i18next";
import BlocContent from "components/molecules/BlocContent/BlocContent";
import IconComponent from "components/atoms/Icons/IconComponent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import Link from "components/atoms/Link/Link";
import NavLink from "components/atoms/NavLink/NavLink";

import "./blocDocTelechargement.scss";
import getFileContent from "core/services/API/Files/getFileContent";
// import Popin from "components/atoms/Popin/Popin";
// import Button from "components/atoms/Button/Button";
import { sendProofOfAddress } from "core/services/httpRequest/sendProofOfAddress";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BlocDocTelechargement = ({ listeDocuments = [], info = {} }) => {

  const history = useHistory();
  // const [openJustif, setOpenJustif] = useState(false);
  const productSerialNumber = useSelector(
    (state) =>
      state?.userContractsState?.contracts?.find((c) => c?.selected)
        ?.souscription?.productSerialNumber
  );

  const statutContrat = useSelector(
    (state) =>
      state?.userContractsState?.contracts?.find((c) => c?.selected)
        ?.souscription?.status
  );
  const { t } = useTranslation();

  const handleClick = (id, name, type) => {

    if(type === "link"){
      window.location.replace(id)
    }
    else if(type === "file"){
      getFileContent(id, name);
    }
  };

  function confirmSendJustif() {
    //   setOpenJustif(false);
    //   // API send justif
    sendProofOfAddress(productSerialNumber);
    //   return;
  }



  return (
    <BlocContent
      title={t("pages.docTelechargement.title")}
      variant="p"
      showIcon={false}
      className="documentDL"
    >
      {/* <Popin open={openJustif} onClose={() => setOpenJustif(false)}>
        <>
          <p>
            Votre attestation de contrat est en cours d'édition. Vous la
            recevrez par mail dans quelques instants. Cliquez pour valider
            l'envoi.
          </p>
          <Button
            style={{ margin: "20px auto 8px" }}
            className="green outline"
            onClick={() => confirmSendJustif()}
          >
            J'ai compris
          </Button>
        </>
      </Popin> */}
      <div className="wrapper_doc">
        {listeDocuments?.map((v, i) => {
          if (v?.title === "Mon justificatif de domicile") {
            return (
              <Link
                className="cta black"
                onClick={() => statutContrat === "PENDING_ACTIVE" ? "" : confirmSendJustif()}
                target="_blank"
                key={i}
                disabled={statutContrat === "PENDING_ACTIVE" ? true : false}
              >
                <div className="picto">
                  <IconComponent name="Telechargement" />
                </div>
                {v.title}
              </Link>
            );
          } else {
            return (
              <Link
                className="cta black"
                onClick={() => handleClick(v.link, v.fileName, v.type)}
                target="_blank"
                key={i}
              >
                <div className="picto">
                  <IconComponent name="Telechargement" />
                </div>
                {v.title}
              </Link>
            );
          }
        })}
      </div>

      <div className="info">
        <div className="wrapper_picto">
          <IconComponent name="Ampoule" />
        </div>
        <div className="info_content">
          <TypographyComponent variant="body2">{info.text}</TypographyComponent>
          <span className="span_mesdoc">"
            <NavLink className="black" to={info.link} disabled={true}>
              {info.txtLink}
            </NavLink>"
          </span>
        </div>
      </div>
    </BlocContent>
  );
};

export default BlocDocTelechargement;
