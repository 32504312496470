import axiosService from "core/services/httpRequest/AxiosConfig";

export async function sendProofOfAddress(productSerialNumber) {
  let body = {
    type: "PROOF_OF_ADDRESS",
    isCustomerVisible: true,
    parameters: { 
      productSerialNumber: productSerialNumber,
      pdfOptions: "margin-left=0mm&margin-right=0mm&margin-top=0mm&margin-bottom=13.3mm&footer-html=https://primeo.tripica.com/static/pdf/fragment/footerTrameB.html"
     },
  };
  return await axiosService
    .post("/file/composePdf", body, {
      responseType: "blob",
    })
    .then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: "application/pdf",
          encoding: "UTF-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Justificatif de domicile");
      document.body.appendChild(link);

      link.click();
      link.remove();
    });
}
