import React from "react";
import IconComponent from "components/atoms/Icons/IconComponent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";

const ContentTer = ({
  iconName,
  iconColor = "primary",
  iconStyle,
  title,
  titleStyle,
  variant = "h2",
  children,
  className,
}) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className={className}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            background: "#80DCF7",
            height: "32px",
            width: "32px",
            padding: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50px",
            marginRight: "10px",
          }}
        >
          <IconComponent name={iconName} color={iconColor} style={iconStyle} />
        </div>
        <TypographyComponent variant={variant} style={titleStyle}>
          {title}
        </TypographyComponent>
      </div>
      <div className="children_wrappe">{children}</div>
    </div>
  );
};

export default ContentTer;
