import Button from "components/atoms/Button/Button";
import React, { useState } from "react";

// style
import "./barreButton.scss";

/**
 * @name BarreButton
 * @param {name} name Name input
 * @param {string} labelA label first button
 * @param {string} labelB label last button
 * @param {function} onChange onChange
 * @param {boolean} error true if error
 * @param {string} otherlabelA if you want change label display in the button
 * @param {string} otherlabelB  if you want change label display in the button
 * @param {string} ClassName class
 *  @param {node} value value
 */

const BarreButton = ({
  name,
  value,
  labelA,
  labelB,
  onChange,
  error,
  otherLabelA,
  otherLabelB,
  className,
  ...props
}) => {
  const [labelACheck, setLabelACheck] = useState(false);
  const [labelBCheck, setLabelBCheck] = useState(false);

  const handleClickA = () => {
    onChange(labelA);
    setLabelACheck(!labelACheck);
    setLabelBCheck(!labelBCheck);
  };

  const handleClickB = () => {
    onChange(labelB);
    setLabelBCheck(!labelACheck);
    setLabelACheck(!labelBCheck);
  };

  return (
    <>
      <div className={`barreButton ${className || ""}`}>
        <label>
          <input
            type="radio"
            value={labelA}
            name={name}
            checked={value === labelA}
            onChange={() => setLabelACheck(!labelACheck)}
            onBlur={props.onBlur}
          />
          <Button
            className={`green outline ${value === labelA ? "checked" : ""}`}
            value={labelA}
            onClick={handleClickA}
            onBlur={props.onBlur}
          >
            {otherLabelA ? otherLabelA : labelA}
          </Button>
        </label>

        <label>
          <input
            type="radio"
            name={name}
            value={labelB}
            checked={value === labelB}
            onChange={() => setLabelBCheck(!labelBCheck)}
            onBlur={props.onBlur}
          />
          <Button
            className={`green outline ${value === labelB ? "checked" : ""}`}
            value={labelB}
            onClick={handleClickB}
            onBlur={props.onBlur}
          >
            {otherLabelB ? otherLabelB : labelB}
          </Button>
        </label>
      </div>
      {error ? <p className="barreButton_error">{error.message}</p> : undefined}
    </>
  );
};

export default BarreButton;
