import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "core/services/httpRequest/AxiosConfig";

const initialState = {
  prefCoMean: [],
  loading: false,
};

export const getPrefCoMean = createAsyncThunk(
  //action type string
  "/GET_PREF_CO_MEAN",
  // callback function
  async (_, thunkAPI) => {
    const res = await axiosService.get("/login");
    return res?.data;
  }
);

export const putPrefCoMean = createAsyncThunk(
  //action type string
  "/PUT_PREF_CO_MEAN",
  // callback function
  async (params, thunkAPI) => {
    const data = thunkAPI.getState();
    const catalog = data.userContractsState.catalog;
    const user = data.prefCoMean.prefCoMean;
    const billingsAccount = data.billingsAccount.billingsAccountInfo;
    // Récupérer les variables productOfferingOuid et productSpecificationOuid
    let productOfferingPrefComOuid = catalog?.find(
      (productOffering) => productOffering?.name === "LOGIN_CUSTOMER_PREFERENCE"
    )?.ouid;
    let productSpecificationPrefComOuid = catalog?.find(
      (productOffering) => productOffering?.name === "LOGIN_CUSTOMER_PREFERENCE"
    )?.productSpecification.ouid;
    let loginOuidPrefCom = user.ouid;
    let customerOuidPrefCom = user.customerOuid;
    let billingOuidPrefCom = billingsAccount.billingsAccountOuid;
    let startDateTime = new Date().getTime() - 60000;

    const bodyModificationOrder = {
      category: "LOGIN",
      customersRef: [
        {
          $$hashKey: "object:1701",
          customerOuid: customerOuidPrefCom,
          role: "OWNER",
          startDateTime: 1673445912497,
        },
      ],
      externalId: "TO_GENERATE",
      notes: [],
      notificationContact: "unused",
      orderDate: startDateTime,
      orderItems: [
        {
          $$hashKey: "object:1535",
          action: "ADD",
          billingAccountOuid: billingOuidPrefCom,
          product: {
            billingAccountOuid: billingOuidPrefCom,
            characteristics: {
              characteristics: JSON.stringify(params),
              loginOuid: loginOuidPrefCom,
            },
            customerOuid: customerOuidPrefCom,
            customersRef: [
              {
                $$hashKey: "object:1701",
                customerOuid: customerOuidPrefCom,
                role: "OWNER",
                startDateTime: startDateTime,
              },
            ],
            description: null,
            name: "LOGIN_CUSTOMER_PREFERENCE",
            orderDate: startDateTime,
            priority: null,
            productOfferingOuid: productOfferingPrefComOuid,
            productPrices: [
              {
                dateTimeCreate: 1420066800000,
                name: "LOGIN_CUSTOMER_PREFERENCE",
                price: {
                  currencyCode: "EUR",
                  dutyFreeAmount: 0,
                  percentage: 0,
                  priceType: "ONETIME",
                  taxIncludedAmount: 0,
                  taxRate: 0,
                },
                priceType: "ONETIME",
                recurringChargePeriodNumber: 0,
                startDateTime: startDateTime,
              },
            ],
            productRelationships: [],
            productSerialNumber: null,
            productSpecificationOuid: productSpecificationPrefComOuid,
            startDateTime: startDateTime,
            status: "CREATED",
          },
          productOfferingOuid: productOfferingPrefComOuid,
        },
      ],
      state: "ACKNOWLEDGED",
    };

    axiosService.post("/productOrder", bodyModificationOrder);
  }
);

export const prefCoMeanSlice = createSlice({
  name: "prefCoMean",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPrefCoMean.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrefCoMean.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.prefCoMean = payload;
      })
      .addCase(getPrefCoMean.rejected, (state) => {
        state.loading = false;
      })
      .addCase(putPrefCoMean.pending, (state) => {
        state.loading = true;
      })
      .addCase(putPrefCoMean.fulfilled, (state) => {
        // state.loading = false;
      })
      .addCase(putPrefCoMean.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default prefCoMeanSlice.reducer;
