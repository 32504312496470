import Button from "components/atoms/Button/Button";
import IconComponent from "components/atoms/Icons/IconComponent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import ContratItemList from "components/molecules/ContratItemList/ContratItemList";
import Layout from "components/organisms/Layout/Layout";
import MenuRight from "components/organisms/Menu/MenuRight";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { capitalizeFirstLetter } from "utils";
import moment from "moment";
import { getIndividual } from "core/services/API/Individual/AgentIndividualSlice";
import {
  getContractsInformations,
  getPDLList,
  selectContract,
} from "core/services/API/Contract/ContractSlice";
import _ from "lodash";
import Loader from "components/atoms/Loader/Loader";
import "./ListContrats.scss";

const ListContrats = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const loadingIndividual = useSelector(
    (state) => state.individualState.loading
  );
  const loadingContrat = useSelector(
    (state) => state.userContractsState.loading
  );

  const infos = useSelector((state) => state.individualState.infos);
  const contracts = useSelector((state) => state.userContractsState.contracts);

  const [contrats, setContrats] = useState([]);

  const dispatch = useDispatch();

  const handleClick = (payload) => {
    dispatch(selectContract(payload));
    history.push("/tableau-de-bord");
  };

  useEffect(() => {
    dispatch(getIndividual(true));
    dispatch(getContractsInformations());
  }, []);

  useEffect(() => {
    if (contracts.length > 0 && !!infos) {
      dispatch(getPDLList());
    }
    if (
      _.every(contracts, function (c) {
        return c?.PDL;
      })
    ) {
      let output = contracts.map((c, index) => {
        return {
          ouid: c?.ouid,
          id: c?.souscription.productSerialNumber,
          statut: c?.souscription.status,
          name: `${infos?.givenName} ${infos?.familyName}`,
          pdl: c?.PDL.characteristics.PRIMARY_RESOURCE,
          address: !!!c?.PDL.characteristics["adresseInstallation.rue"] ? t("common.waitData") :
            `${c?.PDL.characteristics["adresseInstallation.numRue"] ?? ""
            } ${c?.PDL.characteristics[
              "adresseInstallation.rue"
            ]?.toLowerCase()} `
          ,
          addressVille: !!!c?.PDL.characteristics["adresseInstallation.codePostal"] ? "" : `${c?.PDL.characteristics["adresseInstallation.codePostal"]
            } ${capitalizeFirstLetter(
              c?.PDL.characteristics["adresseInstallation.libelleCommune"]
            )}`,
          dateStart: moment(c?.PDL.characteristics.activationDate * 1)?.format(
            "L"
          ),
          // dateEnd: "04/08/2022",
        };
        
      });
      console.log(output)
      setContrats(output);
    }
  }, [contracts, infos, dispatch]);

  const rightContents = <MenuRight />;

  return (
    <Layout rightContent={rightContents}>
      <div className="container-liste-contrats">
        <TypographyComponent variant="body2" className="message">
          {t("pageListeContrat.message")}

          {!!infos.givenName === true
            ? infos?.givenName + " " + infos?.familyName
            : ""}
        </TypographyComponent>
        <div className="container-white">
          <TypographyComponent variant="h1" color="primary">
            {t("pageListeContrat.titre")}
          </TypographyComponent>
          {!loadingIndividual && !loadingContrat && (
            <div className="liste">
              {contrats
                .filter(
                  (v) => v.statut !== "ABORTED" || v.statut !== "CANCELLED "
                )
                .map((item, i) => {
                  return (
                    <ContratItemList
                      contrat={item}
                      key={i}
                      onClick={(e) => {
                        handleClick({ ouid: item.ouid, index: i });
                      }}
                    />
                  );
                })}
            </div>
          )}
          {(loadingContrat || loadingIndividual) && (
            <div className="liste" style={{ display: "flex" }}>
              <Loader />
            </div>
          )}
          <div className="bgLogo">
            <IconComponent name="SmallBg" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListContrats;
