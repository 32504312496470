import React from "react";
import Container from "components/atoms/Container/Container";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import "./blocContentSmall.scss";
const BlocContentSmall = ({ title, children }) => {
  return (
    <Container className="container_content blocContentSmall">
      <header>
        <TypographyComponent className="blocContentSmall-title" variant="p">
          {title}
        </TypographyComponent>
      </header>
      <div>{children}</div>
    </Container>
  );
};

export default BlocContentSmall;
