import IconComponent from "components/atoms/Icons/IconComponent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import React from "react";
import "./servicesPrimeoDispoContent.scss";

const ServicesPrimeoDispoContent = ({
  title,
  price,
  icon,
  description,
  action,
}) => {
  return (
    <div className="dispo_content">
      {!!icon === true && (
        <div className="zone_picto">
          <IconComponent name={icon} color="white" />
        </div>
      )}
      <div className="zone_content">
        <TypographyComponent variant="h2">{title}</TypographyComponent>
        <div>
          <p>
            <b>{price}</b> TTC/mois
          </p>
          <div className="txt_Action">
            <TypographyComponent variant="body2">
              {description}
            </TypographyComponent>
            {action}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesPrimeoDispoContent;
