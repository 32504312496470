import React from "react";
import Link from "../../atoms/Link/Link";

//

const ListLink = ({ list, className, onClick }) => {
  return (
    <nav className={className}>
      <ul>
        {list?.map((item) => (
          <li key={item?.label}>
            <Link
              href={item?.href}
              icon={item?.icon}
              onClick={() => onClick(item?.onClick)}
              className={item?.className}
              alt={item?.label}
              title={item?.label}
              target={item?.target}
            >
              {item?.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default ListLink;
