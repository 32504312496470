/* eslint-disable react-hooks/exhaustive-deps */
import IconComponent from "components/atoms/Icons/IconComponent";
import AccordionCustom from "components/molecules/AccordionCustom/AccordionCustom";
import PaginationManager from "components/organisms/Pagination/PaginationManager";
import getFileContent from "core/services/API/Files/getFileContent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { statutPaymentIcon } from "utils";
import "./tableResponsive.scss";

// Display juste types, montant, date, statut, telechargement

const TableResponsive = ({ datas, pagination = false, numberRowLimit }) => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [nbPages, setNbPages] = useState(Math.ceil(datas.length / rowsPerPage));

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datas?.length) : 0;

  useEffect(() => {
    if (pagination) {
      setRowsPerPage(10);
    } else {
      setRowsPerPage(datas?.length);
    }
  }, [datas]);

  const handleOnChange = (pageSeleted, rowPerPageSelected) => {
    setRowsPerPage(rowPerPageSelected);
    setPage(pageSeleted);
    setNbPages(Math.ceil(datas.length / rowPerPageSelected));
  };

  const handleClick = (id, name) => {
    getFileContent(id, name);
  };

  const options = numberRowLimit
    ? datas?.slice(0, numberRowLimit)
    : datas

  return (
    <div className="table_responsive">
      {options
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          return (
            <AccordionCustom
              key={index}
              panelName={item.reference}
              title={item.types}
              titleType="body"
              subtitle={
                <>
                  <b className="montant">{item.montant}€</b>
                  <p>{item.date}</p>
                </>
              }
              icon={<IconComponent name="ArrowTop" color="text" />}
            >
              <div className="accordion--content-main">
                <span>Statut : </span>

                {item.typeData === "factures" ? (
                  item.statut === true ? (
                    <div className="wrap_statut">
                      <div className="picto">
                        <IconComponent name="Active" color="primary" />
                      </div>
                      Payée
                    </div>
                  ) : (
                    <div className="wrap_statut">
                      <div className="picto">
                        <IconComponent name="Wait" />
                      </div>
                      En attente
                    </div>
                  )
                ) : item.typeData === "paiements" ? (
                  <div className="wrap_statut">
                    <div className="picto">
                      <IconComponent
                        name={`${statutPaymentIcon(item.statut)}`}
                        color={`${statutPaymentIcon(item.statut) === "Error" ||
                          statutPaymentIcon(item.statut) === "Wait"
                          ? ""
                          : "primary"
                          }`}
                      />
                    </div>
                    {item.statut}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {item.telecharger && (
                <div className="accordion--content-cta">
                  <span
                    className="cta"
                    onClick={() => handleClick(item.telecharger, item.name)}
                  >
                    <IconComponent name="Telechargement" />
                    <p>{t("tableauCTA")}</p>
                  </span>
                </div>
              )}
            </AccordionCustom>
          );
        })}
      {pagination && (
        <div className="pagination_wrapper">
          <PaginationManager
            rows={datas}
            pagination={pagination}
            numbersPage={nbPages}
            currentPage={page}
            size={rowsPerPage}
            onChange={handleOnChange}
          />
        </div>
      )}
    </div>
  );
};

export default TableResponsive;
