import { axiosService } from "core/services/httpRequest/AxiosConfig";

const url = `${window.location.origin}${document.ENVIRONNEMENTS.API_LOGIN_PATH}`;

export function askResetPassword(email) {
  return axiosService.post(`${url}/login/reset/password`, { email: email });
}

export function resetPassword(passwordToken, password) {
  return axiosService.put(`${url}/login/reset/password`, {
    passwordToken: passwordToken,
    password: password,
  });
}
