import axiosService from "core/services/httpRequest/AxiosConfig";
import moment from "moment";

export const REEL = "REEL"
export const ESTIMATED = "ESTIMATED"

export function getUsage(
  subscriptionOuid,
  begin = 0//new Date().getTime() - (31536000000),
  //end = new Date().getTime()
) {
  let dates = JSON.stringify({ begin });
  return axiosService.get(
    `/usage/subscriptionOuid/${subscriptionOuid}?filters=${encodeURI(dates)}`
  );
}

export function getUsageV1(
  subscriptionOuid
) {
  return axiosService.get(
    `/usageRatingRequest/subscriptionOuid/${subscriptionOuid}`
  );
}

export function getBilledKwhUsage(
  subscriptionOuid
) {
  return axiosService.get(
    `/usageRatingRequest/subscriptionOuid/${subscriptionOuid}`
  ).then(allBilledUsage => {
    allBilledUsage.data = allBilledUsage.data.reverse();
    return allBilledUsage.data.map((value, currentIndex, allBilledUsage) => {

      let totalWithTaxes = calculateBillingAmountWithTaxes(value)
      let usageAmountInEuros = calculatePriceOfBilledUsage(value)
      return {
        subscriptionAmountEuro : totalWithTaxes,
        usageHPAmountEuro : Number(usageAmountInEuros.HPUsageInEuro) || null ,
        usageHCAmountEuro : Number(usageAmountInEuros.HCUsageInEuro) || null,
        usageBASEAmountEuro : Number(usageAmountInEuros.BASEUsageInEuro) || null,
        date : getIntervalLabelBetweenBilledUsage(value, allBilledUsage[currentIndex - 1]),
        timestampEnd: value.endRatingPeriod
      }
    })
  });
}

export function getRawKwhUsage(
  subscriptionOuid
) {
  let filter = JSON.stringify({ begin : 0 })
  return axiosService.get(
    `/usage/subscriptionOuid/${subscriptionOuid}?filters=${encodeURI(filter)}`
  ).then(allRawUsage => {
    allRawUsage = allRawUsage.data
    .filter(value => value.status !== "CANCELLED")
    .filter(value => value.characteristics["ORIGIN"] !== "FOURNISSEUR" )
    .filter(value => ["CYCL","CFNE","CAL-F_MOD","CHG_PS"].includes(value.characteristics["simpleCode"]) )
    .filter(value => kwhUsageHasAmountInIt(value))
    return allRawUsage.map((value, currentIndex, allRawUsage) => {
      return {
        code: value.characteristics["simpleCode"],
        typeUsage : value.characteristics['Nature_Mesure'] === "REEL" ?  REEL : value.characteristics['Nature_Mesure'] === "ESTIME" ? ESTIMATED : null,
        usageHPAmountKwh : Number(value.characteristics['HP_conso_fournisseur_EA']) || null,
        usageHCAmountKwh : Number(value.characteristics['HC_conso_fournisseur_EA']) || null,
        usageBASEAmountKwh : Number(value.characteristics['BASE_conso_fournisseur_EA']) || null,
        date : getIntervalLabelBetweenRawUsage(value, allRawUsage[currentIndex - 1]),
      }
    }).filter(value => ["CYCL","CAL-F_MOD","CHG_PS"].includes(value.code))
  });

}

function kwhUsageHasAmountInIt(kwhUsage){
  let keys = Object.keys(kwhUsage.characteristics)
  return keys.some(key => key.includes("conso_fournisseur_EA"))
}

function calculateBillingAmountWithTaxes(item){

  const totalTVA20 = Number(item.characteristics['VAT2000.totalAmount']);
  const totalTVA5 = Number(item.characteristics['VAT550.totalAmount']);
  const totalTaxe = Number(item.characteristics['taxes.totalTaxExcludedAmount']);
  const totalAbo = Number(item.characteristics['partFixe.totalTaxExcludedAmount']);

  let totalWithTaxes = (totalTVA20 + totalTVA5 + totalTaxe + totalAbo) / 100 || 0;

  return totalWithTaxes

}

function calculatePriceOfBilledUsage(item){
  const findPricesHC = item?.ratedUsageAggregate?.filter(v => v.characteristics.indexName && v.characteristics.indexName.startsWith("HC"));
  const findPricesHP = item?.ratedUsageAggregate?.filter(v => v.characteristics.indexName && v.characteristics.indexName.startsWith("HP"));
  const findPricesBASE = item?.ratedUsageAggregate?.filter(v => v.characteristics.indexName && v.characteristics.indexName.startsWith("BASE"));

  const totalPricesHCInCents = findPricesHC.reduce((prev, curr, index, array) => prev + curr.taxExcludedRatingAmount, 0);
  const totalPricesHPInCents = findPricesHP.reduce((prev, curr, index, array) => prev + curr.taxExcludedRatingAmount, 0);
  const totalPricesBASEInCents = findPricesBASE.reduce((prev, curr, index, array) => prev + curr.taxExcludedRatingAmount, 0);

  let totalPricesHCInEuro = centsToEuro(totalPricesHCInCents);
  let totalPricesHPInEuro = centsToEuro(totalPricesHPInCents);
  let totalPricesBASEInEuro = centsToEuro(totalPricesBASEInCents);
  
  return {HPUsageInEuro : totalPricesHPInEuro, HCUsageInEuro : totalPricesHCInEuro, BASEUsageInEuro : totalPricesBASEInEuro}
}

function centsToEuro(cents){

  return cents / 100

}

function getIntervalLabelBetweenBilledUsage(billedUsage1, billedUsage2){
  let label = moment(Number(billedUsage1.characteristics["billDate"])).format("DD/MM")
  if(billedUsage2 !== undefined){
  label = moment(Number(billedUsage2?.characteristics["billDate"])).format("DD/MM") + " - " + label
  }
  return label
}

function getIntervalLabelBetweenRawUsage(rawUsage1, rawUsage2){
  let label = moment(Number(rawUsage1.characteristics["Date_Releve"])).format("DD/MM")
  if(rawUsage2 !== undefined){
  label = moment(Number(rawUsage2?.characteristics["Date_Releve"])).format("DD/MM") + " - " + label
  }
  return label
}