import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "core/services/httpRequest/AxiosConfig";

const initialState = {
  paymentMeans: [],
  loading: false,
};

export const getPaymentMeans = createAsyncThunk(
  //action type string
  "/GET_PAYMENT_MEANS",
  // callback function
  async (_, thunkAPI) => {
    const res = await axiosService.get("/customer/connected");

    const bankAccountPaymentMean = {
      bankName: res.data.paymentMeans[0].name,
      iban: res.data.paymentMeans[0].characteristics.iban,
      bic: "string",
      holder: res.data.paymentMeans[0].characteristics.holder,
      country: res.data.paymentMeans[0].characteristics.country,
      city: res.data.paymentMeans[0].characteristics.city,
      postCode: res.data.paymentMeans[0].characteristics.postCode,
      street: res.data.paymentMeans[0].characteristics.street,
      mandate: res.data.paymentMeans[0].characteristics.mandateId,
    };

    return bankAccountPaymentMean;
  },
  //   Add condition is already in the store
  {
    condition: (_, thunkAPI) => {
      const data = thunkAPI.getState();
      const fetchPaymentMeans = data.paymentMeansData.paymentMeans;

      if (Object.keys(fetchPaymentMeans).length > 0) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const paymentMeansSlice = createSlice({
  name: "paymentMeans",
  initialState,
  reducers: {
    // addBillingCharge: (state, action) => {
    //   state.push(action.payliad);
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMeans.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentMeans.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.paymentMeans = payload;
      })
      .addCase(getPaymentMeans.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default paymentMeansSlice.reducer;
