import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifs: [],
  },
  reducers: {
    addNotification: (state, action) => {
      state.notifs.push({
        message: action.payload.message,
        type: action.payload.type,
        title: action.payload.title,
      });
    },

    removeNotification: (state) => {
      state.notifs = [];
    },
  },
});

export const { addNotification, removeNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
