import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "components/atoms/Button/Button";
import PasswordReset from "components/organisms/PasswordReset/PasswordReset";


const Step4 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  function goConnect() {
    //handle logout
    localStorage.removeItem("accessToken");
    // Delete persist root
    localStorage.removeItem("persist:root");
    history.push("/");
  }

  return (
    <PasswordReset>
      <>
        <h1>{t("password.step4")}</h1>
        <p className="blue" style={{ margin: "0 0 15px" }}>
          {t("password.step4sub")}
        </p>
        <div className="container_btn">
          <Button
            className="green medium"
            style={{ margin: "0 auto" }}
            onClick={() => goConnect()}
          >
            {t("password.connect")}
          </Button>
        </div>
      </>
    </PasswordReset>
  );
};

export default Step4;
