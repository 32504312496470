/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HeaderPage from "../HeaderPage/HeaderPage";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";

import "./styles/layoutPage.scss";
import Button from "components/atoms/Button/Button";
import IconComponent from "components/atoms/Icons/IconComponent";
import { useCookies } from "react-cookie";
import CookiesManager from "../Cookies/CookiesManager";
import MenuRight from "../Menu/MenuRight";
import { useDispatch, useSelector } from "react-redux";
import { getIndividual } from "core/services/API/Individual/AgentIndividualSlice";
import {
  getContractsInformations,
  getPDLList,
} from "core/services/API/Contract/ContractSlice";
import { getBillingsAccount } from "core/services/API/BillingsCharges/BillingAccountSlice";
import { capitalizeFirstLetter } from "utils";
import moment from "moment";
import _ from "lodash";
import { getFiles } from "core/services/API/Files/FilesSlices";
import { getLoginInformation } from "core/services/httpRequest/authentification/authentificationSlice";
import { getPaymentRequest } from "core/services/API/PaymentRequest/paymentRequestSlice";
import { getSsoLink } from "core/services/API/SSOZendesk/getLinkSSO";

const LayoutPage = ({ children }) => {
  const { t } = useTranslation();
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [menuIsOpen, setmenuIsOpen] = useState(true);
  // const [menuContratIsOpen, setMenuContratIsOpen] = useState(false);
  // const [styleMenu, setStyleMenu] = useState({});
  const [style, setStyle] = useState({});
  const [contrats, setContrats] = useState([]);
  const [statMenu, setStatMenu] = useState(
    localStorage.getItem("stateMenu") === "true" ? true : false
  );

  const dispatch = useDispatch();

  const infos = useSelector((state) => state.individualState.infos);
  const contracts = useSelector((state) => state.userContractsState.contracts);
  const selected = useSelector((state) =>
    state.userContractsState.contracts.find((contrat) => contrat.selected)
  );
  const priceCharges = useSelector(
    (state) => state.billingsAccount.billingsAccountInfo
  );

  // Cookies
  const [cookies] = useCookies(["cookies"]);
  const [showCookies, setShowCookies] = useState(!cookies.primeoID_session ? true : false);

  useEffect(() => {
    const stateM = localStorage.getItem("stateMenu") === "true" ? true : false;
    setStatMenu(stateM);
  }, []);

  useEffect(() => {
    const stateM = localStorage.getItem("stateMenu") === "true" ? true : false;
    setStatMenu(stateM);
  }, [collectOpen]);

  // Content header

  const menuPrimary = [
    { href: "/tableau-de-bord", label: "Mon tableau de bord", icon: "Home" },
    {
      href: "/factures-et-paiements",
      label: "Mes factures et paiements",
      icon: "Facture",
    },
    { href: "/contrat", label: "Mon contrat", icon: "Contrat" },
    { href: "/ma-consommation", label: "Ma consommation", icon: "MaConso" },
    { href: "/documents", label: "Mes documents", icon: "Document" },
    {
      href: "/mes-infos-personnelles",
      label: "Mes informations",
      icon: "Id",
    },
  ];

  const footerMenu1 = [
    { href: `${t("footer.href1")}`, label: `${t("footer.label1")}`, target: "_blank" },
    { href: `${t("footer.href2")}`, label: `${t("footer.label2")}`, target: "_blank" },
    { label: `${t("footer.label3")}`, onClick: "SSO" },
  ];

  const footerMenu2 = [
    { href: `${t("footer.href4")}`, label: `${t("footer.label4")}` },
    { href: `${t("footer.href5")}`, label: `${t("footer.label5")}` },
    {
      onClick: "cookie",
      label: `${t("footer.label6")}`,
    },
    { href: `${t("footer.href7")}`, label: `${t("footer.label7")}`, target: "_blank" },
  ];

  const detectSize = () => {
    setScreenSize(window.innerWidth);
  };

  function collectOpen(params) {
    setmenuIsOpen(params);
    return params;
  }

  // const onChangeContratMenu = (params) => {
  //   setMenuContratIsOpen(params);
  // };

  useEffect(() => {
    dispatch(getIndividual(true));
    dispatch(getContractsInformations());
    dispatch(getBillingsAccount());
    dispatch(getLoginInformation());
    dispatch(getPaymentRequest());
  }, []);

  useEffect(() => {
    if (priceCharges?.billingsAccountOuid)
      dispatch(getFiles(priceCharges?.billingsAccountOuid));
  }, [priceCharges]);

  useEffect(() => {
    if (contracts.length > 0 && !!infos) {
      dispatch(getPDLList());
    }
    if (
      _.every(contracts, function (c) {
        return c?.PDL;
      })
    ) {
      let output = contracts.map((c, index) => {
        return {
          ouid: c?.ouid,
          id: c?.souscription.productSerialNumber,
          statut: true,
          givenName: c?.PDL.characteristics["titulaireContrat.prenom"],
          familyName: c?.PDL.characteristics["titulaireContrat.nom"],
          name: `${infos?.givenName} ${infos?.familyName}`,
          pdl: c?.PDL.characteristics.PRIMARY_RESOURCE,
          address1: !!!c?.PDL.characteristics["adresseInstallation.rue"] ? t("common.waitData") : `${c?.PDL.characteristics["adresseInstallation.numRue"] ?? ""
            } ${c?.PDL.characteristics[
              "adresseInstallation.rue"
            ]?.toLowerCase()} `,
          address2: !!!c?.PDL.characteristics["adresseInstallation.codePostal"] ? "" : `${c?.PDL.characteristics["adresseInstallation.codePostal"]
            } ${capitalizeFirstLetter(
              c?.PDL.characteristics["adresseInstallation.libelleCommune"]
            )}`,
          dateStart: moment(c?.PDL.characteristics.activationDate * 1)?.format(
            "L"
          ),
        };
      });
      setContrats(output);
    }
  }, [contracts, infos, dispatch]);

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    if (screenSize <= 600) {
      setStyle({ marginLeft: "0" });
    }
    if (screenSize <= 1024 && screenSize > 600) {
      setStyle({ marginLeft: "0" });
    }
    if (screenSize > 1024) {
      if (menuIsOpen) {
        setStyle({ marginLeft: "264px" });
      } else {
        setStyle({ marginLeft: "90px" });
      }
    }

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenSize, menuIsOpen]);


  // specifique action footer
  const handleClickFooter = (v) => {
    if (v === "cookie") {
      setShowCookies(true)
    }
    if (v === "SSO") {
      getSsoLink(t("footer.href3"))
    }

  }

  return (
    <>
      <HeaderPage
        screenSize={screenSize}
        menuPrimary={menuPrimary}
        rightContent={<MenuRight />}
        collectOpen={collectOpen}
        listContrats={contrats}
        selected={selected}
        // onChangeContratMenu={onChangeContratMenu}
        isOpen={statMenu}
      />

      <main className="main_container main_container--page" style={style}>
        <div
          className="main_container--wrapper"
          style={{
            height: "100%",
          }}
        >
          {children}
        </div>
      </main>
      {showCookies && (
        <CookiesManager close={(param) => setShowCookies(param)} />
      )}

      <Button
        className="green btn_cookies"
        iconBefore={true}
        icon={
          showCookies ? (
            <IconComponent name="Cross" />
          ) : (
            <IconComponent name="Cookie" color="white" />
          )
        }
        onClick={() => setShowCookies(!showCookies)}
      />

      <Footer
        footerMenu1={footerMenu1}
        footerMenu2={footerMenu2}
        onClick={handleClickFooter}
      />
    </>
  );
};

export default LayoutPage;
