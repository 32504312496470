import React, { useEffect, useState } from "react";
import BlocContent from "components/molecules/BlocContent/BlocContent";
import { useTranslation } from "react-i18next";
import TableComponent from "../Tables/TableComponent/TableComponent";
import TableResponsive from "../Tables/TableResponsive/TableResponsive";
import Button from "components/atoms/Button/Button";
import CustomDatePicker from "components/atoms/Inputs/DatePicker";
import ComboBox from "components/atoms/Inputs/ComboBox/ComboBox";
import { filterRowsDocuments } from "utils";

const BlocMesFactures = ({ headCells, table, screen, loading = false }) => {
  const { t } = useTranslation();

  const [date, setDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selected, setSelected] = useState(undefined);

  const [dataDocumentsSorting, setDataDocumentsSorting] = useState(table);

  const resetFilters = () => {
    setSelected(undefined);
    setDate(undefined);
    setEndDate(undefined);
    setDataDocumentsSorting(table);
  };

  useEffect(() => {
    setDataDocumentsSorting(table);
  }, [table]);

  const listType = [
    "Factures",
    "Avoirs",
    "Bilans de consommation",
    "Échéancier",
  ];

  const handleFilter = () => {
    const checking = !!date || !!endDate || !!selected;

    if (checking === true) {
      setDataDocumentsSorting(
        filterRowsDocuments(table, date, endDate, selected)
      );
    } else {
      setDataDocumentsSorting(table);
    }
  };

  return (
    <BlocContent
      title={t("pages.mesDocuments.titleTable")}
      variant="h2"
      iconName="Document"
      className="bloc_table"
      iconColor={"white"}
      iconStyle={{ height: "20px", width: "20px" }}
    >
      <div className="filter">
        <div className="titleFilter">{t("tableauFacture.titleFilter")}</div>
        <div className="wrapper_filter">
          <ComboBox
            label="Type"
            value={selected}
            list={listType}
            onChange={(e) => {
              setSelected(e);
            }}
          />
          <CustomDatePicker
            value={date}
            label="Date de début"
            onChange={(e) => setDate(e)}
            limitation={{
              max: endDate ? endDate : undefined,
            }}
          />
          <CustomDatePicker
            value={endDate}
            label="Date de fin"
            onChange={(e) => setEndDate(e)}
            limitation={{
              min: date ? date : undefined,
            }}
          />
          <Button className="blue" onClick={handleFilter}>
            Filtrer
          </Button>
          <p
            className="reset"
            onClick={() => resetFilters()}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              margin: "0 0 0 12px",
            }}
          >
            Effacer
          </p>
        </div>
      </div>
      {screen > 1024 ? (
        <TableComponent
          header={headCells}
          datas={dataDocumentsSorting}
          pagination={true}
          loading={loading}
        />
      ) : (
        <TableResponsive datas={table} pagination={true} />
      )}
    </BlocContent>
  );
};

export default BlocMesFactures;
