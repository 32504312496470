import CustomizedLabel from "components/atoms/CustomizedBarLabel";
import Loader from "components/atoms/Loader/Loader";
import { ESTIMATED } from "core/services/httpRequest/usageAggregate";
import { PureComponent, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from "recharts";
import { replacePointByCommaInDecimals } from "utils";

const matrice = {
  loading: {
    unit: "percent",
  },
  kwh: {
    unit: "kWh",
    usageHPAmountKwh: "usageHPAmountKwh",
    usageHCAmountKwh: "usageHCAmountKwh",
    usageBASEAmountKwh: "usageBASEAmountKwh",
  },
  price: {
    unit: "€",
    usageHPAmountEuro: "usageHPAmountEuro",
    usageHCAmountEuro: "usageHCAmountEuro",
    usageBASEAmountEuro: "usageBASEAmountEuro",
    subscriptionAmountEuro: "subscriptionAmountEuro",
  },
};

const Chart = ({ billedUsageData, rawUsageData, unit, timeFrame, loading }) => {

  const [names, setNames] = useState(matrice.kwh);

  const [barSize, setBarSize] = useState(26);
  useEffect(() => {
    if (loading === true) {
      setNames(matrice.loading)
    }
    if (loading === false) {
      setNames(matrice.price)
    }

  }, [loading])

  useEffect(() => {
    unit === "kWh" ? setNames(matrice.kwh) : setNames(matrice.price);
  }, [unit]);

  useEffect(() => {
    timeFrame === "ANNÉE" ? setBarSize(26) : setBarSize(10);
  }, [timeFrame]);


  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;
      const value = payload.value?.split(" - ");

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="middle"
            fill="#808080"
            transform="rotate(0)"
            style={{
              fontSize: "12px",
            }}
          >
            {value[0]}
          </text>
          <text
            x={0}
            y={0}
            dy={32}
            textAnchor="middle"
            fill="#808080"
            transform="rotate(0)"
            style={{
              fontSize: "12px",
            }}
          >
            {value[1]}
          </text>
        </g>
      );
    }
  }

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        {payload.reverse().map((entry, index) => (
          <div
            key={`item-${index * 42}`}
            style={{
              display: "flex",
              color: entry.color,
              paddingBottom: "4px",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                color: entry.color,
                padding: 0,
                margin: 0,
                marginRight: 4,
              }}
            >
              {entry.value === "Heures pleines" ? "Montant consommation HP" : ""}
              {entry.value === "Heures creuses" ? "Montant consommation HC" : ""}
              {entry.value === "Base" ? "Montant consommation base" : ""}
              {entry.value === "Abonnement, taxes, TVA" ? "Abonnement, taxes, TVA" : ""}
            </p>
            <div
              style={{
                width: "12px",
                height: "12px",
                background: entry.color,
                marginBottom: "-4px",
              }}
            />
          </div>
        ))}
        {payload.map((entry, index) => entry?.dataKey !== "other"
        && (
          <div
            key={`item-${index * 2}`}
            style={{
              display: "flex",
              color: entry.color,
              paddingBottom: "4px",
            }}
          >
           
          </div>

        ))}
      </div>
    );
  };

  const CustomTooltip = (props) => {
    const { active, payload, coordinate } = props;
    if (active && payload && payload?.length) {
      return (
        <div
          key={coordinate?.x * coordinate?.y}
          style={{
            background: "white",
            padding: "6px",
            borderRadius: "4px",
            boxShadow: "1px 1px 30px rgba(0, 0, 0, 0.1)",
          }}
        >
          <p style={{ padding: "0px, 2px", margin: 6 }}>
            {payload[0]?.payload?.date_full}
          </p>
          {payload.reverse()?.filter((x) => x.value != 0).map((x, i) => (
            <div key={i}>
              <p
                style={{ padding: "0px, 2px", margin: 0 }}
              >
                {`${x?.name} : ${replacePointByCommaInDecimals(x?.value)}${x?.unit}`}</p>
            </div>
          ))}
        </div >
      );
    }

    return null;
  };

  function displayBilledUsageInEuroChartBar() {
    return (<>
      <Bar
        dataKey={names.subscriptionAmountEuro}
        stackId="a"
        fill="#2896AF"
        name={`Abonnement, taxes, TVA`}
        barSize={barSize}
        unit={names.unit}
        isAnimationActive={false}
      >
        {billedUsageData.map((entry, index) => {
          return (<Cell key={`cell-${index}`} width={barSize} />);
        }
        )}
      </Bar>
      <Bar
        dataKey={names.usageHCAmountEuro}
        stackId="a"
        fill="#109F7B"
        name={`Heures creuses`}
        barSize={barSize}
        unit={names.unit}
        isAnimationActive={false}
      >

        {billedUsageData.map((entry, index) => {
          return (
            <><Cell key={`cell-${index}`} width={barSize} /></>
          );
        })}
      </Bar>
      <Bar
        dataKey={names.usageHPAmountEuro}
        stackId="a"
        fill="#0ABBF0"
        name={`Heures pleines`}
        barSize={barSize}
        unit={names.unit}
        isAnimationActive={false}>
        {billedUsageData.map((entry, index) => {
          <Cell key={`cell-${index}`} width={barSize} />
        })}
      </Bar>
      <Bar
        dataKey={names.usageBASEAmountEuro}
        stackId="a"
        fill="#09A8D8"
        name={`Base`}
        barSize={barSize}
        unit={names.unit}
        isAnimationActive={false}
      >
        <LabelList
          dataKey={names[0]}
          content={<CustomizedLabel unit={names.unit} />}
        />
        {billedUsageData.map((entry, index) => {
          return (
            <><Cell key={`cell-${index}`} width={barSize} /></>
          );
        })}
      </Bar>
    </>)
  }

  function displayRawUsageInKwhChartBar() {
    return (<>
      <Bar
        dataKey={names.usageHCAmountKwh}
        stackId="a"
        fill="#109F7B"
        name={`Heures creuses`}
        barSize={barSize}
        unit={names.unit}
        isAnimationActive={false}
      >
        {rawUsageData.map((entry, index) => {
          return (
            <><Cell key={`cell-${index}`}
              fill={entry.typeUsage === ESTIMATED ? "none" : "#109F7B"}
              strokeWidth={entry.typeUsage === ESTIMATED ? 2 : ""}
              strokeDasharray={entry.typeUsage === ESTIMATED ? 0 : ""}
              stroke={entry.typeUsage === ESTIMATED ? "#109F7B" : ""}
              width={barSize} /></>
          );
        })}
      </Bar>
      <Bar
        dataKey={names.usageHPAmountKwh}
        stackId="a"
        fill="#0ABBF0"
        name={`Heures pleines`}
        barSize={barSize}
        unit={names.unit}
        isAnimationActive={false}>
        {rawUsageData.map((entry, index) => {
          return (<Cell key={`cell-${index}`}
            width={barSize}
            fill={entry.typeUsage === ESTIMATED ? "none" : "#0ABBF0"}
            strokeWidth={entry.typeUsage === ESTIMATED ? 2 : ""}
            strokeDasharray={entry.typeUsage === ESTIMATED ? 0 : ""}
            stroke={entry.typeUsage === ESTIMATED ? "#0ABBF0" : ""}
          />)
        })}
      </Bar>
      <Bar
        dataKey={names.usageBASEAmountKwh}
        stackId="a"
        fill="#09A8D8"
        name={`Base`}
        barSize={barSize}
        unit={names.unit}
        isAnimationActive={false}
      >
        <LabelList
          dataKey={names[0]}
          content={<CustomizedLabel unit={names.unit} />}
        />
        {rawUsageData.map((entry, index) => {
          return (
            <><Cell key={`cell-${index}`}
              width={barSize}
              fill={entry.typeUsage === ESTIMATED ? "none" : "#09A8D8"}
              strokeWidth={entry.typeUsage === ESTIMATED ? 2 : ""}
              strokeDasharray={entry.typeUsage === ESTIMATED ? 0 : ""}
              stroke={entry.typeUsage === ESTIMATED ? "#0ABBF0" : ""}
            /></>
          );
        })}
      </Bar>
    </>)
  }

  function isBilledUsage() {
    return unit === "€(TTC)";
  }
  function isRawUsage() {
    return unit === "kWh";
  }

  return (

    <ResponsiveContainer height={450} width="100%">
      {billedUsageData?.length > 0 && loading === false && isBilledUsage() ? (
        <BarChart
          width={500}
          height={300}
          data={billedUsageData}
          margin={{
            top: 20,
          }}
        >
          <CartesianGrid vertical={false} stroke="#E6E6E6" />

          <Legend content={renderLegend} />

          <XAxis
            dataKey="date"
            height={50}
            padding={{ left: 6, right: 6 }}
            interval="preserveStartEnd"
            tick={<CustomizedAxisTick />}
          />

          <Tooltip content={<CustomTooltip />} />

          {displayBilledUsageInEuroChartBar()}

        </BarChart>
      ) :
        rawUsageData?.length > 0 && loading === false && isRawUsage() ? (
          <BarChart
            width={500}
            height={300}
            data={rawUsageData}
            margin={{
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} stroke="#E6E6E6" />

            <Legend content={renderLegend} />

            <XAxis
              dataKey="date"
              height={50}
              padding={{ left: 6, right: 6 }}
              interval="preserveStartEnd"
              tick={<CustomizedAxisTick />}
            />

            <Tooltip content={<CustomTooltip />} />

            {displayRawUsageInKwhChartBar()}

          </BarChart>
        ) :
          (
            <div
              className={loading ? "nodata" : "nodata active"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {loading ? (
                <Loader />
              ) : (
                <h3 className="blue">Aucune donnée</h3>
              )}

            </div>
          )}
    </ResponsiveContainer>

  );
};


export default Chart;
