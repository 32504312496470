import React from "react";
import Container from "components/atoms/Container/Container";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import Button from "components/atoms/Button/Button";
import { useTranslation } from "react-i18next";

const BlocJeDois = ({
  price = "0",
  onClickPay = null,
  fullHeight = true,
}) => {
  const { t } = useTranslation();

  return (
    <Container style={fullHeight ? { height: "100%" } : {}}>
      <TypographyComponent variant="p" style={{ fontWeight: "bold" }}>
        {t("pages.facturesEtPaiements.title1")}
      </TypographyComponent>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "22px",
        }}
      >
        <TypographyComponent
          variant="h2"
          style={{ fontWeight: "bold", marginBottom: "10px" }}
        >
          {(Math.abs(price) / 100)?.toFixed(2)?.toString()?.replace(".", ",")}€
          TTC
        </TypographyComponent>
        {/* <Button className="green" onClick={onClickPay} >
                {t("pages.facturesEtPaiements.pay")}
              </Button> */}
      </div>
    </Container>
  );
};

export default BlocJeDois;
