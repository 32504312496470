import Button from "components/atoms/Button/Button";
import React from "react";

// style
import "./barreButton.scss";

/**
 * @name BarreButtonBool
 * @param {name} name Name input
 * @param {string} labelA label first button
 * @param {string} labelB label last button
 * @param {function} onChange onChange
 * @param {boolean} error true if error
 * @param {string} otherlabelA if you want change label display in the button
 * @param {string} otherlabelB  if you want change label display in the button
 * @param {string} ClassName class
 *  @param {node} value value
 */

const BarreButton = ({
  name,
  value,
  labelA,
  labelB,
  onChange,
  error,
  otherLabelA,
  otherLabelB,
  className,
  loading,
  ...props
}) => {
  return (
    <>
      <div className={`barreButton ${className || ""}`}>
        <label>
          <input
            type="radio"
            value={value}
            name={name}
            // checked={value === true}
          />
          <Button
            className={`green outline ${value === true ? "checked" : ""} ${
              loading ? "grey" : ""
            }`}
            onClick={() => onChange(true)}
          >
            {otherLabelA ? otherLabelA : labelA}
          </Button>
        </label>

        <label>
          <input
            type="radio"
            name={name}
            value={value}
            // checked={value === false}
          />
          <Button
            className={`green outline ${value === false ? "checked" : ""} ${
              loading ? "grey" : ""
            }`}
            onClick={() => onChange(false)}
          >
            {otherLabelB ? otherLabelB : labelB}
          </Button>
        </label>
      </div>
      {error ? <p className="barreButton_error">{error.message}</p> : undefined}
    </>
  );
};

export default BarreButton;
