import React from "react";
import Content from "components/molecules/Content/Content";
import BlocContent from "components/molecules/BlocContent/BlocContent";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import Link from "components/atoms/Link/Link";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Notification from "components/atoms/Notification/Notification";

const BlocIdentifiantConnexion = ({ email, onClickEmail, onClickPass }) => {
  const { t } = useTranslation();
  return (
    <BlocContent
      title={t("infosperso.blocIdentifiantConnexionTitle")}
      variant="h2"
      iconName="Letter"
      iconColor="white"
      iconStyle={{ height: "20px", width: "20px" }}
    >
      <Notification />
      <Grid
        container
        spacing={2.5}
        style={{ padding: "0 24px", marginTop: "0" }}
      >
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"Letter"}
            iconColor={"secondary"}
            variant="h3"
            title={t("infosperso.emailConnexion")}
          >
            <TypographyComponent style={{ fontSize: "14px" }}>
              {email}
            </TypographyComponent>
            {/* <Link onClick={onClickEmail} className="black">
              {t("infosperso.modifierEmail")}
            </Link> */}
          </Content>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Content
            iconName={"Pass"}
            iconColor={"secondary"}
            variant="h3"
            title={t("infosperso.pass")}
          >
            <Link onClick={onClickPass} className="black">
              {t("infosperso.modifierPass")}
            </Link>
          </Content>
        </Grid>
      </Grid>
    </BlocContent>
  );
};

export default BlocIdentifiantConnexion;
