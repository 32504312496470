import { Grid } from "@mui/material";
import IconComponent from "components/atoms/Icons/IconComponent";
import Link from "components/atoms/Link/Link";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import BlocContent from "components/molecules/BlocContent/BlocContent";
import Content from "components/molecules/Content/Content";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { internationalMobilePhoneToRegular } from "utils";

const BlocCoordonnee = ({
  titulaire,
  cotitulaires,
  adresse,
  numeros,
  onClickAskModif,
  onClickModifPhone,
}) => {
  const { t } = useTranslation();

  return (
    <BlocContent
      title={t("infosperso.blocCoordonneeTitle")}
      variant="h2"
      iconName="Id"
      iconColor="white"
      iconStyle={{ height: "20px", width: "20px" }}
    >
      <Grid
        container
        spacing={2.5}
        style={{ padding: "0 24px", marginTop: "0" }}
      >
        <Grid item xs={12} sm={6}>
          <Content
            iconName={"User"}
            iconColor={"secondary"}
            variant="h3"
            title={t("infosperso.titulaire")}
          >
            <span>
              {t(`infosperso.${titulaire?.gender}.gender`)}{" "}
              {titulaire?.givenName} {titulaire?.familyName?.toUpperCase()}
              <br /> {t(`infosperso.${titulaire?.gender}.born`)}{" "}
              {moment(titulaire?.birthdate).format("DD/MM/yyyy")}
            </span>
          </Content>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Content
            iconName={"Group"}
            iconColor={"secondary"}
            variant="h3"
            title={t("infosperso.cotitulaire")}
          >
            {cotitulaires?.length > 0
              ? cotitulaires?.map((c, index) => {
                return (
                  <div key={index} style={{ marginBottom: "4px" }}>
                    <span>
                      {" "}
                      {c?.gender} {c?.firstname} {c?.lastname?.toUpperCase()}
                      <br /> {t("infosperso.born")}{" "}
                      {moment(c?.birthdate).format("DD/MM/yyyy")}
                    </span>
                  </div>
                );
              })
              : t("infosperso.none")}
          </Content>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Content
            iconName={"Adress"}
            iconColor={"secondary"}
            variant="h3"
            title={t("infosperso.adresseFacturation")}
          >
            <div style={{ marginBottom: "20px" }}>
              <span style={{ textTransform: "uppercase" }}>
                {adresse?.street1} {adresse?.street2}
                <br />
                {adresse?.supplement1 && (
                  <>
                    {adresse?.supplement1}
                    <br />
                  </>
                )}
                {adresse?.supplement2 && (
                  <>
                    {adresse?.supplement2}
                    <br />
                  </>
                )}
                {adresse?.postCode} {adresse?.city}
              </span>
            </div>
          </Content>
        </Grid>

        <Grid
          item
          xs={12}
          className="securityTip"
          style={{
            border: "1px solid rgb(87, 185, 207)",
            borderRadius: "8px",
            padding: "8px 12px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "24px", marginRight: "10px" }}>
            <IconComponent
              name="LightBulb"
              color="secondary"
              style={{ height: "20px", width: "20px" }}
            />
          </div>

          <div>
            <TypographyComponent>
              {t("infosperso.securityTip")}
            </TypographyComponent>
            <Link className={"black"} onClick={onClickAskModif}>
              <>{t("infosperso.securityTipAsk")}</>
            </Link>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Content
            iconName={"Phone"}
            iconColor={"secondary"}
            variant="h3"
            title={numeros?.length > 1 ? t("infosperso.telephones") : t("infosperso.telephone")}
          >
            <ul style={{ margin: "2px 0", paddingLeft: "25px" }}>
              {numeros?.map((n) => {
                return (
                  <li key={n}>
                    <span>
                      {internationalMobilePhoneToRegular("+33", "0", n)}
                    </span>
                  </li>
                );
              })}
            </ul>
            <Link className={"black"} onClick={onClickModifPhone}>
              <>{t("infosperso.modifierTelephone")}</>
            </Link>
          </Content>
        </Grid>
      </Grid>
    </BlocContent>
  );
};

export default BlocCoordonnee;
