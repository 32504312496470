import React from "react";
import LayoutAccueil from "components/organisms/Layout/LayoutAccueil";
import Container from "components/atoms/Container/Container";

import "./styles/passwordreset.scss";

const PasswordReset = ({ children }) => {
  return (
    <LayoutAccueil className="password_reset_container" >
      <Container className="password_reset_content" >{React.cloneElement(children)}</Container>
    </LayoutAccueil>
  );
};

export default PasswordReset;
