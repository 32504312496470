import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import frLocale from "date-fns/locale/fr";
import moment from "moment";
import "moment/locale/fr";
import "./datePicker.scss";
moment.locale("fr");

const CustomDatePicker = ({
  value = null,
  onChange,
  onBlur = () => null,
  name,
  error,
  label,
  limitation,
  required,
}) => {
  const limitationRange = (date) => {
    return new Date(date) < limitation.min || new Date(date) > limitation.max;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <DatePicker
        name={name}
        label={label}
        value={value}
        mask="__/__/____"
        inputFormat="dd/MM/yyyy"
        onChange={(date) => {
          onChange(date);
        }}
        renderInput={(props) => {
          let params = { ...props };
          params.inputProps.placeholder = "jj/mm/aaaa";
          return (
            <div className="inputText">
              <TextField
                {...params}
                value={value}
                onBlur={(e) => {
                  return onBlur(e);
                }}
                autoComplete="off"
                error={!!error}
                helperText={error?.message}
                inputprops={{
                  ...params,
                  ...params.InputProps,
                }}
                fullWidth
                required={required}
              />
            </div>
          );
        }}
        shouldDisableDate={limitationRange}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
