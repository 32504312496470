/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconComponent from "components/atoms/Icons/IconComponent";

import { useHistory } from "react-router-dom";
import Button from "components/atoms/Button/Button";
import MenuContrat from "../MenuContrat/MenuContrat";
// import { useTranslation } from "react-i18next";
import { getSsoLink } from "core/services/API/SSOZendesk/getLinkSSO";

const MenuResp = ({ menuList, listContrats, selected }) => {
  const history = useHistory();
  // const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, link) => {
    setSelectedIndex(index);
    history.push(link);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const currentIndex = menuList?.findIndex(
      (l) => l.href === history?.location?.pathname
    );
    setSelectedIndex(currentIndex);
  }, [menuList]);

  return (
    <div className="menu_Resp_Burger">
      <Button
        className={`green burger ${open ? "open" : "closed"}`}
        onClick={handleClick}
      >
        <IconComponent name="Burger" />
      </Button>

      <Menu
        id="menuBurger"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className="nav_contrat">
          <MenuContrat listContrat={listContrats} contratSelected={selected} />
        </div>
        {menuList?.map((option, index) => (
          <MenuItem
            key={option.label}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index, option.href)}
          >
            {option.icon && (
              <span className="icon">
                <IconComponent name={option.icon} />
              </span>
            )}
            <p>{option.label}</p>
          </MenuItem>
        ))}
        <div className="TCO">
          <Button className="blue" onClick={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/sections/8021931007517-Questions-fréquentes")}>
            <IconComponent name="FAQ" />
            <p>Consulter notre FAQ</p>
          </Button>
          <Button className="green medium outline btn_demenage" onClick={() => getSsoLink("https://assistance.primeo-energie.fr/hc/fr/requests/new?ticket_form_id=8129099812509")}>

            <IconComponent name="Demenage" />
            <p>Je déménage</p>

          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default MenuResp;
