import React from "react";
import "./checkboxButton.scss";
import Checkbox from "@mui/material/Checkbox";
import { FormHelperText } from "@mui/material";

const CheckboxButton = ({
  text,
  className,
  onChange,
  required,
  helperText,
  error,
  value,
  valueIsObject,
  ...props
}) => {
  const handleChange = (event) => {
    if (valueIsObject) {
      onChange({ ...value, isPicked: event.target.checked });
    } else {
      onChange(event.target.checked);
    }
  };

  return (
    <div className={`checkboxButton ${className || ""}`}>
      <label>
        <Checkbox
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          required={required}
          error={error}
          {...props}
        />
        <span>{text}</span>
      </label>

      {error && (
        <span className="helpText">
          <FormHelperText>{helperText}</FormHelperText>
        </span>
      )}
    </div>
  );
};

export default CheckboxButton;
