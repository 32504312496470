import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "core/services/httpRequest/AxiosConfig";

const initialState = {
  paymentRequest: [],
  loading: false,
};

export const getPaymentRequest = createAsyncThunk(
  //action type string
  "/GET_PAYMENT_REQUEST",
  // callback function
  async (_, thunkAPI) => {
    const res = await axiosService.get("/paymentRequest");

    const paymentRequest = [];

    res.data.map((v) => {
      return paymentRequest.push({
        ouid: v.ouid,
        type: v.type,
        amount: v.amount,
        date: v.dateRequest,
        status: v.status,
      });
    });

    return paymentRequest;
  },
  //   Add condition is already in the store
  {
    condition: (_, thunkAPI) => {
      const data = thunkAPI.getState();
      const fetchPaymentR = data.payments.paymentRequest;
      if (Object.keys(fetchPaymentR).length > 0) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const paymentRequestSlice = createSlice({
  name: "paymentRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentRequest.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.paymentRequest = payload;
      })
      .addCase(getPaymentRequest.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default paymentRequestSlice.reducer;
