import React from "react";
import IconComponent from "components/atoms/Icons/IconComponent";
import "./navlink.scss";
import { Link } from "react-router-dom";

const NavLink = ({
  icon,
  iconContent = <IconComponent name={"ArrowDown"} />,
  // iconPlacement = "right",

  className,
  children,
  disabled,
  to,
}) => {
  return (
    <Link to={to} className={`link ${disabled ? "" : "disabled"} ${className}`}>
      {children} {icon && iconContent}
    </Link>
  );
};

export default NavLink;
