import React from "react";

import "./iconComponent.scss";

// All icons sources
import { ReactComponent as ArrowSvg } from "core/assets/picto/arrow.svg";
import { ReactComponent as CheckSvg } from "core/assets/picto/check.svg";
import { ReactComponent as CrossSvg } from "core/assets/picto/cross.svg";
import { ReactComponent as ErrorSvg } from "core/assets/picto/error.svg";
import { ReactComponent as BeforeSvg } from "core/assets/picto/paginationBefore.svg";
import { ReactComponent as NextSvg } from "core/assets/picto/paginationNext.svg";
import { ReactComponent as EyeSvg } from "core/assets/picto/eye.svg";
import { ReactComponent as ArrowMenuSvg } from "core/assets/picto/arrowMenu.svg";
import { ReactComponent as HomeSvg } from "core/assets/picto/home.svg";
import { ReactComponent as VenteSvg } from "core/assets/picto/vente.svg";
import { ReactComponent as UserSvg } from "core/assets/picto/user.svg";
import { ReactComponent as SocieteSvg } from "core/assets/picto/societe.svg";
import { ReactComponent as BurgerSvg } from "core/assets/picto/lineBurger.svg";
import { ReactComponent as PDLSvg } from "core/assets/picto/PDLContrat.svg";
import { ReactComponent as ArrowTopSvg } from "core/assets/picto/arrowTop.svg";
import { ReactComponent as ArrowDownSvg } from "core/assets/picto/arrowDown.svg";
import { ReactComponent as FacebookSvg } from "core/assets/picto/facebook.svg";
import { ReactComponent as TwitterSvg } from "core/assets/picto/twitter.svg";
import { ReactComponent as LinkedinSvg } from "core/assets/picto/linkedin.svg";
import { ReactComponent as PassSvg } from "core/assets/picto/pass.svg";
import { ReactComponent as LetterSvg } from "core/assets/picto/letter.svg";
import { ReactComponent as IdSvg } from "core/assets/picto/idCard.svg";
import { ReactComponent as GroupSvg } from "core/assets/picto/group.svg";
import { ReactComponent as AdressSvg } from "core/assets/picto/adress.svg";
import { ReactComponent as LightBulbSvg } from "core/assets/picto/lightbulb.svg";
import { ReactComponent as PhoneSvg } from "core/assets/picto/phone.svg";
import { ReactComponent as PaperSvg } from "core/assets/picto/paper.svg";
import { ReactComponent as BilletSvg } from "core/assets/picto/billets.svg";
import { ReactComponent as TelechargementSvg } from "core/assets/picto/telechargement.svg";
import { ReactComponent as AdresseFacturationSvg } from "core/assets/picto/adresseFacturation.svg";
import { ReactComponent as SouscriptionSvg } from "core/assets/picto/souscription.svg";
import { ReactComponent as ModeFacturationSvg } from "core/assets/picto/modeFacturation.svg";
import { ReactComponent as CoTitulaireSvg } from "core/assets/picto/coTitulaire.svg";
import { ReactComponent as TitulairteSvg } from "core/assets/picto/titulaire.svg";
import { ReactComponent as NumeroContratSvg } from "core/assets/picto/numeroContrat.svg";
import { ReactComponent as ContratSvg } from "core/assets/picto/contrat.svg";
import { ReactComponent as DateSvg } from "core/assets/picto/date.svg";
import { ReactComponent as InfoSvg } from "core/assets/picto/info.svg";
import { ReactComponent as TypeCompteurSvg } from "core/assets/picto/typeCompteur.svg";
import { ReactComponent as MonInstallationSvg } from "core/assets/picto/monInstallation.svg";
import { ReactComponent as HorlogeSvg } from "core/assets/picto/horloge.svg";
import { ReactComponent as AlertSvg } from "core/assets/picto/alert.svg";
import { ReactComponent as HelpSvg } from "core/assets/picto/help.svg";
import { ReactComponent as TelSvg } from "core/assets/picto/tel.svg";
import { ReactComponent as LinkSvg } from "core/assets/picto/link.svg";
import { ReactComponent as CookieSvg } from "core/assets/picto/cookie.svg";
import { ReactComponent as SmallLogoBg } from "core/assets/petitLogoBg.svg";
import { ReactComponent as ActiveSvg } from "core/assets/picto/active.svg";
import { ReactComponent as DocumentsSvg } from "core/assets/picto/documents.svg";
import { ReactComponent as AmpouleSvg } from "core/assets/picto/ampoule.svg";
import { ReactComponent as MonOffreSvg } from "core/assets/picto/monOffre.svg";
import { ReactComponent as DepannageSvg } from "core/assets/picto/depannage.svg";
import { ReactComponent as RenouvelableSvg } from "core/assets/picto/renouvelable.svg";
import { ReactComponent as ElectroSvg } from "core/assets/picto/electromenager.svg";
import { ReactComponent as WaitSvg } from "core/assets/picto/wait.svg";
import { ReactComponent as FactureSvg } from "core/assets/picto/facture.svg";
import { ReactComponent as Error403Svg } from "core/assets/picto/403.svg";
import { ReactComponent as MaConsoSvg } from "core/assets/picto/maConso.svg";
import { ReactComponent as AttentionSvg } from "core/assets/picto/attention.svg";
import { ReactComponent as DemenageSvg } from "core/assets/picto/demenage.svg";
import { ReactComponent as FAQSvg } from "core/assets/picto/FAQ.svg";
import { ReactComponent as Error404Svg } from "core/assets/picto/404.svg";
import { ReactComponent as DecoSvg } from "core/assets/picto/deco.svg";
import { ReactComponent as InstaSvg } from "core/assets/picto/instagram.svg";
import { ReactComponent as GiftSvg } from "core/assets/picto/gift.svg";
const iconTypes = {
  Active: ActiveSvg,
  Arrow: ArrowSvg,
  ArrowDown: ArrowDownSvg,
  ArrowTop: ArrowTopSvg,
  Check: CheckSvg,
  Cross: CrossSvg,
  Error: ErrorSvg,
  Before: BeforeSvg,
  Date: DateSvg,
  Next: NextSvg,
  Eye: EyeSvg,
  ArrowMenu: ArrowMenuSvg,
  Home: HomeSvg,
  Vente: VenteSvg,
  User: UserSvg,
  Societe: SocieteSvg,
  Burger: BurgerSvg,
  PDL: PDLSvg,
  Facebook: FacebookSvg,
  Twitter: TwitterSvg,
  Linkedin: LinkedinSvg,
  Pass: PassSvg,
  Letter: LetterSvg,
  Id: IdSvg,
  Group: GroupSvg,
  Adress: AdressSvg,
  LightBulb: LightBulbSvg,
  Phone: PhoneSvg,
  Paper: PaperSvg,
  Billet: BilletSvg,
  Telechargement: TelechargementSvg,
  SmallBg: SmallLogoBg,
  Contrat: ContratSvg,
  NumeroContrat: NumeroContratSvg,
  Titulaire: TitulairteSvg,
  CoTitulaire: CoTitulaireSvg,
  ModeFacturation: ModeFacturationSvg,
  Souscription: SouscriptionSvg,
  AdresseFacturation: AdresseFacturationSvg,
  InfoTooltip: InfoSvg,
  TypeCompteur: TypeCompteurSvg,
  MonInstallation: MonInstallationSvg,
  Horloge: HorlogeSvg,
  Alert: AlertSvg,
  Help: HelpSvg,
  Link: LinkSvg,
  Tel: TelSvg,
  Document: DocumentsSvg,
  Ampoule: AmpouleSvg,
  MonOffre: MonOffreSvg,
  Depannage: DepannageSvg,
  ELECTRICITE_OPTION_ASSURANCE_DEPANNAGE: DepannageSvg,
  Renouvelable: RenouvelableSvg,
  ELECTRICITE_OPTION_VERTE: RenouvelableSvg,
  Electro: ElectroSvg,
  ELECTRICITE_OPTION_ENTRETIEN_CHAUDIERE: ElectroSvg,
  Cookie: CookieSvg,
  Wait: WaitSvg,
  Facture: FactureSvg,
  Error403: Error403Svg,
  MaConso: MaConsoSvg,
  Attention: AttentionSvg,
  Demenage: DemenageSvg,
  FAQ: FAQSvg,
  Error404: Error404Svg,
  Deco: DecoSvg,
  Insta: InstaSvg,
  Gift: GiftSvg
};

const IconComponent = ({ name, color, style, ...props }) => {
  const Icon = iconTypes[name];
  return <Icon className={`svg-icon ${color}`} style={style} {...props} />;
};

export default IconComponent;
