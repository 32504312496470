import React from "react";
import PropTypes from "prop-types";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import "./styles/customtablehead.scss";

const CustomTableHead = (props) => {
  const { order, orderBy, onRequestSort, headCells, action } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function handleAction() {
    if (action.fnc) {
      return action.fnc();
    }
    return;
  }

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell, index) => (
          <TableCell
            key={headCell.id + "headCell" + index}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {!!action && (
          <>
            <TableCell></TableCell>
            <TableCell
              align="center"
              className="modal_relative"
              onClick={() => handleAction()}
            >
              {action?.icon}
            </TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
};

CustomTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
};

export default CustomTableHead;
