import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
// import { updatePassword } from ".core/services/httpRequest/axios";

import PasswordInput from "components/atoms/Inputs/PasswordInput/PasswordInput";
import Button from "components/atoms/Button/Button";
import IconComponent from "components/atoms/Icons/IconComponent";
import { ReactComponent as Polygon } from "core/assets/picto/Polygon.svg";
import PasswordReset from "components/organisms/PasswordReset/PasswordReset";
import { resetPassword } from "../../../core/services/API/ResetPassword/resetPassword";
import handleRequestResponse from "../../../utils/responsesHandler";
import zxcvbn from "zxcvbn";
import { rulesStep3, strongPasswordLabel } from "utils";

const Step3 = () => {
  const [checkers, setCheckers] = useState({
    min: false,
    maj: false,
    number: false,
    spec: false,
    minlength: false,
    strong: false,
  });
  const [scoreLabel, setScoreLabel] = useState();
  const [passwordToken, setpasswordToken] = useState(null);
  const [showChecker, setShowChecker] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();


  const rules = rulesStep3

  const {
    handleSubmit,
    getValues,
    watch,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: { password1: "", password2: "" },
  });

  function submit(data) {
    if (_.every(checkers, (x) => x === true)) {
      resetPassword(passwordToken, data?.password1).then((res) => {
        handleRequestResponse(
          res,
          () => {
            history.push("/password/new-password-accepted");
          },
          () => console.log("notificationHandler")
        );
      });
    } else {
      setError("password1", {
        type: "format",
        message: t("password.formaterror"),
      });
    }
  }

  useEffect(() => {
    setpasswordToken(history.location.search.split("?token=")[1]);
  }, []);

  useEffect(() => {
    const psw = getValues("password1")?.split("");
    const nextState = {};
    if (psw?.some(rules.min.rule)) {
      nextState.min = true;
    }
    if (psw?.some(rules.min.rule) === false) {
      nextState.min = false;
    }

    if (psw?.some(rules.maj.rule)) {
      nextState.maj = true;
    }
    if (psw?.some(rules.maj.rule) === false) {
      nextState.maj = false;
    }

    if (psw?.some(rules.number.rule)) {
      nextState.number = true;
    }
    if (psw?.some(rules.number.rule) === false) {
      nextState.number = false;
    }

    if (psw?.some(rules.spec.rule)) {
      nextState.spec = true;
    }
    if (psw?.some(rules.spec.rule) === false) {
      nextState.spec = false;
    }

    if (rules.minlength.rule(psw)) {
      nextState.minlength = true;
    }
    if (rules.minlength.rule(psw) === false) {
      nextState.minlength = false;
    }
    const strongPsw = zxcvbn(getValues("password1"))?.score;


    if (strongPsw >= 2) {
      nextState.strong = true;
      setScoreLabel(strongPasswordLabel(strongPsw));
    }
    if (strongPsw < 2) {
      nextState.strong = false;
      setScoreLabel(strongPasswordLabel(strongPsw));
    }


    setCheckers(nextState);
  }, [watch("password1")]);

  useEffect(() => {
    if (
      getValues("password1") === getValues("password2") &&
      _.every(checkers, (x) => x === true)
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [watch("password1"), watch("password2")]);

  return (
    <PasswordReset>
      <>
        <h1>{t("password.step3")}</h1>
        <p className="blue" style={{ marginTop: "0" }}>
          {t("password.step3sub")}
        </p>
        <form
          name="resetForm"
          onSubmit={handleSubmit(submit)}
          noValidate
          autoComplete="off"
        >
          <Controller
            name="password1"
            control={control}
            rules={{
              required: t("error.required"),
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <PasswordInput
                value={value}
                onChange={onChange}
                onBlur={(e) => {
                  onBlur(e);
                  setShowChecker(false);
                }}
                onFocus={() => setShowChecker(true)}
                label={t("auth.pass")}
                placeholder={t("password.new")}
                variant="outlined"
                error={errors.password1 !== undefined}
                helperText={errors.password1?.message}
                required
              />
            )}
          />
          {/*  */}
          <Controller
            name="password2"
            control={control}
            rules={{
              required: t("error.required"),
              validate: (x) =>
                rules.same.rule(x, getValues("password1"))
                  ? true
                  : t("password.nomatch"),
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <PasswordInput
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  if (rules.same.rule(e.target.value, getValues("password1"))) {
                    clearErrors("password2");
                  }
                }}
                onBlur={onBlur}
                label={t("auth.confirmPass")}
                placeholder={t("password.repete")}
                variant="outlined"
                error={errors.password2 !== undefined}
                helperText={errors.password2?.message}
                required
              />
            )}
          />
          {showChecker && (
            <div className="password_checker">
              <div className="custom_arrow">
                <Polygon />
              </div>
              <div className="checker">
                {checkers.maj ? (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Active" color="primary" />
                    </div>
                    <p>{t("password.maj")}</p>
                  </>
                ) : (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Cross" color="error" />
                    </div>
                    <p>{t("password.maj")}</p>
                  </>
                )}
              </div>
              <div className="checker">
                {checkers.min ? (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Active" color="primary" />{" "}
                    </div>
                    <p>{t("password.min")}</p>
                  </>
                ) : (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Cross" color="error" />
                    </div>
                    <p>{t("password.min")}</p>
                  </>
                )}
              </div>
              <div className="checker">
                {checkers.number ? (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Active" color="primary" />{" "}
                    </div>
                    <p>{t("password.number")}</p>
                  </>
                ) : (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Cross" color="error" />
                    </div>
                    <p>{t("password.number")}</p>
                  </>
                )}
              </div>
              <div className="checker">
                {checkers.spec ? (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Active" color="primary" />{" "}
                    </div>
                    <p>{t("password.spec")}</p>
                  </>
                ) : (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Cross" color="error" />
                    </div>
                    <p>{t("password.spec")}</p>
                  </>
                )}
              </div>
              <div className="checker">
                {checkers.minlength ? (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Active" color="primary" />{" "}
                    </div>
                    <p>{t("password.minlength")}</p>
                  </>
                ) : (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Cross" color="error" />
                    </div>
                    <p>{t("password.minlength")}</p>
                  </>
                )}
              </div>
              <div className="checker">
                {checkers.strong ? (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Active" color="primary" />{" "}
                    </div>
                    <p>{scoreLabel}</p>
                  </>
                ) : (
                  <>
                    <div style={{ width: "22px" }}>
                      <IconComponent name="Cross" color="error" />
                    </div>
                    <p>{scoreLabel}</p>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="container_btn">
            <Button
              type="submit"
              className="green medium"
              style={{ margin: "0 auto" }}
              disabled={submitDisabled}
            >
              {t("password.change")}
            </Button>
          </div>
        </form>
      </>
    </PasswordReset>
  );
};

export default Step3;
