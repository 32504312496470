import React from "react";

import "./button.scss";

/**
 * @name Button
 * @param {node} children Text dans le bouton
 * @param {IconComponent} icon Chemin svg
 * @param {boolean} iconBefore true if icon is before the text
 * @param {string} className ClassName
 * @param {boolean} disabled disabled
 */

const Button = ({
  children,
  icon,
  iconBefore,
  className,
  disabled,
  ...props
}) => {
  return (
    <button
      className={className}
      type={"button"}
      disabled={disabled}
      {...props}
    >
      {iconBefore && icon}
      {children}
      {!iconBefore && icon}
    </button>
  );
};

export default Button;
