import React from "react";
import "./contratItem.scss";
import TypographyComponent from "components/atoms/TypographyComponent/TypographyComponent";
import { statutContrat } from "utils";

const ContratItem = ({ contrat, isSelected = false, ...props }) => {
  return (
    <div
      className={`contrat_item--wrapper ${isSelected && "selected"}`}
      {...props}
    >
      <header>
        <div className="contrat_code">
          <TypographyComponent variant="body2">
            <b>{contrat.id}</b>
          </TypographyComponent>
        </div>

        {/* <div className="contrat_dateEnd">
          <TypographyComponent variant="body2">
            <b>Date de fin le :</b>
          </TypographyComponent>
          <TypographyComponent variant="body2">
            <b>{contrat.dateEnd}</b>
          </TypographyComponent>
        </div> */}
      </header>
      <div className="contrat_info--wrapper">
        {/* <div className="contrat_name">
          <TypographyComponent variant="body2">
            {contrat.givenName} {contrat.familyName}
          </TypographyComponent>
        </div> */}
        <div className="contrat_address">
          <TypographyComponent variant="body2">
            {contrat.address1}
            <br />
            {contrat.address2}
          </TypographyComponent>
        </div>

        <div className="contrat_pdl">
          <TypographyComponent variant="body2">
            <b> PDL : {contrat.pdl}</b>
          </TypographyComponent>
        </div>

        <div className="contrat_dateStart">
          <TypographyComponent variant="body2">
            <b>Activé à partir du :</b>
          </TypographyComponent>
          <TypographyComponent variant="body2">
            <b>{contrat.dateStart}</b>
          </TypographyComponent>
        </div>

        {contrat.dateResiliation && (
          <div className="dateResiliation">
            <TypographyComponent variant="body2">
              Votre contrat sera retiré de votre espace client le{" "}
              {contrat.dateResiliation}
            </TypographyComponent>
          </div>
        )}
        {contrat.status && (
          <div className="contrat_status">
            <TypographyComponent variant="body2">
              {statutContrat(contrat.status)}
            </TypographyComponent>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContratItem;
